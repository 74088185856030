export const PLAN = {
  TYPE: {
    store: "store",
    advertise: "advertise"
  },

  SUBTYPE_ADVERTISE: {
    freeOnLaunch: "freeOnLaunch",
    adUntilSold: "adUntilSold"
  },
  SUBTYPE_STORE: {
    store: "store",
    oneTimePayment: "oneTimePayment"
  }
};

export const getPlanSubType = (val) => {
  if (val === PLAN.SUBTYPE_ADVERTISE.adUntilSold) {
    return "Ad Until Sold";
  } else if (val === PLAN.SUBTYPE_ADVERTISE.freeOnLaunch) {
    return "Free On Launch";
  } else if (val === PLAN.SUBTYPE_STORE.oneTimePayment) {
    return "One Time Payment";
  } else if (val === PLAN.SUBTYPE_STORE.store) {
    return "Recurring";
  } else {
    return "";
  }
};
