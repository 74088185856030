import { useEffect, useRef, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "components/loader/loader";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { Tooltip } from "@mui/material";
import moment from "moment/moment";
import { getMomentFormat } from "constants/dateTime.constant";
import { Icon } from "@iconify/react";
import AdrenaInput from "components/adrenaInput";
import { getOrderList } from "store";
import ChatDialog from "components/chatDialog";
import { chatMessages } from "store";
export default function OrdersList() {
  var page = 1;
  const perPage = 10;
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  var searchInputRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const { ordersListData, totalOrdersCount } = useSelector((state) => state.couponSlice);
  const [perPageRows, setNumberOfRows] = useState(perPage);
  const [pageCheck, setPageCheck] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [showChatDialog, setShowChatDialog] = useState(false);
  const [sellerId, setSellerId] = useState("");
  useEffect(() => {
    if (ordersListData) {
      setIsLoading(false);
    }
  }, [ordersListData]);
  useEffect(() => {
    fetchOrdersList();
  }, []);

  const fetchOrdersList = (page, numberOfRows) => {
    let data = {
      page: page ? page : 1,
      perPage: numberOfRows ? numberOfRows : 10,
      language: i18n?.language,
      search: searchInputRef.current.value
    };
    dispatch(getOrderList(data, setIsLoading));
  };
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length > 2) {
      fetchOrdersList(page);
    } else if (e.target.value.length === 0) {
      fetchOrdersList(page);
    }
  };
  const clearSearch = () => {
    setSearchValue("");
    var data = {
      page: 1,
      limit: 10,
      search: "",
      perPage: 10
    };
    dispatch(getOrderList(data, setIsLoading));
  };
  const options = {
    count: totalOrdersCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPage: perPageRows,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: t("category.rowsPerPage"),
        displayRows: "of"
      },
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`
      }
    },
    onChangePage(currentPage) {
      if (currentPage >= pageCheck) {
        if (currentPage === 0) {
          currentPage = currentPage + 1;
        }
        setPageCheck(currentPage);
        page = currentPage + 1;
        fetchOrdersList(page, "");
      } else {
        if (pageCheck > 0) {
          page = pageCheck - 1;
        }
        setPageCheck(page);
        fetchOrdersList(page, "");
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      setNumberOfRows(numberOfRows);
      fetchOrdersList(page, numberOfRows);
    }
  };
  const handleChat = (id) => {
    setSellerId(
      ordersListData[ordersListData?.findIndex((item) => item.chatRoomId === id)]?.buyerId
    );
    let request = {
      chatRoomId: id,
      page: 1,
      perPage: 25,
      language: i18n?.language
    };
    dispatch(chatMessages(request));
    setShowChatDialog(true);
  };
  const columns = [
    {
      name: "orderId",
      label: t("orders.orderId"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  fontSize: 14
                }}>
                {value}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "productTitle",
      label: t("refund.productName"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  maxWidth: 140,
                  fontSize: 14
                }}>
                {value.charAt(0).toUpperCase() + value.slice(1)}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "buyerName",
      label: t("contract.buyer"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  fontSize: 14
                }}>
                {value}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "sellerName",
      label: t("contract.seller"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  fontSize: 14
                }}>
                {value}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "productPrice",
      label: t("refund.productPrice"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  fontSize: 14
                }}>
                {value ? value : "-"}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "paymentMethodId",
      label: t("orders.paymentMethodType"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  fontSize: 14
                }}>
                {value ? value : "-"}
              </Typography>
            </Tooltip>
          );
        }
      }
    },

    {
      name: "paymentType",
      label: t("orders.paymentType"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  fontSize: 14
                }}>
                {value ? value : "-"}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    // {
    //   name: "isAllInstalmentPaid",
    //   label: t("orders.isAllInstalmentPaid"),
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: (value) => {
    //       return (
    //         <Tooltip title={value}>
    //           <Typography
    //             style={{
    //               fontSize: 14
    //             }}>
    //             {value ? value : "-"}
    //           </Typography>
    //         </Tooltip>
    //       );
    //     }
    //   }
    // },
    {
      name: "paymentStatus",
      label: t("orders.paymentStatus"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  fontSize: 14
                }}>
                {value ? value : "-"}
              </Typography>
            </Tooltip>
          );
        }
      }
    },

    {
      name: "orderStatus",
      label: t("orders.status"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  fontSize: 14
                }}>
                {value ? value : "-"}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "payDate",
      label: t("orders.payDate"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          var localTime = moment.utc(value).toDate();
          return <div style={{ alignItems: "center" }}>{getMomentFormat(localTime)}</div>;
        }
      }
    },

    {
      name: "createdAt",
      label: t("orders.orderAt"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          var localTime = moment.utc(value).toDate();
          return <div style={{ alignItems: "center" }}>{getMomentFormat(localTime)}</div>;
        }
      }
    },
    {
      name: "chatRoomId",
      label: t("orders.viewChatHistory"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <Icon
                onClick={() => {
                  handleChat(value);
                  //   setShowChatDialog(true);
                }}
                icon="material-symbols-light:chat-sharp"
                color="red"
                height="30px"
                width="30px"
              />
            </>
          );
        }
      }
    }
  ];

  return (
    <>
      <ChatDialog showDialog={showChatDialog} setShowDialog={setShowChatDialog} data={sellerId} />
      <Grid container>
        <Grid
          container
          sx={{
            border: " 1px solid rgba(224, 224, 224, 1)",
            p: 2,
            backgroundColor: "white"
          }}>
          <Typography variant="body1" sx={{ fontSize: 18 }} color="secondary.contrastText">
            {t("orders.list")}
          </Typography>
        </Grid>
        <Grid
          container
          sx={{
            border: " 1px solid rgba(224, 224, 224, 1)",
            p: 2,
            backgroundColor: "white"
          }}>
          <Grid xs={3}>
            <AdrenaInput
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  fetchOrdersList(page);
                }
              }}
              inputRef={searchInputRef}
              title={t("orders.searchOrder")}
              type="email"
              endIcon={
                searchValue && (
                  <Icon
                    icon="material-symbols:cancel"
                    style={{ cursor: "pointer" }}
                    color="red"
                    onClick={() => clearSearch()}
                  />
                )
              }
              onChange={handleSearchChange}
              value={searchValue}
            />
          </Grid>
        </Grid>
        {isLoading ? (
          <Grid
            container
            sx={{
              border: "1px solid rgba(224, 224, 224, 1)",
              mt: 2,
              height: 300,
              backgroundColor: "white"
            }}>
            <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}></Grid>
          </Grid>
        ) : (
          <>
            {ordersListData && ordersListData.length > 0 ? (
              <Grid container>
                <Grid xs={12}>
                  <MUIDataTable
                    // title={"Events List"}
                    data={ordersListData}
                    columns={columns}
                    options={options}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid
                  container
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    mt: 2,
                    height: 300,
                    backgroundColor: "white"
                  }}>
                  <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
                    <Typography sx={{ fontSize: 24, fontWeight: 500, mt: "7.5rem" }}>
                      {t("category.noRecordFound")}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}

        {isLoading && <Loader open={isLoading} text={t("adrena.pleaseWait")} />}
      </Grid>
    </>
  );
}
