/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import AdminInput from "components/adminInput";
import AdminButton from "components/adminButton";
import { useState } from "react";
import useStyles from "./styles";
import AdrenaLogo from "assets/img/app_logo.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminLogin } from "store/slice/auth";
import { Icon } from "@iconify/react";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { CircularProgress, InputLabel, Stack } from "@mui/material";

export default function Login() {
  const [showPassword, setShowPassword] = useState("");
  const [email, setEmail] = useState("");
  const [formError, setFormError] = useState({});
  const [password, setPassword] = useState("");
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const [selectedLang, setSelectedLang] = useState(i18n.language);

  const { isLoading } = useSelector((state) => state.authSlice);

  const handleSubmit = () => {
    console.log({
      email: email,
      password: password
    });
    const validEmail = new RegExp("^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$");

    if (!email) {
      setFormError((formError) => ({
        ...formError,
        email: t("login.emailError")
      }));
      return;
    } else if (!email.includes("@")) {
      setFormError((formError) => ({
        ...formError,
        email: t("login.emailError1")
      }));
      return;
    } else if (!email.includes(".")) {
      setFormError((formError) => ({
        ...formError,
        email: t("login.emailError1")
      }));
      return;
    } else if (!validEmail.test(email)) {
      setFormError((formError) => ({
        ...formError,
        email: t("login.emailError1")
      }));
      return;
    }
    if (!password) {
      setFormError((formError) => ({
        ...formError,
        password: t("login.password")
      }));
      return;
    }
    var data = {
      email: email,
      password: password
    };
    dispatch(adminLogin(data, navigate));
    // secureLocalStorage.setItem("authenticated", true);
    // navigate("/dashboard");
  };
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setSelectedLang(language);
  };

  return (
    <div className={classes.setCss}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            flexDirection: "column"
          }}>
          <Stack container alignItems="center" justifyContent="center" spacing={2} sx={{ mt: 10 }}>
            <img src={AdrenaLogo} alt="logo" />
          </Stack>
          <Typography
            sx={{
              mt: 10,
              fontSize: "24px",
              fontWeight: 600,
              fontFamily: "Setimo",
              lineHeight: "28px"
            }}
            color="secondary">
            {t("login.subheading")}
          </Typography>
          <Box noValidate sx={{ mt: 1 }}>
            <InputLabel
              sx={{
                fontSize: "12px",
                fontWeight: 700,
                fontFamily: "Setimo",
                lineHeight: "16px",
                color: "#E7E9EA",
                mb: -1.6,
                mt: 2.5,
                display: "flex"
              }}>
              EMAIL
            </InputLabel>
            <AdminInput
              type="email"
              borderRadius={0}
              value={email}
              onChange={(val) => {
                if (val) {
                  setEmail(val.target.value);
                  setFormError((formError) => ({
                    ...formError,
                    email: ""
                  }));
                }
              }}
              formError={formError.email}
            />
            <InputLabel
              sx={{
                fontSize: "12px",
                fontWeight: 700,
                fontFamily: "Setimo",
                lineHeight: "16px",
                color: "#E7E9EA",
                mb: -1.6,
                mt: 1,
                display: "flex"
              }}>
              PASSWORD
            </InputLabel>
            <AdminInput
              borderRadius={0}
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(val) => {
                if (val) {
                  setPassword(val.target.value);
                  setFormError((formError) => ({
                    ...formError,
                    password: ""
                  }));
                }
              }}
              formError={formError.password}
              endIcon={
                showPassword ? (
                  <Icon icon="tabler:eye" width="28" height="28" color="white" />
                ) : (
                  <Icon icon="tabler:eye-off" width="28" height="28" color="white" />
                )
              }
              PasswordShowClick={() => setShowPassword(!showPassword)}
            />

            <Link
              sx={{
                float: "right",
                textDecoration: "none",
                fontSize: 15,
                mb: 3
              }}
              color="secondary"
              variant="body2"
              href="forgotPassword">
              {t("login.forgotPassword")}
            </Link>
            <AdminButton
              onClick={(event) => {
                handleSubmit(event);
              }}
              sx={{
                fontFamily: "Setimo",
                color: "#FFFFFF",
                height: "2.8rem",
                fontSize: "18px ",
                fontWeight: "bold"
              }}
              title={t("login.signIn")}
              fullWidth={true}
              isLoading={isLoading}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "left",
              justifyContent: "center",
              py: 1,
              pt: 7
            }}>
            <ReactCountryFlag
              onClick={() => changeLanguage("en")}
              className="emojiFlag"
              countryCode="US"
              style={{
                fontSize: selectedLang === "en" ? "4em" : "3em",
                marginRight: 20,
                filter: "grayscale(100%)"
              }}
              aria-label="English"
              svg
            />
            <ReactCountryFlag
              onClick={() => changeLanguage("pt")}
              className="emojiFlag"
              countryCode="BR"
              svg
              style={{
                fontSize: selectedLang === "pt" ? "4em" : "3em",
                filter: "grayscale(100%)"
              }}
              aria-label="Portuguese"
            />
          </Box>
        </Box>
      </Container>
    </div>
  );
}
