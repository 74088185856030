import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default function AdminDropdownInput(props) {
  const { value, items, onChange, defaultValue, placeholder, formError } = props;
  const { i18n } = useTranslation();
  return (
    <FormControl fullWidth>
      <Select
        value={value ?? ""}
        defaultValue={defaultValue ?? "none"}
        onChange={onChange}
        sx={{ color: "#242424" }}>
        <MenuItem value="none" disabled>
          {placeholder}
        </MenuItem>
        {items?.map((data, index) => {
          return (
            <MenuItem color="secondary.contrastText" value={data.id} key={index}>
              {/* {data.titleEn ? (data.titleEn ? data.titleEs : data.titleEs) : data.titlePt} */}
              {i18n?.language == "en" ? data.titleEn : data.titlePt}
            </MenuItem>
          );
        })}
      </Select>
      <Typography sx={{ color: "red", fontSize: 15, ml: 1 }}>
        {formError ? formError : ""}
      </Typography>
    </FormControl>
  );
}
