import * as React from "react";
import { Card, Box, Typography, Divider } from "@mui/material";
import cartouche from "../../../assets/img/cartouche.png";
import AnimatedNumber from "react-animated-number";

const CardWithIcon = (props) => {
  const { children, Icon, title, value } = props;
  return (
    <Card
      sx={{
        boxShadow: "1px 1px 1px 2px black",
        // minHeight: 150,
        // maxHeight: 150,
        display: "flex",
        flexDirection: "column",
        flex: "1",
        "& a": {
          textDecoration: "none",
          color: "inherit"
        }
      }}>
      <Box
        sx={{
          minHeight: 150,
          maxHeight: 150,
          overflow: "inherit",
          padding: "16px",
          position: "relative",
          backgroundImage: `url(${cartouche})`,
          display: "flex",
          backgroundSize: "cover",
          justifyContent: "space-between",
          alignItems: "center",
          "& .icon": {
            color: (theme) => (theme.palette.mode === "dark" ? "inherit" : "#dc2440")
          }
        }}>
        <Box width="3em" className="icon">
          {Icon}
        </Box>
        <Box textAlign="left">
          <Typography
            sx={{
              color: "black",
              fontSize: "25px",
              position: "absolute",
              top: 15,
              right: 0,
              width: 180
            }}>
            {title}
          </Typography>
          <AnimatedNumber
            component="text"
            initialValue={0}
            value={value}
            stepPrecision={0}
            style={{
              transition: "0.8s ease-out",
              fontSize: 38,
              transitionProperty: "background-color, color, opacity",
              position: "absolute",
              bottom: 0,
              right: 10
            }}
            duration={3000}
            formatValue={(n) => Intl.NumberFormat("en-US").format(n)}
          />
        </Box>
      </Box>

      {children && <Divider />}
      {children}
    </Card>
  );
};

export default CardWithIcon;
