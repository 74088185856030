import { List } from "@mui/material";
import { Autocomplete, ListItemText, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { clearAddressSearch } from "store";
import { addressSearch } from "store";
// import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useEffect } from "react";
import { addressPlaced } from "store";
import { t } from "i18next";

export default function FilterByCity(props) {
  const { fetchdashBoardData, isMobile } = props;
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { addressListData, adressData } = useSelector((state) => state.userSlice);
  const [address, setAddress] = useState("");
  useEffect(() => {
    adressData?.address_components.map((item) => {
      {
        item.types.map((data) => {
          if (data === "administrative_area_level_3") {
            fetchdashBoardData("", "", item.long_name);
          }
        });
      }
    });
  }, [adressData]);
  const handleSearch = (event, value) => {
    if (value) {
      let data = {
        pattern: value,
        language: i18n.language
      };
      dispatch(addressSearch(data));
    } else {
      fetchdashBoardData();
      dispatch(clearAddressSearch({ data: [] }));
    }
  };
  const handlePlaceId = (value, option) => {
    console.log("option", option);
    setAddress(option?.description);
    let data = {
      placeId: option?.place_id,
      language: i18n.language
    };
    dispatch(addressPlaced(data));
    fetchdashBoardData("", "", option.description);
  };

  return (
    <Autocomplete
      sx={{
        backgroundColor: "#dad6d6",
        width: isMobile ? "100%" : 250,
        height: 42,
        paddingTop: 0.1
      }}
      freeSolo
      disableClearable
      options={addressListData}
      value={address && address}
      onKeyUp={(e) => {
        if (e.key === "Enter") {
          setAddress("");
        }
      }}
      getOptionLabel={(option) => option.description || address}
      onChange={handlePlaceId}
      onInputChange={handleSearch}
      // closeIcon={<HighlightOffIcon onChange={() => console.log("sda")} />}
      renderOption={(props, option) => {
        const { description } = option;
        return (
          <List
            {...props}
            style={{
              backgroundColor: "white",
              marginTop: "-0.5rem",
              marginBottom: "-0.5rem"
            }}>
            <ListItemText
              sx={{
                fontSize: "16px",
                color: "black",
                fontWeight: 400,
                lineHeight: "22px",
                fontFamily: "Setimo"
              }}>
              {description}
            </ListItemText>
          </List>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t("dashBoard.enterTheCity")}
          InputLabelProps={{
            style: { color: "black" }
          }}
          sx={{
            input: {
              color: "black",
              fontFamily: "setimo",
              fontSize: 16,
              height: 10
            },
            "& .MuiAutocomplete-inputRoot": {
              borderRadius: "0px"
            },
            "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
              color: "black",
              visibility: "visible"
            }
          }}
          InputProps={{
            ...params.InputProps,
            type: "search"
          }}
        />
      )}
    />
  );
}
