import { Icon } from "@iconify/react";
import { Box, Chip, Divider, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { boldFont, formFont } from "components/style";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function PlanBox(props) {
  const [selectColor, setSelectColor] = useState(false);
  const { plan, adFormData, setAdFormData, userStoreDetail } = props;
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        // height: '200px',
        border: "1px solid black",
        mt: 4,
        backgroundColor:
          selectColor === true && plan?.id === adFormData?.planId ? "#323232" : "transparent"
      }}
      onBlur={() => {}}
      onClick={() => {
        if (plan.subType === "store") {
          setSelectColor(true);
          setAdFormData((adFormData) => ({
            ...adFormData,
            planId: plan.id,
            planSubType: plan.subType,
            amountMonthly: plan.amountMonthly,
            subscriptionType: "yearly",
            planName: plan?.name
          }));
        } else {
          setSelectColor(true);
          setAdFormData((adFormData) => ({
            ...adFormData,
            planId: plan.id,
            planSubType: plan.subType,
            subscriptionType: "",
            amountMonthly: "",
            planName: plan?.name
          }));
        }
      }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Chip
          sx={{
            borderRadius: "unset",
            backgroundColor: "#E01F26",
            mt: 1.5,
            ml: 1.95,
            pt: 0.5,
            fontSize: "17px",
            fontWeight: "700",
            color: "white"
          }}
          label={plan?.name}
        />
      </Box>
      <Chip
        sx={{
          borderRadius: "unset",
          backgroundColor: plan?.id === adFormData?.planId ? "#e7e9ea" : "black",
          color: plan?.id === adFormData?.planId ? "black" : "#e7e9ea",
          mt: 0.5,
          ml: 1.95,
          pt: 0.5,
          fontFamily: "Setimo",
          maxWidth: "90%",
          fontSize: "14px",
          fontWeight: "400"
        }}
        label={plan?.description}
      />
      {/* <Grid container sx={{ p: 1.8 }}>
        {plan?.keyFeature?.map((feature, index) => {
          return (
            <Grid item xs={12} sx={{ display: "flex", pt: 1 }} key={index}>
              <Icon icon="ri:checkbox-circle-fill" color="#e7e9ea" height="15px" />
              <Typography sx={{ pl: 1, mt: -0.2 }} style={formFont}>
                {feature}
              </Typography>
            </Grid>
          );
        })}
      </Grid> */}
      <Divider sx={{ mt: 2, borderColor: plan?.id === adFormData?.planId ? "#e7e9ea" : "black" }} />
      {userStoreDetail?.planId === plan?.id ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between", px: 2 }}>
            <Typography style={boldFont} sx={{ mt: 0.7 }}>
              {t("myStore.active")}
            </Typography>
            <Typography style={boldFont} sx={{ mt: 0.7 }}>
              {userStoreDetail?.productCount}/{plan?.numberOfAds}
            </Typography>
          </Box>
        </>
      ) : (
        <Typography style={boldFont} sx={{ pl: 2.5, pt: 1 }}>
          {plan.subType === "store" ? (
            <RadioGroup
              row
              value={adFormData?.subscriptionType ? adFormData?.subscriptionType : ""}
              onChange={(event) => {
                setAdFormData((adFormData) => ({
                  ...adFormData,
                  subscriptionType: event.target.value
                }));
              }}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group">
              <FormControlLabel
                value="monthly"
                checked={
                  adFormData?.subscriptionType === "monthly" && adFormData?.planId === plan?.id
                }
                control={
                  <Radio
                    icon={
                      <Icon
                        icon="clarity:circle-line"
                        color={plan?.id === adFormData?.planId ? "#e7e9ea" : "black"}
                        height="20px"
                        width="20px"
                      />
                    }
                    checkedIcon={
                      <Icon
                        icon="carbon:circle-filled"
                        color={plan?.id === adFormData?.planId ? "#e7e9ea" : "black"}
                        height="20px"
                        width="20px"
                      />
                    }
                  />
                }
                label={
                  <Typography
                    style={boldFont}
                    sx={{
                      mt: 0.7,
                      color: plan?.id === adFormData?.planId ? "white" : "black"
                    }}>
                    R$ {plan?.amountMonthly}
                    <span style={formFont}> / {t("storePlan.month")}</span>
                  </Typography>
                }
              />
              <Typography
                style={formFont}
                sx={{ mt: 1.4, color: plan?.id === adFormData?.planId ? "white" : "black" }}>
                {t("storePlan.or")}
              </Typography>
              &nbsp; &nbsp;
              <FormControlLabel
                value="yearly"
                checked={
                  adFormData?.subscriptionType === "yearly" && adFormData?.planId === plan?.id
                }
                control={
                  <Radio
                    icon={
                      <Icon
                        icon="clarity:circle-line"
                        color={plan?.id === adFormData?.planId ? "#e7e9ea" : "black"}
                        height="20px"
                        width="20px"
                      />
                    }
                    checkedIcon={
                      <Icon
                        icon="carbon:circle-filled"
                        color={plan?.id === adFormData?.planId ? "#e7e9ea" : "black"}
                        height="20px"
                        width="20px"
                      />
                    }
                  />
                }
                label={
                  <Typography
                    style={boldFont}
                    sx={{ mt: 0.7, color: plan?.id === adFormData?.planId ? "white" : "black" }}>
                    R$ {plan?.amountYearly}
                    <span style={formFont}> / {t("storePlan.year")}</span>
                  </Typography>
                }
              />
            </RadioGroup>
          ) : plan?.priceRange?.length >= 1 ? (
            <>
              <Typography
                style={boldFont}
                sx={{ color: plan?.id === adFormData?.planId ? "#e7e9ea" : "black", py: 1 }}>
                R$ &nbsp;
                {plan?.priceRange?.map((range) => {
                  if (
                    adFormData?.price >= range?.startRange &&
                    adFormData?.price <= range?.endRange
                  ) {
                    return range.amount;
                  } else if (adFormData?.price > range?.startRange && range?.endRange === 0) {
                    return range.amount;
                  }
                })}
              </Typography>
            </>
          ) : (
            <Typography
              style={boldFont}
              sx={{ color: plan?.id === adFormData?.planId ? "#e7e9ea" : "black", py: 1 }}>
              {t("adrenaAds.free")}
            </Typography>
          )}
        </Typography>
      )}
    </Box>
  );
}
