import { createSlice } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";
import api from "../../services";
import { ADRENA } from "../../services/apiConstant";
import { toast } from "react-toastify";

// Slice

const slice = createSlice({
  name: "couponSlice",
  initialState: {
    couponList: null,
    couponDetail: null,
    totalCount: null,
    addCouponStatus: null,
    planListData: null,
    totalPlanCount: null,
    planDetailData: null,
    priceRangeData: null,
    totalServicesCount: null,
    serviceListData: null,
    viewServiceDetail: null,
    refundListData: null,
    totalRefundsCount: null,
    contractListData: null,
    totalContractsCount: null,
    ordersListData: null,
    totalOrdersCount: null,
    chatListData: null
  },
  reducers: {
    // getCouponsListSuccess: (state, action) => {
    //   state.couponList = action.payload.data;
    //   state.totalCount = action.payload.totalItems;
    // },
    getCouponsListSuccess: (state, action) => {
      if (action.payload.page === 1) {
        state.couponList = [];
      }
      state.couponList = action.payload.data; //[...state.categoryListData, ...action.payload.data];
      state.totalCount = action.payload.totalItems;
    },
    addCouponSuccess: (state, action) => {
      state.addCouponStatus = action.payload.status;
    },
    couponDetailSuccess: (state, action) => {
      state.couponDetail = action.payload;
    },
    clearCouponDetailsSuccess: (state, action) => {
      state.couponDetail = action.payload;
    },
    updateCouponSuccess: (state, action) => {
      const objId = action.payload.id;
      console.log(objId);
      const index = state.couponList.findIndex((item) => item.id === objId);
      state.couponList[index] = action.payload;
    },
    deleteCouponSuccess: (state, action) => {
      state.couponList = state.couponList.filter((item) => item.id !== action.payload);
      state.totalCount = state.totalCount - 1;
    },
    getPlansListSuccess: (state, action) => {
      if (action.payload.page === 1) {
        state.planListData = [];
      }
      state.planListData = action.payload.data; //[...state.categoryListData, ...action.payload.data];
      state.totalPlanCount = action.payload.totalItems;
    },
    getPlanDetailSuccess: (state, action) => {
      state.planDetailData = action.payload;
    },
    clearPlanDetailsSuccess: (state, action) => {
      state.planDetailData = action.payload;
    },
    deletePlanSuccess: (state, action) => {
      state.planListData = state.planListData.filter((item) => item.id !== action.payload);
      state.totalPlanCount = state.totalPlanCount - 1;
    },
    getPriceRangeSuccess: (state, action) => {
      console.log("action", action.payload);
      state.priceRangeData = action.payload;
    },
    clearPriceRangeSuccess: (state, action) => {
      state.priceRangeData = action.payload;
    },
    getServicesListSuccess: (state, action) => {
      if (action.payload.page === 1) {
        state.planListData = [];
      }
      state.serviceListData = action.payload.data; //[...state.categoryListData, ...action.payload.data];
      state.totalServicesCount = action.payload.totalItems;
    },
    viewServiceSuccess: (state, action) => {
      state.viewServiceDetail = action.payload;
    },
    getRefundListSuccess: (state, action) => {
      if (action.payload.page === 1) {
        state.refundListData = [];
      }
      state.refundListData = action.payload.data; //[...state.categoryListData, ...action.payload.data];
      state.totalRefundsCount = action.payload.totalItems;
    },
    changeRefundStatusSuccess: (state, action) => {
      let index = state.refundListData?.findIndex((item) => item.id === action.payload.id);
      state.refundListData[index] = action.payload;
    },
    getContractListSuccess: (state, action) => {
      if (action.payload.page === 1) {
        state.contractListData = [];
      }
      state.contractListData = action.payload.data; //[...state.categoryListData, ...action.payload.data];
      state.totalContractsCount = action.payload.totalItems;
    },
    getOrderListSuccess: (state, action) => {
      if (action.payload.page === 1) {
        state.ordersListData = [];
      }
      state.ordersListData = action.payload.data; //[...state.categoryListData, ...action.payload.data];
      state.totalOrdersCount = action.payload.totalItems;
    },
    chatMessagesSuccess: (state, action) => {
      state.chatListData = action.payload.data;
    }
  }
});

const {
  getCouponsListSuccess,
  addCouponSuccess,
  couponDetailSuccess,
  clearCouponDetailsSuccess,
  updateCouponSuccess,
  deleteCouponSuccess,
  getPlansListSuccess,
  getPlanDetailSuccess,
  clearPlanDetailsSuccess,
  deletePlanSuccess,
  getPriceRangeSuccess,
  clearPriceRangeSuccess,
  getServicesListSuccess,
  viewServiceSuccess,
  getRefundListSuccess,
  changeRefundStatusSuccess,
  getContractListSuccess,
  getOrderListSuccess,
  chatMessagesSuccess
} = slice.actions;

export const getCouponsList = (requestParams) => async (dispatch) => {
  try {
    await api.post(ADRENA.getCouponsList, requestParams).then((response) => {
      if (response) {
        dispatch(
          getCouponsListSuccess({
            ...response.data,
            page: requestParams.page
          })
        );
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const addCoupon = (requestParams) => async (dispatch) => {
  try {
    await api.post(ADRENA.addCoupon, requestParams).then((response) => {
      if (response) {
        let result = response.data;
        if (result.status) {
          dispatch(addCouponSuccess(result));
        }
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const couponDetail = (id, language) => async (dispatch) => {
  try {
    await api.get(`${ADRENA.couponDetail}/${id}?language=${language}`).then((response) => {
      if (response) {
        let result = response.data;
        if (result.status) {
          dispatch(couponDetailSuccess(result.data));
        }
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const clearCouponDetails = () => async (dispatch) => {
  dispatch(clearCouponDetailsSuccess(null));
};
export const updateCoupon = (requestParams) => async (dispatch) => {
  try {
    await api.post(ADRENA.couponUpdate, requestParams).then((response) => {
      if (response) {
        let result = response.data;
        if (result.status) {
          dispatch(updateCouponSuccess(result.data));
        }
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const deleteCoupon = (id, language) => async (dispatch) => {
  try {
    await api.delete(`${ADRENA.deleteCoupon}/${id}?language=${language}`).then((response) => {
      if (response) {
        let result = response.data;
        if (result.status) {
          dispatch(deleteCouponSuccess(result.data));
        }
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const addPlan = (requestParams, navigate, setIsLoading) => async () => {
  try {
    await api.post(ADRENA.addPlan, requestParams).then((response) => {
      if (response) {
        let result = response.data;
        if (result.status) {
          navigate("/plans");
          toast.success(response.data.mssage);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error(response.data.message);
        }
      }
    });
  } catch (e) {
    setIsLoading(false);
    return console.error(e.message);
  }
};
export const getPlansList = (requestParams, setIsLoading) => async (dispatch) => {
  try {
    await api.post(ADRENA.getPlansList, requestParams).then((response) => {
      if (response) {
        dispatch(
          getPlansListSuccess({
            ...response.data,
            page: requestParams.page
          })
        );
        setIsLoading(false);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const getPlanDetail = (requestParams, setIsLoading) => async (dispatch) => {
  try {
    await api.post(ADRENA.getPlanDetail, requestParams).then((response) => {
      if (response) {
        let result = response.data;
        if (result.status) {
          dispatch(getPlanDetailSuccess(result.data));
          setIsLoading(false);
        }
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const updatePlan = (requestParams, navigate, setIsLoading) => async () => {
  try {
    await api.post(ADRENA.updatePlan, requestParams).then((response) => {
      if (response) {
        let result = response.data;
        if (result.status) {
          setIsLoading(false);
          toast.success(response.data.mssage);
          navigate("/plans");
        } else {
          setIsLoading(false);
          toast.error(response.data.message);
        }
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const clearPlanDetails = () => async (dispatch) => {
  dispatch(clearPlanDetailsSuccess(null));
};
export const deletePlan = (requestParams, setDeleteDialog) => async (dispatch) => {
  try {
    await api.post(ADRENA.deletePlan, requestParams).then((response) => {
      if (response) {
        let result = response.data;
        if (result.status) {
          toast.success(response.data.message);
          dispatch(deletePlanSuccess(result.planId));
        } else {
          toast.error(response.data.message);
        }
        setDeleteDialog(false);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const getPriceRange = (requestParams) => async (dispatch) => {
  try {
    console.log("getPriceRange");
    await api.post(ADRENA.getPriceRange, requestParams).then((response) => {
      if (response) {
        console.log("response", response.data);
        let result = response.data;
        if (result.status) {
          dispatch(getPriceRangeSuccess(result.data));
        }
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const clearPriceRange = () => async (dispatch) => {
  dispatch(clearPriceRangeSuccess(null));
};
export const createService = (requestParams, navigate, setIsLoading) => async () => {
  try {
    await api.post(ADRENA.createService, requestParams).then((response) => {
      if (response) {
        let result = response.data;
        if (result.status) {
          setIsLoading(false);
          navigate("/VerifiedPlan");
        } else {
          setIsLoading(false);
          toast.error(response.data.message);
        }
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const getServicesList = (requestParams, setIsLoading) => async (dispatch) => {
  try {
    await api.post(ADRENA.getServicesList, requestParams).then((response) => {
      if (response) {
        dispatch(
          getServicesListSuccess({
            ...response.data,
            page: requestParams.page
          })
        );
        setIsLoading(false);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const viewService = (requestParams, setIsLoading) => async (dispatch) => {
  try {
    await api.post(ADRENA.viewService, requestParams).then((response) => {
      if (response) {
        let result = response.data;
        if (result.status) {
          dispatch(viewServiceSuccess(result.data));
          setIsLoading(false);
        }
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const editService = (requestParams, navigate, setIsLoading) => async () => {
  try {
    await api.post(ADRENA.editService, requestParams).then((response) => {
      if (response) {
        let result = response.data;
        if (result.status) {
          setIsLoading(false);
          navigate("/VerifiedPlan");
        } else {
          setIsLoading(false);
          toast.error(response.data.message);
        }
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const deleteService = (requestParams) => async () => {
  try {
    await api.post(ADRENA.deleteService, requestParams).then((response) => {
      if (response) {
        let result = response.data;
        if (result.status) {
          // dispatch(deletePlanSuccess(result.planId));
          toast.success(result?.message);
        }
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const getRefundList = (requestParams, setIsLoading) => async (dispatch) => {
  try {
    await api.post(ADRENA.getRefundList, requestParams).then((response) => {
      let result = response.data;
      if (result?.status) {
        dispatch(
          getRefundListSuccess({
            ...response.data,
            page: requestParams.page
          })
        );
        setIsLoading(false);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const getContractList = (requestParams, setIsLoading) => async (dispatch) => {
  try {
    await api.post(ADRENA.getContractList, requestParams).then((response) => {
      let result = response.data;
      if (result?.status) {
        dispatch(
          getContractListSuccess({
            ...response.data,
            page: requestParams.page
          })
        );
        setIsLoading(false);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const getOrderList = (requestParams, setIsLoading) => async (dispatch) => {
  try {
    await api.post(ADRENA.getOrderList, requestParams).then((response) => {
      let result = response.data;
      if (result?.status) {
        dispatch(
          getOrderListSuccess({
            ...response.data,
            page: requestParams.page
          })
        );
        setIsLoading(false);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const chatMessages = (data) => async (dispatch) => {
  api
    .post(`${ADRENA.chatMessages}`, data)
    .then((response) => {
      if (response.data.status) {
        dispatch(chatMessagesSuccess(response.data));
      } else {
        toast.error(response?.data?.message);
      }
    })
    .catch(() => {});
};
export const changeRefundStatus = (requestParams, setIsLoading) => async (dispatch) => {
  try {
    await api.post(`${ADRENA.changeRefundStatus}`, requestParams).then((response) => {
      let result = response.data;
      if (result?.status) {
        toast.success(response?.data?.mssage);
        dispatch(changeRefundStatusSuccess(result?.data));
        setIsLoading(false);
      } else {
        toast.error(response?.data?.mssage);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export default slice.reducer;
