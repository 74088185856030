/** @format */

import React, { memo } from "react";

import PropTypes from "prop-types";
import AdminButton from "components/adminButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Slide
} from "@mui/material";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Component = (props) => {
  const { t } = useTranslation();
  //eslint
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => props.handleClose(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle style={{ paddingLeft: 30, paddingTop: 25, fontFamily: "setimo" }}>
          {props.title}
        </DialogTitle>
        <DialogContent
          style={{
            backgroundColor: "white",
            paddingTop: 0
          }}>
          <Typography variant="body1">{props.text}</Typography>
        </DialogContent>
        <DialogActions>
          <AdminButton
            onClick={() => {
              props.handleClose(false);
              props.handleAgree();
            }}
            color="primary"
            variant="contained"
            title={t("dialog.btnYes")}
          />

          <AdminButton
            title={t("dialog.btnNo")}
            onClick={() => props.handleClose(false)}
            color="primary"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

Component.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.any,
  title: PropTypes.string,
  text: PropTypes.string,
  handleAgree: PropTypes.any
};

export default memo(Component);
