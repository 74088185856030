import { Outlet } from "react-router-dom";

// @mui
// components

export default function AuthLayout() {
  return (
    <>
      <div>
        <Outlet />
      </div>
    </>
  );
}
