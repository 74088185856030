import { alpha } from "@mui/material/styles";

const withAlphas = (color) => {
  return {
    ...color,
    alpha4: alpha(color.main, 0.04),
    alpha8: alpha(color.main, 0.08),
    alpha12: alpha(color.main, 0.12),
    alpha30: alpha(color.main, 0.3),
    alpha50: alpha(color.main, 0.5)
  };
};

export const neutral = {
  50: "#ffffff",
  100: "#faddde",
  200: "#f5babc",
  300: "#D2D6DB",
  400: "#9DA4AE",

  500: "#E01F26",
  600: "#d6292f",
  700: "#cc3338",
  800: "#9b151a",
  900: "#560c0f"
};

export const adrenaTheme = withAlphas({
  lightest: "#e64b51",
  light: "#e01f26",
  main: "#E01F26",
  dark: "#b3181e",
  darkest: "#861216",
  contrastText: "#FFFFFF"
});

export const secondary = withAlphas({
  main: "#FFFFFF",
  contrastText: "#000000"
});
export const success = withAlphas({
  lightest: "#F0FDF9",
  light: "#3FC79A",
  main: "#12CC19",
  dark: "#0B815A",
  darkest: "#134E48",
  contrastText: "#FFFFFF"
});

export const info = withAlphas({
  lightest: "#ECFDFF",
  light: "#CFF9FE",
  main: "#06AED4",
  dark: "#0E7090",
  darkest: "#164C63",
  contrastText: "#FFFFFF"
});

export const warning = withAlphas({
  lightest: "#FFFAEB",
  light: "#FEF0C7",
  main: "#F79009",
  dark: "#B54708",
  darkest: "#7A2E0E",
  contrastText: "#FFFFFF"
});

export const error = withAlphas({
  lightest: "#FEF3F2",
  light: "#FEE4E2",
  main: "#F04438",
  dark: "#B42318",
  darkest: "#7A271A",
  contrastText: "#FFFFFF"
});

export const appColors = {
  successGreenColor: "#00DA8B",
  reportedColor: "#37C9EB",
  alertColor: "#E70000",
  favColor: "#FF9900",
  copyColor: "#1D82F4",
  warningColor: "#FF9900",
  primaryColor: "#E01F26",
  whiteColor: "#FFFFFF",
  blackColor: "#000000"
};
