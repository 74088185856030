/** @format */

import React, { memo, useEffect, useState } from "react";

import PropTypes from "prop-types";
import AdminButton from "components/adminButton";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Slide,
  Grid,
  Rating,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { rateAndTagByAdmin } from "store";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UserRatingDialog = (props) => {
  const { setShowDialog, showDialog, userId, userDetaiData } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    if (userDetaiData) {
      if (userDetaiData?.ratingByAdmin === null) {
        setRating(0);
      } else {
        setRating(userDetaiData?.ratingByAdmin);
      }
    }
  }, [userDetaiData]);
  const [rating, setRating] = useState(0);
  var theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t, i18n } = useTranslation();

  const handleRating = () => {
    let request = {
      userId: userId,
      updateType: 2,
      tags: [],
      rating: rating === 0 ? null : rating,
      language: i18n?.language
    };
    dispatch(rateAndTagByAdmin(request, setShowDialog, t("users.userRatingSuccess")));
  };
  return (
    <>
      <Dialog
        maxWidth="xs"
        open={showDialog}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: 0,
            width: "330px"
          }
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowDialog(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontFamily: "setimo", fontWeight: 700, fontSize: 20 }}>
            {t("users.userRating")}
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            backgroundColor: "white",
            paddingTop: 0
          }}>
          <Rating
            name="simple-controlled"
            size="large"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
          />
        </DialogContent>
        <Grid container sx={{ px: 2.8, pb: 2 }} gap={isMobile ? 1 : 2}>
          <Grid xs={isMobile ? 12 : 5.5}>
            <AdminButton
              fullWidth
              onClick={() => setShowDialog(false)}
              color="primary"
              variant="outlined"
              title="Cancel"
            />
          </Grid>
          <Grid xs={isMobile ? 12 : 5.5}>
            <AdminButton
              fullWidth
              title={t("forgotPassword.submit")}
              onClick={() => {
                handleRating();
              }}
              color="primary"
              sx={{ height: "2.5rem" }}
            />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

UserRatingDialog.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.any,
  title: PropTypes.string,
  text: PropTypes.string,
  handleAgree: PropTypes.any
};

export default memo(UserRatingDialog);
