import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import AdminButton from "components/adminButton";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import AdrenaInput from "components/adrenaInput";
import { getCategoryList, getSunCategoryDetail, updateSubCategory } from "store/slice/users";
// import uploadImage from "assets/img/uploadImage.png";
import { useDispatch, useSelector } from "react-redux";
import { addSubCategory } from "store/slice/users";
import AdminDropdownInput from "components/adminDropdownInput";
// import { Icon } from "@iconify/react";
// import { ADRENA } from "services/apiConstant";
// import api from "services";
// import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import i18n from "i18n";
import Loader from "components/loader/loader";

export default function AddSubCategory() {
  const navigate = useNavigate();
  var dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const [formError, setFormError] = useState({});
  const [formData, setFormData] = useState({});
  const [loading, setIsLoading] = useState(false);
  // Slectors --
  const { subCategoryDetailData, categoryListData } = useSelector((state) => state.userSlice);
  const { isLoading } = useSelector((state) => state.authSlice);

  useEffect(() => {
    categoryList();

    if (id) {
      categoryList();
      dispatch(getSunCategoryDetail(id));
    }
  }, []);

  useEffect(() => {
    if (id) {
      if (subCategoryDetailData && subCategoryDetailData.data) {
        console.log("subCategoryDetailData == ", subCategoryDetailData);

        const details = subCategoryDetailData?.data;
        console.log("details == ", details);

        setFormData((formData) => ({
          ...formData,
          description: details?.descriptionEn ?? "",
          title: details?.titleEn ?? "",
          categoryId: details.categoryId ?? "",
          categoryName: details.categoryName ?? "",
          subCategoryNameSpanish: details.titleEs ?? "",
          subCategoryNamePortuguese: details.titlePt ?? "",
          descriptionSpanish: details.descriptionEs ?? "",
          descriptionPortuguese: details.descriptionPt ?? ""
        }));
        // setSrc(subCategoryDetailData?.data.imageAccessUrl);
      }
    }
  }, [subCategoryDetailData]);

  const categoryList = () => {
    var data = {
      page: 1,
      limit: 1000
    };
    let isSearch = false;
    dispatch(getCategoryList(data, isSearch, setIsLoading));
  };

  const handleChangeDropdown = (event) => {
    console.log("handleChangeDropdown", event.target.value);
    setFormData((formData) => ({
      ...formData,
      categoryId: event.target.value
    }));
    setFormError((formError) => ({
      ...formError,
      categoryError: ""
    }));
  };

  const handleSubmit = () => {
    if (!formData.categoryId) {
      setFormError((formError) => ({
        ...formError,
        categoryError: t("subCategory.categoryError")
      }));
      return;
    }
    if (!formData.title) {
      setFormError((formError) => ({
        ...formError,
        titleNameError: t("category.titleNameError")
      }));
      return;
    }
    if (!formData.subCategoryNameSpanish) {
      setFormError((formError) => ({
        ...formError,
        subCategoryNameSpanishError: t("subCategory.subCategoryNameSpanishError")
      }));
      return;
    }
    if (!formData.subCategoryNamePortuguese) {
      setFormError((formError) => ({
        ...formError,
        subCategoryNamePortugueseError: t("subCategory.subCategoryNamePortugueseError")
      }));
      return;
    }

    if (!formData.description) {
      setFormError((formError) => ({
        ...formError,
        descriptionError: t("category.descriptionError")
      }));
      return;
    }
    if (!formData.descriptionSpanish) {
      setFormError((formError) => ({
        ...formError,
        descriptionSpanishError: t("subCategory.descriptionSpanishError")
      }));
      return;
    }

    if (!formData.descriptionPortuguese) {
      setFormError((formError) => ({
        ...formError,
        descriptionPortugueseError: t("subCategory.descriptionPortugueseError")
      }));
      return;
    }

    // if (!src) {
    //   toast.error(t("category.uploadImage"));
    //   return;
    // }
    if (id) {
      var data = {
        // image: profilename ? profilename : subCategoryDetailData?.data.image,
        image: "",
        titleEn: formData.title,
        titlePt: formData.subCategoryNamePortuguese,
        titleEs: formData.subCategoryNameSpanish,
        descriptionEn: formData.description,
        descriptionPt: formData.descriptionPortuguese,
        descriptionEs: formData.descriptionSpanish,
        categoryId: formData.categoryId,
        language: i18n.language,
        uid: id
      };
      dispatch(updateSubCategory(data, navigate, setIsLoading));
    } else {
      // eslint-disable-next-line no-redeclare
      var data = {
        //  image: profilename,
        image: "",
        categoryId: formData.categoryId,
        titleEn: formData.title,
        titlePt: formData.subCategoryNamePortuguese,
        titleEs: formData.subCategoryNameSpanish,
        descriptionEn: formData.description,
        descriptionPt: formData.descriptionPortuguese,
        descriptionEs: formData.descriptionSpanish
      };
      dispatch(addSubCategory(data, navigate, setIsLoading));
    }
  };

  // const onChange = (event, item) => {
  //   const file = event.target.files[0];
  //   const formData = new FormData();
  //   if (file) {
  //     formData.append("file", file);
  //   }
  //   api
  //     .post(`${ADRENA.uploadSubCategoryProfile}`, formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data"
  //       }
  //     })
  //     .then((response) => {
  //       console.log(response);
  //       let filename = response?.data?.data[0]?.fileName;
  //       setProfilename(filename);
  //       let imageResult = response.data.data[0].accessUrl;
  //       setPicture(imageResult);
  //       var imageUrl = imageResult;
  //       console.log(imageUrl);
  //       if (imageUrl) {
  //         setSrc(imageUrl);
  //       }
  //       toast.success(response.data.message);
  //       let result = response.data;
  //       if (result.status) {
  //         let data = result.data;
  //         item.value = data.fileUrl;
  //       }
  //     })
  //     .catch(() => {
  //       ("error");
  //     });
  // };

  // const removeProfile = () => {
  //   setFiletype("subCategory");
  //   if (filetype === "subCategory") {
  //     removeFileUpload();
  //   }
  // };

  // const removeFileUpload = () => {
  //   let requestParams = {
  //     type: filetype,
  //     fileName: filetype === "subCategory" && profilename
  //   };
  //   console.log;
  //   api
  //     .post(`${ADRENA.removeprofile}`, requestParams, {
  //       headers: {
  //         "Content-Type": " application/json"
  //       }
  //     })
  //     .then((response) => {
  //       toast.success(response.data.message);
  //       let result = response.data;
  //       if (result.status) {
  //         if (filetype === "subCategory") {
  //           setSrc("");
  //         }
  //       }
  //     })
  //     .catch(() => {
  //       ("error");
  //     });
  // };

  return (
    <>
      <Grid container sx={{ mb: 5 }}>
        <Grid>
          <Typography sx={{ fontSize: 18, fontWeight: 700, color: "#212b36" }}>
            {t("category.addSubCategory")}
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: "#555" }}>
            {" "}
            {t("subCategory.createNewSubCategory")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          container
          sx={{
            border: " 1px solid rgba(224, 224, 224, 1)",
            background: "white",
            p: 2,
            borderRadius: 1
          }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                sx={{ mb: 1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
                {t("subCategory.selectCategoryName")}
              </Typography>
              <AdminDropdownInput
                value={formData.categoryId}
                placeholder="Choose Category"
                defaultValue={formData.categoryId}
                onChange={handleChangeDropdown}
                formError={formError.categoryError}
                items={categoryListData}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ mb: -1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
                {t("subCategory.subCategoryName")}
              </Typography>
              <AdrenaInput
                value={formData.title}
                onChange={(val) => {
                  setFormData((formData) => ({
                    ...formData,
                    title: val.target.value
                  }));
                  setFormError((formError) => ({
                    ...formError,
                    titleNameError: ""
                  }));
                }}
                formError={formError.titleNameError}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ mb: -1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
                {t("subCategory.subCategoryNameSpanish")}
              </Typography>
              <AdrenaInput
                value={formData.subCategoryNameSpanish}
                onChange={(val) => {
                  setFormData((formData) => ({
                    ...formData,
                    subCategoryNameSpanish: val.target.value
                  }));
                  setFormError((formError) => ({
                    ...formError,
                    subCategoryNameSpanishError: ""
                  }));
                }}
                formError={formError.subCategoryNameSpanishError}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ mb: -1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
                {t("subCategory.subCategoryNamePortuguese")}
              </Typography>
              <AdrenaInput
                value={formData.subCategoryNamePortuguese}
                onChange={(val) => {
                  setFormData((formData) => ({
                    ...formData,
                    subCategoryNamePortuguese: val.target.value
                  }));
                  setFormError((formError) => ({
                    ...formError,
                    subCategoryNamePortugueseError: ""
                  }));
                }}
                formError={formError.subCategoryNamePortugueseError}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ mb: -1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
                {t("category.descriptionInEnglish")}
              </Typography>
              <AdrenaInput
                multiline={true}
                rows={3}
                value={formData.description}
                onChange={(val) => {
                  setFormData((formData) => ({
                    ...formData,
                    description: val.target.value
                  }));
                  setFormError((formError) => ({
                    ...formError,
                    descriptionError: ""
                  }));
                }}
                formError={formError.descriptionError}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ mb: -1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
                {t("subCategory.descriptionSpanish")}
              </Typography>
              <AdrenaInput
                multiline={true}
                rows={3}
                value={formData.descriptionSpanish}
                onChange={(val) => {
                  setFormData((formData) => ({
                    ...formData,
                    descriptionSpanish: val.target.value
                  }));
                  setFormError((formError) => ({
                    ...formError,
                    descriptionSpanishError: ""
                  }));
                }}
                formError={formError.descriptionSpanishError}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ mb: -1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
                {t("subCategory.descriptionPortuguese")}
              </Typography>
              <AdrenaInput
                multiline={true}
                rows={3}
                value={formData.descriptionPortuguese}
                onChange={(val) => {
                  setFormData((formData) => ({
                    ...formData,
                    descriptionPortuguese: val.target.value
                  }));
                  setFormError((formError) => ({
                    ...formError,
                    descriptionPortugueseError: ""
                  }));
                }}
                formError={formError.descriptionPortugueseError}
              />
            </Grid>

            {/* <Grid item xs={12}>
              <Typography variant="body1" component="span" color="secondary.contrastText">
                {t("subCategory.subCategoryImage")}
              </Typography>

              <Box
                sx={{
                  border: "1px solid rgba(224, 224, 224, 1)",
                  maxWidth: 180,
                  height: 120,
                  position: "relative",
                  textAlign: "center",
                  my: 1
                }}>
                {src && (
                  <>
                    <Icon
                      icon="mdi:remove-bold"
                      onClick={() => {
                        removeProfile();
                      }}
                      style={{
                        position: "absolute",
                        right: 0,
                        fontSize: 25,
                        marginTop: -10,
                        marginRight: -10,
                        color: "white",
                        backgroundColor: "red",
                        borderRadius: 25,
                        padding: 2
                      }}
                    />
                  </>
                )}
                <input
                  ref={inputProfile}
                  style={{ display: "none", cursor: "pointer" }}
                  type="file"
                  accept="image/*"
                  onChange={onChange}
                  multiple={true}
                />
                <img
                  onClick={() => inputProfile.current && inputProfile.current.click()}
                  src={src ? src : uploadImage}
                  id="image"
                  alt="logo"
                  width="180"
                  height="120"
                />
              </Box>
            </Grid> */}
          </Grid>
          <Grid container sx={{ mt: 2 }}>
            <AdminButton
              title={t("button.save")}
              onClick={handleSubmit}
              sx={{ width: 150, mr: 1 }}
              isLoading={isLoading}
            />
            <AdminButton
              sx={{ bgcolor: "black", "&:hover": { bgcolor: "#757575" }, width: 150 }}
              title={t("button.cancel")}
              onClick={() => {
                navigate("/subCategories");
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      {loading && <Loader open={loading} text={"Please Wait"} />}
    </>
  );
}
