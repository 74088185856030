import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import { List, ListItemButton, ListItemText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const options = [
  { id: "week", value: "dashBoard.thisWeek" },
  { id: "month", value: "dashBoard.thisMonth" },
  { id: "year", value: "dashBoard.thisYear" }
];

export default function SplitButton(props) {
  const { t } = useTranslation();
  const { fetchdashBoardData } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    fetchdashBoardData(options[index].id);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        sx={{ borderRadius: 0 }}>
        <Button sx={{ width: 120 }}>{`${t(options[selectedIndex]?.value)}`}</Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom"
            }}>
            <Paper sx={{ width: 170, borderRadius: 0 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <List sx={{ width: "10.8rem" }}>
                    {options.map((option, index) => (
                      <ListItemButton
                        key={index}
                        selected={index === selectedIndex}
                        sx={{
                          ":hover": {
                            backgroundColor: "red",
                            color: index === selectedIndex ? "black" : "white"
                          },
                          color: "black",
                          backgroundColor: index === selectedIndex ? "red" : ""
                        }}
                        onClick={(event) => handleMenuItemClick(event, index)}>
                        <ListItemText>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "22px",
                              fontFamily: "setimo"
                            }}>
                            {`${t(option?.value)}`}
                          </Typography>
                        </ListItemText>
                      </ListItemButton>
                    ))}
                  </List>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
