import { useEffect, useState } from "react";
import { Box, FormControlLabel, Grid, Radio, RadioGroup, Stack } from "@mui/material";
import AdminButton from "components/adminButton";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import AdrenaInput from "components/adrenaInput";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import { addPlan, getPlanDetail, clearPriceRange, updatePlan } from "store";
import Loader from "components/loader/loader";
import { toast } from "react-toastify";
import { PLAN } from "utils/constant";
import { getPriceRange } from "store";

export default function AddUpdatePlan() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [formError, setFormError] = useState({});
  const [formData, setFormData] = useState({});
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [inputList, setInputList] = useState([""]);
  const [amountInputs, setAmountInputs] = useState([]);
  const { planDetailData, priceRangeData } = useSelector((state) => state.couponSlice);

  useEffect(() => {
    dispatch(clearPriceRange());
    dispatch(getPriceRange({ language: i18n?.language }));
  }, []);

  useEffect(() => {
    if (priceRangeData) {
      priceRangeData?.map((range) => {
        let data = {
          startRange: range.startRange,
          endRange: range.endRange,
          amount: ""
        };

        setAmountInputs((amountInputs) => [...amountInputs, data]);
      });
      dispatch(clearPriceRange());
    }
  }, [priceRangeData]);

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      keyFeature: inputList
    }));
  }, [inputList]);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      let requestParams = {
        planId: id,
        language: i18n?.language
      };
      dispatch(getPlanDetail(requestParams, setIsLoading));
    }
  }, [id]);

  useEffect(() => {
    if (planDetailData) {
      setFormData(planDetailData);
      if (planDetailData.trial === 0) {
        setFormData((formData) => ({
          ...formData,
          isFreeTrial: false
        }));
      } else {
        setFormData((formData) => ({
          ...formData,
          isFreeTrial: true
        }));
      }
      setInputList(planDetailData?.keyFeature);
      setAmountInputs(planDetailData?.priceRange);
    }
  }, [planDetailData]);

  const handleSubmit = () => {
    if (!formData.name) {
      setFormError((formError) => ({
        ...formError,
        nameError: t("plans.nameError")
      }));
      return;
    }
    if (!formData.description) {
      setFormError((formError) => ({
        ...formError,
        descriptionError: t("plans.descriptionError")
      }));
    }
    if (!formData.type) {
      setFormError((formError) => ({
        ...formError,
        typeError: t("plans.typeError")
      }));
      return;
    }
    if (formData.isActive === undefined) {
      setFormError((formError) => ({
        ...formError,
        isActiveError: t("plans.isActiveError")
      }));
      return;
    }

    if (!formData?.subType) {
      setFormError((formError) => ({
        ...formError,
        subTypeError: t("plan.subTypeError")
      }));
      return;
    }
    //Validations for Store Type Plans
    if (formData.type === PLAN.TYPE.store) {
      if (formData.subType === PLAN.SUBTYPE_STORE.store) {
        if (!formData?.numberOfAds) {
          setFormError((formError) => ({
            ...formError,
            numberOfAdsError: t("plan.adsLimitError")
          }));
          return;
        }
        if (!formData?.amountMonthly) {
          setFormError((formError) => ({
            ...formError,
            amountMonthlyError: t("plan.monthlyAmountError")
          }));
          return;
        }
        if (!formData?.amountHalfYearly) {
          setFormError((formError) => ({
            ...formError,
            amountHalfYearlyError: t("plan.halfYearlyAmountError")
          }));
        }
        if (!formData?.amountYearly) {
          setFormError((formError) => ({
            ...formError,
            amountYearlyError: t("plan.yearlyAmountError")
          }));
          return;
        }
      }
      if (formData.subType === PLAN.SUBTYPE_STORE.oneTimePayment) {
        if (!formData?.numberOfAds) {
          setFormError((formError) => ({
            ...formError,
            numberOfAdsError: t("plan.adsLimitError")
          }));
          return;
        }
        if (!formData?.amountMonthly) {
          setFormError((formError) => ({
            ...formError,
            amountMonthlyError: t("plan.monthlyAmountError")
          }));
        }
        if (!formData?.amountQuarterly) {
          setFormError((formError) => ({
            ...formError,
            amountQuarterlyError: t("plan.QuaterlyAmountError")
          }));
          return;
        }
        if (!formData?.amountHalfYearly) {
          setFormError((formError) => ({
            ...formError,
            amountHalfYearlyError: t("plan.halfYearlyAmountError")
          }));
          return;
        }
        if (!formData?.amountYearly) {
          setFormError((formError) => ({
            ...formError,
            amountYearlyError: t("plan.yearlyAmountError")
          }));
          return;
        }
      }
    }

    //Validations for Advertise Type Plans
    if (formData.type === PLAN.TYPE.advertise) {
      if (formData.subType === PLAN.SUBTYPE_ADVERTISE.freeOnLaunch && !formData?.duration) {
        setFormError((formError) => ({
          ...formError,
          durationError: t("plans.durationError")
        }));
        return;
      }
      if (formData.subType === PLAN.SUBTYPE_ADVERTISE.adUntilSold) {
        const doEmptyAction = amountInputs.every((o) => o.amount != "");
        if (!doEmptyAction) {
          toast.error("Plese enter amount value");
          return;
        }
      }
    }

    let requestData = {
      name: formData?.name,
      description: formData?.description,
      type: formData?.type, //"advertise / store",
      subType: formData?.subType, //"freeOnLaunch / adUntilSold (This options are only for advertise) / store / oneTimePayment",
      duration: formData?.duration ?? "",
      numberOfAds:
        formData?.type === PLAN.TYPE.store
          ? formData?.numberOfAds
          : formData?.subType === PLAN.SUBTYPE_ADVERTISE.adUntilSold
          ? "1"
          : "",
      trial: formData?.trial ?? "",
      isActive: !!formData?.isActive,
      keyFeature: formData?.keyFeature ?? [],
      priceRange: formData?.subType === "adUntilSold" ? amountInputs : [],
      amountMonthly: parseFloat(formData?.amountMonthly ?? 0),
      amountYearly: parseFloat(formData?.amountYearly ?? 0),
      amountHalfYearly: parseFloat(formData?.amountHalfYearly ?? 0),
      amountQuarterly: parseFloat(formData?.amountQuarterly ?? 0),
      discountYearly: parseFloat(formData?.discountYearly ?? 0),
      language: i18n?.language
    };
    console.log("requestData", requestData);
    setIsLoading(true);
    if (id) {
      requestData.planId = id;
      console.log("Update", requestData);
      dispatch(updatePlan(requestData, navigate, setIsLoading));
    } else {
      dispatch(addPlan(requestData, navigate, setIsLoading));
    }
  };
  const handleRemoveInput = (index) => {
    let temp = [...inputList];
    temp.splice(index, 1);
    setInputList(temp);
  };
  const handleInputChange = (value, index) => {
    var temp = [...inputList];
    temp[index] = value;
    setInputList(temp);
  };
  const handleClearInputs = () => {
    setFormData((formData) => ({
      ...formData,
      numberOfAds: "",
      amountMonthly: "",
      amountQuarterly: "",
      amountHalfYearly: "",
      amountYearly: ""
    }));
  };
  const StoreView = () => {
    return (
      <>
        <Grid container spacing={1} sx={{ px: 2, pt: 2 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              sx={{ mb: 1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
              {t("plan.subType")}
            </Typography>
            <RadioGroup
              row
              defaultValue={PLAN.SUBTYPE_STORE.store}
              onChange={(event) => {
                handleClearInputs();
                setFormData((formData) => ({
                  ...formData,
                  subType: event.target.value
                }));

                setFormError((formError) => ({
                  ...formError,
                  subTypeError: ""
                }));
              }}
              value={formData?.subType}
              sx={{ pt: 2 }}>
              <FormControlLabel
                value={PLAN.SUBTYPE_STORE.store}
                control={<Radio checked={formData?.subType === PLAN.SUBTYPE_STORE.store} />}
                label={
                  <Typography sx={{ pt: 0.1, fontSize: 17, fontWeight: 400, color: "black" }}>
                    {t("plan.storeRecurring")}
                  </Typography>
                }
              />
              <FormControlLabel
                value={PLAN.SUBTYPE_STORE.oneTimePayment}
                control={
                  <Radio checked={formData?.subType === PLAN.SUBTYPE_STORE.oneTimePayment} />
                }
                label={
                  <Typography sx={{ pt: 0.1, fontSize: 17, fontWeight: 400, color: "black" }}>
                    {t("plan.storeOneTimePayment")}
                  </Typography>
                }
              />
            </RadioGroup>
          </Grid>
        </Grid>

        {formData.subType === PLAN.SUBTYPE_STORE.store && StoreRecurringView()}
        {formData.subType === PLAN.SUBTYPE_STORE.oneTimePayment && StoreOneTimeView()}
      </>
    );
  };
  const StoreOneTimeView = () => {
    return (
      <>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <AdrenaInput
            heading={t("plan.adsLimit")}
            type="number"
            value={formData?.numberOfAds}
            onChange={(val) => {
              setFormData((formData) => ({
                ...formData,
                numberOfAds: val.target.value
              }));
              setFormError((formError) => ({
                ...formError,
                numberOfAdsError: ""
              }));
            }}
            formError={formError?.numberOfAdsError}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <AdrenaInput
            heading={t("plan.monthlyAmount")}
            startIcon={<Icon icon="fa6-solid:brazilian-real-sign" />}
            type="number"
            value={formData?.amountMonthly}
            onChange={(val) => {
              setFormData((formData) => ({
                ...formData,
                amountMonthly: val.target.value
              }));
              setFormError((formError) => ({
                ...formError,
                amountMonthlyError: ""
              }));
            }}
            formError={formError?.amountMonthlyError}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <AdrenaInput
            heading={t("plan.QuaterlyAmount")}
            startIcon={<Icon icon="fa6-solid:brazilian-real-sign" />}
            type="number"
            value={formData?.amountQuarterly}
            onChange={(val) => {
              setFormData((formData) => ({
                ...formData,
                amountQuarterly: val.target.value
              }));
              setFormError((formError) => ({
                ...formError,
                amountQuarterlyError: ""
              }));
            }}
            formError={formError?.amountQuarterlyError}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <AdrenaInput
            heading={t("plan.halfYearlyAmount")}
            startIcon={<Icon icon="fa6-solid:brazilian-real-sign" />}
            type="number"
            value={formData?.amountHalfYearly}
            onChange={(val) => {
              setFormData((formData) => ({
                ...formData,
                amountHalfYearly: val.target.value
              }));
              setFormError((formError) => ({
                ...formError,
                amountHalfYearlyError: ""
              }));
            }}
            formError={formError?.amountHalfYearlyError}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <AdrenaInput
            heading={t("plan.yearlyAmount")}
            startIcon={<Icon icon="fa6-solid:brazilian-real-sign" />}
            type="number"
            value={formData?.amountYearly}
            onChange={(val) => {
              setFormData((formData) => ({
                ...formData,
                amountYearly: val.target.value
              }));
              setFormError((formError) => ({
                ...formError,
                amountYearlyError: ""
              }));
            }}
            formError={formError?.amountYearlyError}
          />
        </Grid>
      </>
    );
  };
  const StoreRecurringView = () => {
    return (
      <>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <AdrenaInput
            heading={t("plan.adsLimit")}
            type="number"
            value={formData?.numberOfAds}
            onChange={(val) => {
              setFormData((formData) => ({
                ...formData,
                numberOfAds: val.target.value
              }));
              setFormError((formError) => ({
                ...formError,
                numberOfAdsError: ""
              }));
            }}
            formError={formError?.numberOfAdsError}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <AdrenaInput
            heading={t("plan.monthlyAmount")}
            startIcon={<Icon icon="fa6-solid:brazilian-real-sign" />}
            type="number"
            value={formData?.amountMonthly}
            onChange={(val) => {
              setFormData((formData) => ({
                ...formData,
                amountMonthly: val.target.value
              }));
              setFormError((formError) => ({
                ...formError,
                amountMonthlyError: ""
              }));
            }}
            formError={formError?.amountMonthlyError}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <AdrenaInput
            heading={t("plan.halfYearlyAmount")}
            startIcon={<Icon icon="fa6-solid:brazilian-real-sign" />}
            type="number"
            value={formData?.amountHalfYearly}
            onChange={(val) => {
              setFormData((formData) => ({
                ...formData,
                amountHalfYearly: val.target.value
              }));
              setFormError((formError) => ({
                ...formError,
                amountHalfYearlyError: ""
              }));
            }}
            formError={formError?.amountHalfYearlyError}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <AdrenaInput
            heading={t("plan.yearlyAmount")}
            startIcon={<Icon icon="fa6-solid:brazilian-real-sign" />}
            type="number"
            value={formData?.amountYearly}
            onChange={(val) => {
              setFormData((formData) => ({
                ...formData,
                amountYearly: val.target.value
              }));
              setFormError((formError) => ({
                ...formError,
                amountYearlyError: ""
              }));
            }}
            formError={formError?.amountYearlyError}
          />
        </Grid>
      </>
    );
  };

  const advertiseSubType = () => {
    return (
      <>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography
            sx={{
              mb: -1,
              color: "#212b36",
              fontWeight: 500,
              fontSize: 14,
              display: "block"
            }}>
            {t("plan.subType")}
          </Typography>
          <RadioGroup
            onChange={(event) => {
              setFormData((formData) => ({
                ...formData,
                subType: event.target.value
              }));

              setFormError((formError) => ({
                ...formError,
                subTypeError: ""
              }));
            }}
            value={formData?.subType}
            sx={{ pt: 2 }}
            row>
            <FormControlLabel
              value={PLAN.SUBTYPE_ADVERTISE.freeOnLaunch}
              control={
                <Radio checked={formData?.subType === PLAN.SUBTYPE_ADVERTISE.freeOnLaunch} />
              }
              label={
                <Typography sx={{ pt: 0.1, fontSize: 17, fontWeight: 400, mr: 5, color: "black" }}>
                  {t("plans.freeOnLaunch")}
                </Typography>
              }
            />
            <FormControlLabel
              value={PLAN.SUBTYPE_ADVERTISE.adUntilSold}
              control={<Radio checked={formData?.subType === PLAN.SUBTYPE_ADVERTISE.adUntilSold} />}
              label={
                <Typography sx={{ pt: 0.1, fontSize: 17, fontWeight: 400, color: "black" }}>
                  {t("plans.adUntilSold")}
                </Typography>
              }
            />
          </RadioGroup>
          <Typography sx={{ color: "red", fontSize: 15, pt: 1, pl: 1 }}>
            {formError?.subTypeError && formError?.subTypeError}
          </Typography>
        </Grid>
        {formData.subType === PLAN.SUBTYPE_ADVERTISE.freeOnLaunch && advertiseFreeOnLaunch()}
        {formData.subType === PLAN.SUBTYPE_ADVERTISE.adUntilSold && advertiseAdUntilSoldView()}
      </>
    );
  };

  const advertiseAdUntilSoldView = () => {
    return (
      <Grid container spacing={3} px={2}>
        {amountInputs?.map((obj, index) => {
          return (
            <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
              <Box
                sx={{
                  border: "1px solid #d0d4d9",
                  px: 2,
                  pt: 1.5,
                  display: "flex",
                  gap: 3
                }}>
                <Stack>
                  <Typography
                    sx={{
                      color: "#212b36",
                      fontWeight: 500,
                      fontSize: 14,
                      display: "block"
                    }}>
                    {t("plan.minPrice")}
                  </Typography>
                  <Typography variant="h6" sx={{ fontFamily: "setimo", pt: 1.5 }}>
                    {obj?.startRange}
                  </Typography>
                </Stack>
                <Typography variant="h6" sx={{ fontFamily: "setimo", pt: 4, mr: 1 }}>
                  -
                </Typography>
                <Stack>
                  <Typography
                    sx={{
                      color: "#212b36",
                      fontWeight: 500,
                      fontSize: 14,
                      display: "block"
                    }}>
                    {t("plan.maxPrice")}
                  </Typography>
                  <Typography variant="h6" sx={{ fontFamily: "setimo", pt: 1.5 }}>
                    {obj?.endRange}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography
                    sx={{
                      color: "#212b36",
                      fontWeight: 500,
                      fontSize: 14,
                      mb: -2,
                      display: "block"
                    }}>
                    {t("plan.amount")}
                  </Typography>
                  <AdrenaInput
                    value={obj?.amount}
                    fullWidth
                    onChange={(event) => {
                      let temp = [...amountInputs];
                      let data = {
                        startRange: obj.startRange,
                        endRange: obj.endRange,
                        amount: event.target.value
                      };
                      temp[index] = data;
                      setAmountInputs(temp);
                    }}
                    type="number"
                    startIcon={<Icon icon="fa6-solid:brazilian-real-sign" />}
                  />
                </Stack>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const advertiseFreeOnLaunch = () => {
    return (
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography
          sx={{
            mb: -1,
            color: "#212b36",
            fontWeight: 500,
            fontSize: 14,
            display: "block"
          }}>
          {t("plan.date")}
        </Typography>
        <AdrenaInput
          type="datetime-local"
          value={formData.duration}
          onChange={(val) => {
            setFormData((formData) => ({
              ...formData,
              duration: val.target.value
            }));
            setFormError((formError) => ({
              ...formError,
              durationError: ""
            }));
          }}
          formError={formError?.durationError}
        />
      </Grid>
    );
  };

  return (
    <>
      <Grid container sx={{ mb: 5 }}>
        <Typography sx={{ fontSize: "1.5rem", fontWeight: 600, color: "#212b36" }}>
          {t("plans.btn")}
        </Typography>
      </Grid>

      <Grid
        container
        sx={{
          border: "1px solid rgba(224, 224, 224, 1)",
          background: "white",
          p: 3,
          borderRadius: 1
        }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              sx={{ mb: -1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
              {t("plans.planName")}
            </Typography>
            <AdrenaInput
              value={formData.name}
              onChange={(val) => {
                setFormData((formData) => ({
                  ...formData,
                  name: val.target.value
                }));
                setFormError((formError) => ({
                  ...formError,
                  nameError: ""
                }));
              }}
              formError={formError.nameError}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              sx={{ mb: -1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
              {t("plans.planDescription")}
            </Typography>
            <AdrenaInput
              value={formData.description}
              onChange={(val) => {
                setFormData((formData) => ({
                  ...formData,
                  description: val.target.value
                }));
                setFormError((formError) => ({
                  ...formError,
                  descriptionError: ""
                }));
              }}
              formError={formError.descriptionError}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              sx={{ mb: -1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
              {t("plans.type")}
            </Typography>
            <RadioGroup
              value={formData?.type}
              onChange={(event) => {
                setFormData((formData) => ({
                  ...formData,
                  type: event.target.value
                }));
                setFormError((formError) => ({
                  ...formError,
                  typeError: ""
                }));
              }}
              sx={{ pt: 2 }}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group">
              <FormControlLabel
                value="advertise"
                control={<Radio checked={formData?.type === "advertise"} />}
                label={
                  <Typography
                    sx={{ pt: 0.1, fontSize: 17, fontWeight: 400, mr: 5, color: "black" }}>
                    {t("plans.advertise")}
                  </Typography>
                }
              />
              <FormControlLabel
                value="store"
                control={<Radio checked={formData?.type === "store"} />}
                label={
                  <Typography sx={{ pt: 0.1, fontSize: 17, fontWeight: 400, color: "black" }}>
                    {t("plans.store")}
                  </Typography>
                }
              />
            </RadioGroup>
            <Typography sx={{ color: "red", fontSize: 15, pt: 1, pl: 1 }}>
              {formError?.typeError ? formError?.typeError : ""}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
              sx={{ mb: -1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
              {t("plans.activeStatus")}
            </Typography>
            <RadioGroup
              onChange={(event) => {
                if (event.target.value == "true") {
                  setFormData((formData) => ({
                    ...formData,
                    isActive: true
                  }));
                } else {
                  setFormData((formData) => ({
                    ...formData,
                    isActive: false
                  }));
                }
                setFormError((formError) => ({
                  ...formError,
                  isActiveError: ""
                }));
              }}
              value={formData?.isActive}
              sx={{ pt: 2 }}
              row>
              <FormControlLabel
                value={true}
                control={<Radio checked={formData?.isActive === true} />}
                label={
                  <Typography
                    sx={{ pt: 0.1, fontSize: 17, fontWeight: 400, mr: 5, color: "black" }}>
                    {t("plans.enable")}
                  </Typography>
                }
              />
              <FormControlLabel
                value={false}
                control={<Radio checked={formData?.isActive === false} />}
                label={
                  <Typography sx={{ pt: 0.1, fontSize: 17, fontWeight: 400, color: "black" }}>
                    {t("plans.disable")}
                  </Typography>
                }
              />
            </RadioGroup>
            <Typography sx={{ color: "red", fontSize: 15, pt: 1, pl: 1 }}>
              {formError?.isActiveError && formError?.isActiveError}
            </Typography>
          </Grid>

          {formData?.type === PLAN.TYPE.store && <>{StoreView()}</>}
          {formData?.type === PLAN.TYPE.advertise && <>{advertiseSubType()}</>}

          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{
                  mb: -1,
                  color: "#212b36",
                  fontWeight: 500,
                  fontSize: 14,
                  display: "block"
                }}>
                {t("plans.features")}
              </Typography>
              <AdminButton
                onClick={() => setInputList((inputList) => [...inputList, ""])}
                title={t("button.addMore")}
                sx={{ bgcolor: "black", "&:hover": { bgcolor: "#757575" }, width: 150 }}
              />
            </Box>
            {formData?.keyFeature?.map((value, index) => {
              return (
                <AdrenaInput
                  endIcon={
                    index >= 1 && (
                      <Icon
                        style={{ cursor: "pointer" }}
                        icon="gridicons:cross-circle"
                        color="red"
                        width="25"
                        height="25"
                        onClick={() => handleRemoveInput(index)}
                      />
                    )
                  }
                  key={index}
                  s
                  value={value}
                  onChange={(val) => handleInputChange(val.target.value, index)}
                  formError={formError.descriptionPortugueseError}
                />
              );
            })}
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={6} sm={6} md={1.5} lg={1.5}>
            <AdminButton
              title={!id ? t("plans.btnAdd") : t("plans.btnUpdate")}
              onClick={handleSubmit}
              sx={{ width: 150, mr: 1 }}
            />
          </Grid>
          <Grid item xs={6}>
            <AdminButton
              sx={{ bgcolor: "black", "&:hover": { bgcolor: "#757575" }, width: 150 }}
              title={t("button.cancel")}
              onClick={() => {
                navigate("/plans");
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      {isLoading && <Loader open={isLoading} text={"Please Wait"} />}
    </>
  );
}
