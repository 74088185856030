import { Icon } from "@iconify/react";
import { Box, Typography } from "@mui/material";
import React from "react";

const ImageBox = (props) => {
  const { image, handleDelete } = props;
  // const { vendorImageNameList } = useSelector((state) => state.subCategories);

  // const handleDelete = (index) => {
  //   let data = {
  //     fileName: vendorImageNameList[index],
  //     language: "en"
  //   };
  //   dispatch(deleteVendorImages(index));
  // };

  return (
    <>
      <Box
        sx={{
          height: "250px",
          position: "relative",
          width: "250px",
          maxWidth: "250px"
          // maxHeight: '187px'
        }}>
        <img
          alt=""
          src={image}
          style={{
            height: "250px",
            width: "250px"
          }}
        />
        <Typography
          style={{
            position: "absolute",
            top: "2px",
            right: "6px",
            backgroundColor: "white",
            borderRadius: 150,
            height: 25,
            zIndex: "5"
          }}>
          <Icon
            onClick={handleDelete}
            color="black"
            icon="clarity:remove-solid"
            style={{
              height: "25px",
              width: "25px"
            }}
          />
        </Typography>
      </Box>
    </>
  );
};

export default ImageBox;
