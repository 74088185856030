/** @format */

import React, { memo } from "react";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";

// import { useTheme } from "@mui/styles/useTheme";
import { CircularProgress } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Component = (props) => {
  // const theme = useTheme();

  return (
    <>
      <Dialog
        maxWidth="xs"
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        PaperProps={{
          elevation: 0
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogContent
          style={{
            backgroundColor: "secondary",
            padding: 15
          }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size={30} color="primary" />
          </div>

          <Typography variant="body1" style={{ paddingLeft: 12, paddingTop: 10 }}>
            {props.text}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

Component.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.any,
  title: PropTypes.string,
  text: PropTypes.string,
  handleAgree: PropTypes.any
};

export default memo(Component);
