import { useEffect, useState } from "react";
// @mui
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  IconButton,
  Popover,
  Grid,
  FormControl,
  Select
} from "@mui/material";
// mocks_
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

import { Icon } from "@iconify/react";
import ReactCountryFlag from "react-country-flag";

// import NotificationsIcon from "@mui/icons-material/Notifications";
import i18n from "i18n";
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Profile",
    icon: "eva:person-fill"
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [selectedLang, setSelectedLang] = useState(i18n.language);

  const [userData, setUserData] = useState("");

  useEffect(() => {
    const userData = secureLocalStorage.setItem(process.env.REACT_APP_USER_STORAGE_KEY);
    if (userData) {
      let userInfo = JSON.parse(userData);
      console.log("userIfo", userInfo);
      setUserData(userInfo);
    }
  }, []);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    secureLocalStorage.clear();
    navigate("/login", { replace: true });
  };

  const changeLanguage = (e) => {
    console.log("lang", e.target.value);
    let val = e.target.value;
    i18n.changeLanguage(val);
    setSelectedLang(val);
  };
  return (
    <>
      <Grid container sx={{ display: "flex", justifyContent: "right", pr: 2 }}>
        <Grid>
          <FormControl sx={{ m: 1, paddingTop: 1, minWidth: 80 }} size="small">
            <Select
              sx={{
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                  border: 0
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: 0
                }
              }}
              id="language"
              value={selectedLang}
              onChange={changeLanguage}
              defaultValue={selectedLang}>
              <MenuItem
                value={"en"}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "transparent",
                    "&.Mui-focusVisible": { background: "transparent" },
                    color: "#FFFFFF"
                  }
                }}>
                <ReactCountryFlag
                  className="emojiFlag"
                  countryCode="US"
                  style={{
                    fontSize: "2em",
                    filter: "grayscale(100%)"
                  }}
                  aria-label="English"
                  svg
                />
              </MenuItem>

              <MenuItem
                value={"pt"}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "transparent",
                    "&.Mui-focusVisible": { background: "transparent" },
                    color: "#FFFFFF"
                  }
                }}>
                <ReactCountryFlag
                  className="emojiFlag"
                  countryCode="BR"
                  svg
                  style={{
                    fontSize: "2em",
                    filter: "grayscale(100%)"
                  }}
                  aria-label="Portuguese"
                />
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid sx={{ mt: 3.5, pr: 2 }}>
          <NotificationsIcon />
        </Grid> */}
        <IconButton
          onClick={handleOpen}
          sx={{
            p: 0,
            ...(open && {
              "&:before": {
                content: "''",
                borderRadius: "50%"
              }
            })
          }}>
          <Grid sx={{ mt: 1.4 }}>
            <Icon icon="codicon:account" color="white" width={25} />
          </Grid>

          {/* <Avatar sx={{ backgroundColor: "red" }} alt="photoURL">
          AD
        </Avatar> */}
        </IconButton>
      </Grid>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75
            }
          }
        }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userData?.username}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {userData?.email}
          </Typography>
        </Box>

        <Stack sx={{ p: 1, mt: -3 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              onClick={() => {
                navigate("/adminProfile");
              }}
              key={option.label}>
              <Icon icon="iconamoon:profile" width={25} />

              <Typography color="secondary.contrastText" sx={{ ml: 1 }}>
                {" "}
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          <Icon icon="uiw:logout" width={25} />

          <Typography color="secondary.contrastText" sx={{ ml: 1 }}>
            {t("profile.logout")}
          </Typography>
        </MenuItem>
      </Popover>
    </>
  );
}
