import { useEffect, useState } from "react";
import { Grid, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import AdminButton from "components/adminButton";
import Loader from "components/loader/loader";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { Menu } from "@mui/material";
import moment from "moment/moment";
import { getMomentFormat } from "constants/dateTime.constant";
import TableActionButton from "components/adminTableActionButton";
import AlertDialog from "components/alertDialog";
import { useNavigate } from "react-router-dom";
import { getPlansList } from "store";
import { clearPlanDetails } from "store";
import { deletePlan } from "store";
import { getPlanSubType } from "utils/constant";
export default function Plans() {
  var page = 1;
  const perPage = 10;
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { planListData, totalPlanCount, addCouponStatus } = useSelector(
    (state) => state.couponSlice
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [planId, setPlanId] = useState();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [perPageRows, setNumberOfRows] = useState(perPage);
  const [pageCheck, setPageCheck] = useState(1);
  useEffect(() => {
    fetchPlanList();
  }, []);
  useEffect(() => {
    if (addCouponStatus) {
      fetchPlanList();
    }
  }, [addCouponStatus]);
  const fetchPlanList = (page, numberOfRows) => {
    let data = {
      page: page ? page : 1,
      perPage: numberOfRows ? numberOfRows : 10,
      language: i18n?.language
    };
    dispatch(getPlansList(data, setIsLoading));
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event, dataIndex) => {
    setPlanId(dataIndex);
    setAnchorEl(event.currentTarget);
  };
  const handleEdit = () => {
    setAnchorEl(null);
    navigate(`/plan/${planId}`);
  };
  const handleDelete = () => {
    setAnchorEl(null);
    setDeleteDialog(true);
  };
  const handleDeletePlan = () => {
    let data = {
      planId: planId,
      language: i18n.language
    };
    dispatch(deletePlan(data, setDeleteDialog));
  };
  const optionMenu = () => {
    return (
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={handleEdit}>{t("category.edit")}</MenuItem>
        <MenuItem onClick={handleDelete}>{t("category.delete")}</MenuItem>
      </Menu>
    );
  };
  const options = {
    count: totalPlanCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPage: perPageRows,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: t("category.rowsPerPage"),
        displayRows: "of"
      },
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`
      }
    },
    onChangePage(currentPage) {
      if (currentPage >= pageCheck) {
        if (currentPage === 0) {
          currentPage = currentPage + 1;
        }
        setPageCheck(currentPage);
        page = currentPage + 1;
        fetchPlanList(page, "");
      } else {
        if (pageCheck > 0) {
          page = pageCheck - 1;
        }
        setPageCheck(page);
        fetchPlanList(page, "");
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      setNumberOfRows(numberOfRows);
      fetchPlanList(page, numberOfRows);
    }
  };
  const columns = [
    {
      name: "name",
      label: t("plans.planName"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                maxWidth: 140,
                fontSize: 14
              }}>
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </Typography>
          );
        }
      }
    },
    {
      name: "type",
      label: t("plans.type"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                maxWidth: 140,
                fontSize: 14
              }}>
              By {value.charAt(0).toUpperCase() + value.slice(1)}
            </Typography>
          );
        }
      }
    },
    {
      name: "subType",
      label: "Plan Sub Type",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography
              style={{
                fontSize: 14
              }}>
              {getPlanSubType(value)}
            </Typography>
          );
        }
      }
    },
    {
      name: "duration",
      label: "Plan Expire Date",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography
              style={{
                fontSize: 14
              }}>
              {value === 0 ? "Unlimited" : value}
            </Typography>
          );
        }
      }
    },
    {
      name: "numberOfAds",
      label: t("plans.noOfAds"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography
              style={{
                fontSize: 14
              }}>
              {value}
            </Typography>
          );
        }
      }
    },
    {
      name: "isActive",
      label: "Plan Active Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography
              style={{
                fontSize: 14
              }}>
              {value ? "Enable" : "Disable"}
            </Typography>
          );
        }
      }
    },
    {
      name: "createdAt",
      label: t("products.createdAt"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          var localTime = moment.utc(value).toDate();
          return <div style={{ alignItems: "center" }}>{getMomentFormat(localTime)}</div>;
        }
      }
    },
    // {
    //   name: "isActive",
    //   label: "Plan Active Status",
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: (value) => {
    //       return (
    //         <Tooltip title={value}>
    //           <Switch {...label} checked={value} />
    //         </Tooltip>
    //       );
    //     }
    //   }
    // },
    {
      name: "id",
      label: t("category.action"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (dataIndex) => {
          return (
            <>
              <TableActionButton
                style={{ marginLeft: 10 }}
                onClick={(event) => handleClick(event, dataIndex)}
              />
              {optionMenu()}
            </>
          );
        }
      }
    }
  ];

  return (
    <>
      <AlertDialog
        open={deleteDialog}
        title="Warning"
        text={t("plans.deletePlan")}
        handleClose={setDeleteDialog}
        handleAgree={handleDeletePlan}
      />

      <Grid container>
        <Grid
          container
          sx={{
            border: " 1px solid rgba(224, 224, 224, 1)",
            p: 2,
            backgroundColor: "white"
          }}>
          <Grid container sx={{ justifyContent: "space-between" }}>
            <Grid sx={{ mt: 1 }}>
              <Typography variant="body1" sx={{ fontSize: 18 }} color="secondary.contrastText">
                Plans List
              </Typography>
            </Grid>
            <Grid>
              <AdminButton
                sx={{ mt: "3%" }}
                title={t("plans.btn")}
                onClick={() => {
                  dispatch(clearPlanDetails());
                  navigate("/create-plan");
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {isLoading ? (
          <Grid
            container
            sx={{
              border: "1px solid rgba(224, 224, 224, 1)",
              mt: 2,
              height: 300,
              backgroundColor: "white"
            }}>
            <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}></Grid>
          </Grid>
        ) : (
          <>
            {planListData && planListData.length > 0 ? (
              <Grid container>
                <Grid xs={12}>
                  <MUIDataTable
                    // title={"Events List"}
                    data={planListData}
                    columns={columns}
                    options={options}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid
                  container
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    mt: 2,
                    height: 300,
                    backgroundColor: "white"
                  }}>
                  <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
                    <Typography sx={{ fontSize: 24, fontWeight: 500, mt: "7.5rem" }}>
                      {t("category.noRecordFound")}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}

        {isLoading && <Loader open={isLoading} text={t("adrena.pleaseWait")} />}
      </Grid>
    </>
  );
}
