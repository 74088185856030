import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  // InputAdornment,
  List,
  ListItemText,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addressSearch } from "store";
import { clearAddressSearch } from "store";
import { addressPlaced } from "store";
import AdminButton from "components/adminButton";
import { useEffect } from "react";
// import i18next from 'i18next';

function valuetext(value) {
  return `${value}°C`;
}

const ProductFilterDialog = (props) => {
  const {
    // badgeData,
    addressData,
    productdata,
    onClick,
    rangeData,
    userlist,
    ShowDialog,
    setShowDialog,
    productList
  } = props;

  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  var [range, setRange] = useState([0, 100]);
  var [productStatus, setProductStatus] = useState("");
  var [address, setAddress] = useState("");
  var [city, setCity] = useState("");
  const { addressListData, adressData } = useSelector((state) => state.userSlice);
  useEffect(() => {
    adressData?.address_components.map((item) => {
      {
        item.types.map((data) => {
          if (data === "administrative_area_level_3") {
            setCity(item.long_name);
          }
        });
      }
    });
  }, [adressData]);
  // badgeData(badge);
  addressData(city);
  productdata(productStatus);
  addressData(address);
  rangeData(range);

  const handleChange1 = (event, newValue) => {
    setRange(newValue);
  };
  const handleChangeAccountType = (event, newValue) => {
    setProductStatus(newValue);
  };

  const clearFilter = () => {
    productStatus = "";
    city = "";
    address = "";
    range = [0, 100];
    productdata("");
    addressData("");
    productdata("");
    addressData("");
    rangeData("");
    userlist();
    onClose();
    productList();
  };

  // const handleChangeBadge = (event, newValue) => {
  //   setBadge(newValue);
  // };

  const onClose = () => {
    setShowDialog(!ShowDialog);
  };

  const handleSearch = (event, value) => {
    if (value) {
      let data = {
        pattern: value,
        language: i18n.language
      };
      dispatch(addressSearch(data));
    } else {
      dispatch(clearAddressSearch({ data: [] }));
    }
  };
  const handlePlaceId = (value, option) => {
    setAddress(option?.description);
    let data = {
      placeId: option?.place_id,
      language: i18n.language
    };
    dispatch(addressPlaced(data));
  };

  return (
    <Dialog
      open={ShowDialog}
      PaperProps={{
        style: {
          backgroundColor: "#323232",
          boxShadow: "none",
          width: "578px"
        }
      }}>
      <DialogTitle>
        <Typography
          sx={{
            color: "#E7E9EA",
            fontSize: "22px",
            fontFamily: "setimo",
            fontWeight: 700
          }}>
          {t("user.filter")}
        </Typography>

        <IconButton sx={{ float: "right", mt: -4.8 }} onClick={onClose}>
          <Icon
            icon="system-uicons:cross"
            color="#E7E9EA"
            width={"23.63px"}
            height={"23.63px"}
            style={{ fontWeight: 700 }}
          />
        </IconButton>
      </DialogTitle>
      <Divider light />

      <DialogContent>
        <Grid container spacing={1.5} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <FormLabel style={{ color: "#E7E9EA" }} id="demo-row-radio-buttons-group-label">
              Region
            </FormLabel>
            <Autocomplete
              freeSolo
              options={addressListData}
              value={address}
              getOptionLabel={(option) => option.description || address}
              onChange={handlePlaceId}
              onInputChange={handleSearch}
              renderOption={(props, option) => {
                const { description } = option;
                return (
                  <List
                    {...props}
                    style={{
                      backgroundColor: "#323232",
                      marginTop: "-0.5rem",
                      marginBottom: "-0.5rem"
                    }}>
                    <ListItemText
                      sx={{
                        fontSize: "16px",
                        color: "#E7E9EA",
                        fontWeight: 400,
                        lineHeight: "22px",
                        fontFamily: "Setimo"
                      }}>
                      {description}
                    </ListItemText>
                  </List>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Enter the city"
                  InputLabelProps={{
                    style: { color: "#E7E9EA" }
                  }}
                  sx={{
                    mt: 1,

                    input: {
                      color: "#E7E9EA",
                      fontFamily: "setimo"
                    },
                    "& .MuiAutocomplete-inputRoot": {
                      borderRadius: "0px"
                    },
                    "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
                      color: "#E7E9EA",
                      visibility: "visible"
                    }
                  }}
                  InputProps={{
                    ...params.InputProps
                  }}
                />
              )}
            />
            <Divider light sx={{ mt: 3 }} />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel style={{ color: "#E7E9EA" }} id="demo-row-radio-buttons-group-label">
                Product Status
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={productStatus}
                onChange={handleChangeAccountType}>
                <FormControlLabel
                  value="draft"
                  control={
                    <Radio
                      sx={{
                        color: "#E7E9EA",
                        "&.Mui-checked": {
                          color: "#E7E9EA"
                        }
                      }}
                    />
                  }
                  label={<Typography sx={{ color: "#E7E9EA" }}>Draft</Typography>}
                />
                <FormControlLabel
                  value="publish"
                  control={
                    <Radio
                      sx={{
                        color: "#E7E9EA",
                        "&.Mui-checked": {
                          color: "#E7E9EA"
                        }
                      }}
                    />
                  }
                  label={<Typography sx={{ color: "#E7E9EA" }}>Publish</Typography>}
                />
                <FormControlLabel
                  value="decline"
                  control={
                    <Radio
                      sx={{
                        color: "#E7E9EA",
                        "&.Mui-checked": {
                          color: "#E7E9EA"
                        }
                      }}
                    />
                  }
                  label={<Typography sx={{ color: "#E7E9EA" }}>Decline</Typography>}
                />
                <FormControlLabel
                  value="approve"
                  control={
                    <Radio
                      sx={{
                        color: "#E7E9EA",
                        "&.Mui-checked": {
                          color: "#E7E9EA"
                        }
                      }}
                    />
                  }
                  label={<Typography sx={{ color: "#E7E9EA" }}>Approve</Typography>}
                />
                <FormControlLabel
                  value="sold"
                  control={
                    <Radio
                      sx={{
                        color: "#E7E9EA",
                        "&.Mui-checked": {
                          color: "#E7E9EA"
                        }
                      }}
                    />
                  }
                  label={<Typography sx={{ color: "#E7E9EA" }}>Sold</Typography>}
                />
                <FormControlLabel
                  value=""
                  control={
                    <Radio
                      sx={{
                        color: "#E7E9EA",
                        "&.Mui-checked": {
                          color: "#E7E9EA"
                        }
                      }}
                    />
                  }
                  label={<Typography sx={{ color: "#E7E9EA" }}>All</Typography>}
                />
              </RadioGroup>
            </FormControl>
            <Divider light sx={{ mt: 3 }} />
          </Grid>

          {/* <Grid item xs={12}>
            <FormControl>
              <FormLabel style={{ color: "#E7E9EA" }} id="demo-row-radio-buttons-group-label">
                Seller Verification Status
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={handleChangeBadge}
                value={badge}>
                <FormControlLabel
                  value="verify"
                  control={
                    <Radio
                      sx={{
                        color: "#E7E9EA",
                        "&.Mui-checked": {
                          color: "#E7E9EA"
                        }
                      }}
                    />
                  }
                  label={<Typography sx={{ color: "#E7E9EA" }}>Verified</Typography>}
                />
                <FormControlLabel
                  value="nonVerify"
                  control={
                    <Radio
                      sx={{
                        color: "#E7E9EA",
                        "&.Mui-checked": {
                          color: "#E7E9EA"
                        }
                      }}
                    />
                  }
                  label={<Typography sx={{ color: "#E7E9EA" }}>Non Verified</Typography>}
                />
                <FormControlLabel
                  value=""
                  control={
                    <Radio
                      sx={{
                        color: "#E7E9EA",
                        "&.Mui-checked": {
                          color: "#E7E9EA"
                        }
                      }}
                    />
                  }
                  label={<Typography sx={{ color: "#E7E9EA" }}>Both</Typography>}
                />
              </RadioGroup>
            </FormControl>
            <Divider light sx={{ mt: 3 }} />
          </Grid> */}
          <Divider />

          <Grid item xs={12}>
            <FormLabel style={{ color: "#E7E9EA" }} id="demo-row-radio-buttons-group-label">
              Price Range
            </FormLabel>
            <Slider
              getAriaLabel={() => "Temperature range"}
              value={range}
              onChange={handleChange1}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
              min={0}
              max={1000}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid sx={{ p: 2 }}>
            <AdminButton onClick={onClick} title="Save" />
          </Grid>
          <Grid sx={{ p: 2 }}>
            <AdminButton onClick={clearFilter} title="Reset" />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ProductFilterDialog;
