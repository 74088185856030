import { Icon } from "@iconify/react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import AdrenaButton from "components/button";
import { messageStyle, validText } from "components/style";
import moment from "moment";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import uploadImage from "../../assets/img/uploadImage.png";
export default function ChatDialog(props) {
  const { showDialog, setShowDialog, loader, data } = props;
  const { t } = useTranslation();
  const { chatListData } = useSelector((state) => state.couponSlice);
  var theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const messageEl = useRef();
  const onClose = () => {
    setShowDialog(!showDialog);
  };
  console.log("isMobile", isMobile);
  return (
    <Dialog
      open={showDialog}
      maxWidth={isMobile ? "xl" : "sm"}
      PaperProps={{
        style: {
          backgroundColor: "#323232",
          boxShadow: "none",
          borderRadius: 0
        }
      }}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between"
        }}>
        <Typography
          sx={{
            fontFamily: "setimo",
            fontWeight: 700,
            fontSize: "25px",
            color: "white"
          }}>
          Order Chat
        </Typography>
        <IconButton sx={{ float: "right", marginRight: "-12px" }} onClick={onClose}>
          <Icon
            icon="ion:close-sharp"
            color="white"
            width={"23.63px"}
            height={"23.63px"}
            style={{ fontWeight: 700 }}
          />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          ref={messageEl}
          id="scrollableDiv"
          sx={{
            overflow: "auto",
            display: "flex",
            flexDirection: "column-reverse",
            minHeight: "700px",
            maxHeight: "700px",
            mt: 1,
            px: 2,
            pb: 1,
            width: "100%",
            background: "black",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "0.2em"
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent"
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888"
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555"
            }
          }}>
          {chatListData?.map((item, index) => {
            return (
              <>
                <InfiniteScroll
                  key={index}
                  dataLength={item?.data?.length}
                  //   next={handleScroll}
                  inverse={true}
                  hasMore={true}
                  scrollableTarget="scrollableDiv">
                  {loader && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress thickness="4" size={30} sx={{ color: "red" }} />
                    </Box>
                  )}
                  <Typography style={validText} sx={{ textAlign: "center", pt: 1 }} key={index}>
                    {item?.date}
                  </Typography>

                  {item?.data?.map((message, index) => {
                    return (
                      <>
                        <Typography
                          key={index}
                          sx={{
                            fontSize: "12px",
                            fontWeight: 700,
                            fontFamily: "Setimo",
                            lineHeight: "16px",
                            color: "white",
                            textAlign: message?.senderUserId === data ? "right" : "left"
                          }}>
                          {message?.senderUserId === data ? "Buyer" : "Seller"}
                          &nbsp;
                          <span style={{ fontWeight: 400 }}>
                            {moment(message?.dateTime).format("HH:mm")}
                          </span>
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent:
                              message?.senderUserId === data ? "flex-end" : "flex-start",
                            mb: 2
                          }}>
                          <Paper
                            variant="outlined"
                            sx={{
                              px: 3,
                              p: 1,
                              minWidth: "min-content",
                              maxWidth: "80%",
                              backgroundColor:
                                message?.senderUserId === data ? "#323232" : "#E7E9EA",
                              borderRadius: 0
                            }}>
                            {message?.messageType === "text" && (
                              <Typography
                                sx={{
                                  fontFamily: "setimo",
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  fontWeight: 400,
                                  whiteSpace: "pre-line",
                                  color: message?.senderUserId === data ? "#E7E9EA" : "black"
                                }}>
                                {message?.chatText}
                              </Typography>
                            )}

                            {message?.messageType === "pendingPayment" &&
                              (message?.safeBuyingStatus === "pending" ? (
                                message?.senderUserId === data ? (
                                  <Box>
                                    <Typography
                                      sx={{
                                        fontSize: "13px",
                                        fontWeight: 800,
                                        fontFamily: "setimoBold",
                                        lineHeight: "16px",
                                        color: "#E7E9EA"
                                      }}>
                                      {t("message.adrenaAutoMessage")}
                                    </Typography>
                                    <Typography
                                      style={messageStyle}
                                      sx={{
                                        color: "#E7E9EA",
                                        mb: 1.5,
                                        pt: 0.5
                                      }}>
                                      {t("message.payNowToBook")}
                                    </Typography>

                                    <AdrenaButton
                                      fullWidth
                                      style={{
                                        height: 30,
                                        fontSize: "12px",
                                        paddingTop: "0.6rem"
                                      }}
                                      text={t("adSuccessfull.btnPayNow")}
                                    />
                                  </Box>
                                ) : (
                                  <>
                                    <Typography
                                      sx={{
                                        fontSize: "13px",
                                        fontWeight: 800,
                                        fontFamily: "setimoBold",
                                        lineHeight: "16px",
                                        color: "black"
                                      }}>
                                      {t("message.adrenaAutoMessage")}
                                    </Typography>
                                    <Typography
                                      style={messageStyle}
                                      sx={{
                                        color: "black",
                                        mt: 0.5
                                      }}>
                                      {t("message.buyerRequestForPayment")}
                                    </Typography>
                                  </>
                                )
                              ) : message?.senderUserId === data ? (
                                <Box>
                                  <Typography
                                    style={messageStyle}
                                    sx={{
                                      color: "#E7E9EA",
                                      mb: 1.5
                                    }}>
                                    {`${t("message.confirmOrderAfterPayment")}`}
                                  </Typography>
                                </Box>
                              ) : (
                                <>
                                  <Typography
                                    style={messageStyle}
                                    sx={{
                                      color: "black"
                                    }}>
                                    payment is done and confirmed by adrena team.
                                  </Typography>
                                </>
                              ))}
                            {message?.messageType === "sellerConfirmation" &&
                              (message?.senderUserId === data ? (
                                <>
                                  <Typography
                                    sx={{
                                      fontSize: "13px",
                                      fontWeight: 800,
                                      fontFamily: "setimoBold",
                                      lineHeight: "16px",
                                      color: "white"
                                    }}>
                                    {" "}
                                    {t("message.adrenaAutoMessage")}
                                  </Typography>
                                  <Typography
                                    style={messageStyle}
                                    sx={{
                                      color: "white",
                                      mt: 0.5
                                    }}>
                                    {t("message.shippingDetails")}
                                    {t("message.confirmYourOrder")}
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <Typography
                                    sx={{
                                      fontSize: "13px",
                                      fontWeight: 800,
                                      fontFamily: "setimoBold",
                                      lineHeight: "16px",
                                      color: "black"
                                    }}>
                                    {t("message.adrenaAutoMessage")}
                                  </Typography>
                                  <Typography
                                    style={messageStyle}
                                    sx={{
                                      color: "black",
                                      mt: 0.5
                                    }}>
                                    {t("message.pleseConfirmOrder")}
                                  </Typography>
                                  <AdrenaButton
                                    fullWidth
                                    style={{
                                      height: 30,
                                      fontSize: "12px",
                                      paddingTop: "0.6rem",
                                      background: "green"
                                    }}
                                    text={
                                      message?.safeBuyingStatus === "accepted"
                                        ? t("purchaseAd.btnConfirmed")
                                        : t("purchaseAd.btnConfirm")
                                    }
                                  />
                                </>
                              ))}
                            {message?.messageType === "dispatchConfirmation" &&
                              (message?.senderUserId !== data ? (
                                <>
                                  <Typography
                                    sx={{
                                      fontSize: "13px",
                                      fontWeight: 800,
                                      fontFamily: "setimoBold",
                                      lineHeight: "16px",
                                      color: "black"
                                    }}>
                                    {t("message.adrenaAutoMessage")}
                                  </Typography>
                                  <Typography
                                    style={messageStyle}
                                    sx={{
                                      color: "black",
                                      mt: 0.5
                                    }}>
                                    {t("message.productWillDispatched")}
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <Box sx={{ p: 1 }}>
                                    {message?.safeBuyingStatus === "uploaded" ? (
                                      <>
                                        <Typography
                                          sx={{
                                            fontSize: "13px",
                                            fontWeight: 800,
                                            fontFamily: "setimoBold",
                                            lineHeight: "16px",
                                            color: "white"
                                          }}>
                                          {t("message.adrenaAutoMessage")}
                                        </Typography>
                                        <Typography
                                          style={messageStyle}
                                          sx={{
                                            color: "white",
                                            mb: 1.5,
                                            mt: 0.5
                                          }}>
                                          {t("message.thanksForSharingshippingDetail")}
                                        </Typography>
                                        <AdrenaButton
                                          fullWidth
                                          style={{
                                            height: 30,
                                            fontSize: "12px",
                                            paddingTop: "0.6rem",
                                            backgroundColor: "green"
                                          }}
                                          text={t("purchaseAd.btnDispatched")}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <Typography
                                          sx={{
                                            fontSize: "13px",
                                            fontWeight: 800,
                                            fontFamily: "setimoBold",
                                            lineHeight: "16px",
                                            color: "white"
                                          }}>
                                          {t("message.adrenaAutoMessage")}
                                        </Typography>
                                        <Typography
                                          style={messageStyle}
                                          sx={{
                                            color: "white",
                                            mt: 0.5
                                          }}>
                                          {t("message.pleaseShareShippingDetail")}
                                        </Typography>
                                        <Box
                                          sx={{
                                            height: "16rem",
                                            width: "auto",
                                            my: 2,
                                            border: message?.mediaFullUrl ? "" : "2px dashed white",
                                            borderRadius: 5
                                          }}>
                                          <img
                                            src={
                                              message?.mediaFullUrl
                                                ? message?.mediaFullUrl
                                                : uploadImage
                                            }
                                            height={"100%"}
                                            width={"100%"}
                                            alt={""}
                                          />
                                        </Box>
                                        <AdrenaButton
                                          fullWidth
                                          style={{
                                            height: 30,
                                            fontSize: "12px",
                                            paddingTop: "0.6rem"
                                          }}
                                          text={t("purchaseAd.btnDispatch")}
                                        />
                                      </>
                                    )}
                                  </Box>
                                </>
                              ))}
                            {message?.messageType === "buyerProductConfirmation" &&
                              (message?.senderUserId === data ? (
                                <>
                                  <Typography
                                    sx={{
                                      fontSize: "13px",
                                      fontWeight: 800,
                                      fontFamily: "setimoBold",
                                      lineHeight: "16px",
                                      color: "#E7E9EA"
                                    }}>
                                    {t("message.adrenaAutoMessage")}
                                  </Typography>
                                  <Typography
                                    style={messageStyle}
                                    sx={{
                                      color: "#E7E9EA",
                                      mt: 0.5
                                    }}>
                                    {t("message.buyerConfirmReceivedProduct")}
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <Box sx={{ p: 1 }}>
                                    {message?.safeBuyingStatus === "uploaded" ? (
                                      <>
                                        <Typography
                                          sx={{
                                            fontSize: "13px",
                                            fontWeight: 800,
                                            fontFamily: "setimoBold",
                                            lineHeight: "16px",
                                            color: "black"
                                          }}>
                                          {t("message.adrenaAutoMessage")}
                                        </Typography>
                                        <Typography
                                          style={messageStyle}
                                          sx={{
                                            color: "black",
                                            mb: 1.5,
                                            mt: 0.5
                                          }}>
                                          {t("message.receivedProductbuyer")}
                                        </Typography>
                                        <AdrenaButton
                                          fullWidth
                                          style={{
                                            height: 30,
                                            fontSize: "12px",
                                            paddingTop: "0.6rem",
                                            backgroundColor: "green"
                                          }}
                                          text={t("purchaseAd.btnReceived")}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <Typography
                                          sx={{
                                            fontSize: "13px",
                                            fontWeight: 800,
                                            fontFamily: "setimoBold",
                                            lineHeight: "16px",
                                            color: "Black"
                                          }}>
                                          {t("message.adrenaAutoMessage")}
                                        </Typography>
                                        <Typography
                                          style={messageStyle}
                                          sx={{
                                            color: "Black",
                                            mt: 0.5
                                          }}>
                                          {t("message.pleaseUploadProductReceived")}
                                        </Typography>
                                        <Box
                                          sx={{
                                            height: "16rem",
                                            width: "auto",
                                            my: 2,
                                            border: message?.mediaFullUrl ? "" : "2px dashed black",
                                            borderRadius: 5
                                          }}>
                                          <img
                                            src={
                                              message?.mediaFullUrl
                                                ? message?.mediaFullUrl
                                                : uploadImage
                                            }
                                            height={"100%"}
                                            width={"100%"}
                                            alt={""}
                                          />
                                        </Box>
                                        <AdrenaButton
                                          fullWidth
                                          style={{
                                            height: 30,
                                            fontSize: "12px",
                                            paddingTop: "0.6rem"
                                          }}
                                          text={t("purchaseAd.btnReceive")}
                                        />
                                      </>
                                    )}
                                  </Box>
                                </>
                              ))}
                            {message?.messageType === "receivedConfirmationFromBuyer" &&
                              (message?.senderUserId === data ? (
                                <>
                                  <Typography
                                    sx={{
                                      fontSize: "13px",
                                      fontWeight: 800,
                                      fontFamily: "setimoBold",
                                      lineHeight: "16px",
                                      color: "#E7E9EA"
                                    }}>
                                    {t("message.adrenaAutoMessage")}
                                  </Typography>
                                  <Typography
                                    style={messageStyle}
                                    sx={{
                                      color: "#E7E9EA",
                                      mt: 0.5
                                    }}>
                                    {t("message.orderComplete")}
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <Typography
                                    sx={{
                                      fontSize: "13px",
                                      fontWeight: 800,
                                      fontFamily: "setimoBold",
                                      lineHeight: "16px",
                                      color: "Black"
                                    }}>
                                    {t("message.adrenaAutoMessage")}
                                  </Typography>
                                  <Typography
                                    style={messageStyle}
                                    sx={{
                                      color: "black"
                                    }}>
                                    {t("message.orderComplete")}
                                  </Typography>
                                </>
                              ))}
                            {message?.messageType === "contract" && (
                              <>
                                {message?.senderUserId === data ? (
                                  <Typography
                                    style={messageStyle}
                                    sx={{
                                      color: "#E7E9EA"
                                    }}>
                                    {`${t("message.safeBuyingRequest")} `}
                                  </Typography>
                                ) : (
                                  <>
                                    <Box
                                      sx={{
                                        backgroundColor: "#E7E9EA",
                                        color: "black",
                                        px: 1,
                                        pt: 0.5
                                      }}>
                                      <Typography
                                        sx={{
                                          fontSize: "13px",
                                          fontWeight: 800,
                                          fontFamily: "setimoBold",
                                          lineHeight: "16px",
                                          color: "black"
                                        }}>
                                        {t("message.adrenaAutoMessage")}
                                      </Typography>
                                      <Typography
                                        style={messageStyle}
                                        sx={{
                                          pt: 0.5
                                        }}>
                                        {t("message.buyerHiredAdrenaForIntermediaryService")}
                                      </Typography>
                                      <Divider
                                        sx={{ backgroundColor: "black", my: 1, mx: -2.2, pb: 0.5 }}
                                      />
                                      <Typography
                                        style={messageStyle}
                                        sx={{
                                          pt: 0.5
                                        }}>
                                        {t("message.buyerProposalToSeller")}
                                      </Typography>
                                      <Stack
                                        direction="row"
                                        sx={{
                                          gap: 2,
                                          display: "flex",
                                          justifyContent: "center",
                                          py: 0.5
                                        }}>
                                        {message?.safeBuyingStatus === "accepted" ? (
                                          <AdrenaButton
                                            fullWidth
                                            text={t("message.btnAccepted")}
                                            style={{
                                              backgroundColor: "green",
                                              color: "white",
                                              height: 28,
                                              paddingTop: "0.6rem",
                                              fontSize: "12px"
                                            }}
                                          />
                                        ) : message?.safeBuyingStatus === "rejected" ? (
                                          <AdrenaButton
                                            fullWidth
                                            text={t("message.btnRejected")}
                                            style={{
                                              backgroundColor: "red",
                                              color: "white",
                                              height: 28,
                                              paddingTop: "0.6rem",
                                              fontSize: "12px"
                                            }}
                                          />
                                        ) : (
                                          <>
                                            <AdrenaButton
                                              text={t("message.btnAccept")}
                                              style={{
                                                backgroundColor: "green",
                                                color: "white",
                                                height: 28,
                                                paddingTop: "0.6rem",
                                                paddingLeft: "1rem",
                                                paddingRight: "1rem",
                                                fontSize: "12px"
                                              }}
                                            />
                                            <AdrenaButton
                                              text={t("message.btnRefuse")}
                                              style={{
                                                backgroundColor: "black",
                                                color: "white",
                                                height: 28,
                                                paddingTop: "0.6rem",
                                                paddingLeft: "1rem",
                                                paddingRight: "1rem",
                                                fontSize: "12px"
                                              }}
                                            />
                                          </>
                                        )}
                                      </Stack>
                                      <Divider
                                        sx={{ backgroundColor: "black", my: 1, mx: -2.2, pb: 0.5 }}
                                      />
                                      <Typography
                                        style={messageStyle}
                                        sx={{
                                          pt: 0.5
                                        }}>
                                        {t("message.speakToSpecialistDescription")}
                                      </Typography>
                                      <Stack
                                        direction="row"
                                        sx={{
                                          gap: 2,
                                          display: "flex",
                                          justifyContent: "center",
                                          py: 0.5
                                        }}>
                                        <AdrenaButton
                                          text={t("message.btnSpeakToSpecialist")}
                                          style={{
                                            backgroundColor: "black",
                                            color: "white",
                                            height: 28,
                                            paddingTop: "0.6rem",
                                            paddingLeft: "1rem",
                                            paddingRight: "1rem",
                                            fontSize: "12px"
                                          }}
                                        />
                                      </Stack>
                                    </Box>
                                  </>
                                )}
                              </>
                            )}
                            {message?.messageType === "contractlink" && (
                              <>
                                <Box sx={{ px: 1, pt: 0.5 }}>
                                  <Typography
                                    sx={{
                                      fontSize: "13px",
                                      fontWeight: 800,
                                      fontFamily: "setimoBold",
                                      lineHeight: "16px",
                                      color: message?.senderUserId === data ? "#E7E9EA" : "black"
                                    }}>
                                    {t("message.adrenaAutoMessage")}
                                  </Typography>
                                  <Typography
                                    style={messageStyle}
                                    sx={{
                                      pt: 0.5,
                                      color: message?.senderUserId === data ? "#E7E9EA" : "black"
                                    }}>
                                    {`${message?.userName} ${t("message.signContract")}`}
                                  </Typography>
                                  <Stack
                                    direction="row"
                                    sx={{
                                      gap: 2,
                                      display: "flex",
                                      justifyContent: "center",
                                      py: 1
                                    }}>
                                    <AdrenaButton
                                      fullWidth
                                      text={t("message.viewContract")}
                                      style={{
                                        backgroundColor: "transparent",
                                        color: message?.senderUserId === data ? "#E7E9EA" : "black",
                                        height: 30,
                                        paddingTop: "0.6rem",
                                        border: `1px solid ${
                                          message?.senderUserId === data ? "#E7E9EA" : "black"
                                        } `,
                                        fontSize: "12px"
                                      }}
                                    />
                                  </Stack>
                                </Box>
                              </>
                            )}
                            {message?.messageType === "image" && (
                              <Box sx={{ height: "18rem", width: "18rem" }} key={index}>
                                <img
                                  src={message?.mediaFullUrl}
                                  height={"100%"}
                                  width={"100%"}
                                  alt="logo"
                                />
                              </Box>
                            )}
                            {message?.messageType === "video" && (
                              <Box sx={{ height: "18rem", width: "18rem" }} key={index}>
                                <video
                                  loop
                                  playsInline
                                  autoPlay
                                  muted
                                  id="video"
                                  height={"100%"}
                                  width={"100%"}>
                                  <source src={message?.mediaFullUrl} type="video/mp4" />
                                </video>
                              </Box>
                            )}
                          </Paper>
                        </Box>
                      </>
                    );
                  })}
                </InfiniteScroll>
              </>
            );
          })}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
