import { useEffect, useState } from "react";
import { Box, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import AdminButton from "components/adminButton";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import AdrenaInput from "components/adrenaInput";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import Loader from "components/loader/loader";
import { createService } from "store";
import { viewService } from "store";
import { editService } from "store";

export default function AddUpdateVerifyPlan() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [formError, setFormError] = useState({});
  const [formData, setFormData] = useState({});
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [inputList, setInputList] = useState([""]);

  const { viewServiceDetail } = useSelector((state) => state.couponSlice);

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      keyFeature: inputList
    }));
  }, [inputList]);

  useEffect(() => {
    if (id != ":id") {
      setIsLoading(true);
      let requestParams = {
        serviceId: id,
        language: i18n?.language
      };
      dispatch(viewService(requestParams, setIsLoading));
    }
  }, [id]);
  useEffect(() => {
    if (viewServiceDetail) {
      setFormData(viewServiceDetail);
      setInputList(viewServiceDetail?.keyFeature);
    }
  }, [viewServiceDetail]);
  const handleSubmit = () => {
    if (!formData.name) {
      setFormError((formError) => ({
        ...formError,
        nameError: t("plans.nameError")
      }));
      return;
    }
    if (!formData.description) {
      setFormError((formError) => ({
        ...formError,
        descriptionError: t("plans.descriptionError")
      }));
      return;
    }
    if (!formData.durationType) {
      setFormError((formError) => ({
        ...formError,
        durationTypeError: t("plans.subTypeError")
      }));
      return;
    }
    if (formData.isActive === undefined) {
      setFormError((formError) => ({
        ...formError,
        isActiveError: t("plans.isActiveError")
      }));
      return;
    }
    if (!formData.amount) {
      setFormError((formError) => ({
        ...formError,
        amountError: t("plans.isActiveError")
      }));
      return;
    }
    if (formData.durationType === "limited" && !formData?.days) {
      setFormError((formError) => ({
        ...formError,
        durationError: t("plans.durationError")
      }));
      return;
    }
    if (id != ":id") {
      setIsLoading(true);
      let data = {
        serviceId: id,
        name: formData?.name,
        description: formData?.description,
        type: "verifiedBadge",
        durationType: formData?.durationType,
        days: formData?.durationType === "limited" ? formData?.days * 1 : "",
        amount: formData?.amount * 1,
        isActive: !!formData?.isActive,
        keyFeature: formData?.keyFeature,
        language: "en/pt/es"
      };
      dispatch(editService(data, navigate, setIsLoading));
    } else if (id === ":id") {
      setIsLoading(true);
      let data = {
        name: formData?.name,
        description: formData?.description,
        type: "verifiedBadge",
        durationType: formData?.durationType,
        days: formData?.durationType === "limited" ? formData?.days * 1 : "",
        amount: formData?.amount * 1,
        isActive: !!formData?.isActive,
        keyFeature: formData?.keyFeature,
        language: i18n?.language
      };
      dispatch(createService(data, navigate, setIsLoading));
    }
  };
  const handleRemoveInput = (index) => {
    let temp = [...inputList];
    temp.splice(index, 1);
    setInputList(temp);
  };
  const handleInputChange = (value, index) => {
    var temp = [...inputList];
    temp[index] = value;
    setInputList(temp);
  };
  return (
    <>
      <Grid container sx={{ mb: 5 }}>
        <Grid>
          <Typography sx={{ fontSize: 18, fontWeight: 700, color: "#212b36" }}>
            Add Verify Plan
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: "#555" }}>
            {t("plans.addPlan")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          container
          sx={{
            border: " 1px solid rgba(224, 224, 224, 1)",
            background: "white",
            p: 2,
            borderRadius: 1
          }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography
                sx={{ mb: -1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
                {t("plans.planName")}
              </Typography>
              <AdrenaInput
                value={formData.name}
                onChange={(val) => {
                  setFormData((formData) => ({
                    ...formData,
                    name: val.target.value
                  }));
                  setFormError((formError) => ({
                    ...formError,
                    nameError: ""
                  }));
                }}
                formError={formError.nameError}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography
                sx={{ mb: -1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
                {t("plans.planDescription")}
              </Typography>
              <AdrenaInput
                value={formData.description}
                onChange={(val) => {
                  setFormData((formData) => ({
                    ...formData,
                    description: val.target.value
                  }));
                  setFormError((formError) => ({
                    ...formError,
                    descriptionError: ""
                  }));
                }}
                formError={formError.descriptionError}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography
                sx={{ mb: -1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
                Duration Type
              </Typography>
              <RadioGroup
                value={formData?.type}
                onChange={(event) => {
                  setFormData((formData) => ({
                    ...formData,
                    durationType: event.target.value
                  }));
                  setFormError((formError) => ({
                    ...formError,
                    durationTypeError: ""
                  }));
                }}
                sx={{ pt: 2 }}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group">
                <FormControlLabel
                  value="limited"
                  control={<Radio checked={formData?.durationType === "limited"} />}
                  label={
                    <Typography
                      sx={{ pt: 0.1, fontSize: 17, fontWeight: 700, mr: 5, color: "black" }}>
                      Limited
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="unlimited"
                  control={<Radio checked={formData?.durationType === "unlimited"} />}
                  label={
                    <Typography sx={{ pt: 0.1, fontSize: 17, fontWeight: 700, color: "black" }}>
                      Unlimited
                    </Typography>
                  }
                />
              </RadioGroup>
              <Typography sx={{ color: "red", fontSize: 15, pt: 1, pl: 1 }}>
                {formError?.durationTypeError ? formError?.durationTypeError : ""}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography
                sx={{ mb: -1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
                {t("plans.activeStatus")}
              </Typography>
              <RadioGroup
                onChange={(event) => {
                  if (event.target.value == "true") {
                    setFormData((formData) => ({
                      ...formData,
                      isActive: true
                    }));
                  } else {
                    setFormData((formData) => ({
                      ...formData,
                      isActive: false
                    }));
                  }
                  setFormError((formError) => ({
                    ...formError,
                    isActiveError: ""
                  }));
                }}
                value={formData?.isActive}
                sx={{ pt: 2 }}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group">
                <FormControlLabel
                  value={true}
                  control={<Radio checked={formData?.isActive === true} />}
                  label={
                    <Typography
                      sx={{ pt: 0.1, fontSize: 17, fontWeight: 700, mr: 5, color: "black" }}>
                      {t("plans.enable")}
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={false}
                  control={<Radio checked={formData?.isActive === false} />}
                  label={
                    <Typography sx={{ pt: 0.1, fontSize: 17, fontWeight: 700, color: "black" }}>
                      {t("plans.disable")}
                    </Typography>
                  }
                />
              </RadioGroup>
              <Typography sx={{ color: "red", fontSize: 15, pt: 1, pl: 1 }}>
                {formError?.isActiveError && formError?.isActiveError}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography
                sx={{ mb: -1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
                Amount
              </Typography>
              <AdrenaInput
                type="number"
                startIcon={<Icon icon="fa6-solid:brazilian-real-sign" />}
                value={formData?.amount}
                onChange={(val) => {
                  setFormData((formData) => ({
                    ...formData,
                    amount: val.target.value
                  }));
                  setFormError((formError) => ({
                    ...formError,
                    amountError: ""
                  }));
                }}
                formError={formError.amountError}
              />
            </Grid>

            {formData?.durationType === "limited" && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography
                  sx={{
                    mb: -1,
                    color: "#212b36",
                    fontWeight: 500,
                    fontSize: 14,
                    display: "block"
                  }}>
                  Duration
                </Typography>
                <AdrenaInput
                  type="number"
                  endIcon={"Days"}
                  value={formData.days}
                  onChange={(val) => {
                    setFormData((formData) => ({
                      ...formData,
                      days: val.target.value
                    }));
                    setFormError((formError) => ({
                      ...formError,
                      durationError: ""
                    }));
                  }}
                  formError={formError.durationError}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  sx={{
                    mb: -1,
                    color: "#212b36",
                    fontWeight: 500,
                    fontSize: 14,
                    display: "block"
                  }}>
                  {t("plans.features")}
                </Typography>
                <AdminButton
                  onClick={() => setInputList((inputList) => [...inputList, ""])}
                  title={t("button.addMore")}
                  sx={{ bgcolor: "black", "&:hover": { bgcolor: "#757575" }, width: 150 }}
                />
              </Box>
              {formData?.keyFeature?.map((value, index) => {
                return (
                  <AdrenaInput
                    endIcon={
                      index >= 1 && (
                        <Icon
                          style={{ cursor: "pointer" }}
                          icon="gridicons:cross-circle"
                          color="red"
                          width="25"
                          height="25"
                          onClick={() => handleRemoveInput(index)}
                        />
                      )
                    }
                    key={index}
                    multiline={true}
                    rows={3}
                    value={value}
                    onChange={(val) => handleInputChange(val.target.value, index)}
                    formError={formError.descriptionPortugueseError}
                  />
                );
              })}
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={6} sm={6} md={1.5} lg={1.5}>
              <AdminButton
                title={id === ":id" ? t("plans.btnAdd") : t("plans.btnUpdate")}
                onClick={handleSubmit}
                sx={{ width: 150, mr: 1 }}
              />
            </Grid>
            <Grid item xs={6}>
              <AdminButton
                sx={{ bgcolor: "black", "&:hover": { bgcolor: "#757575" }, width: 150 }}
                title={t("button.cancel")}
                onClick={() => {
                  navigate("/plans");
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isLoading && <Loader open={isLoading} text={"Please Wait"} />}
    </>
  );
}
