/** @format */
import moment from "moment";

export const getMomentFormat = (value) => {
  return moment(value).format("DD/MM/YY - HH:mm") + "h";
};

export const getMomentFormatLiveDetail = (value) => {
  return moment(value).format("DD/MM/YY  HH:mm") + "h";
};

export const getMomentTime = (value) => {
  return moment(value).format("DD/MM/YY");
};

export const getMomentTimeSecond = (value) => {
  return moment(value).format("YYYY-MM-DD HH:mm:ss");
};

export const getMomentTimeSecondLive = (value) => {
  return moment(value).format("DD/MM/YYYY-HH:mm:ss a");
};
