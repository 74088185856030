import {
  createTheme,
  filledInputClasses,
  inputLabelClasses
  //tableCellClasses
} from "@mui/material";
import { appColors } from "./colors";

// Used only to create transitions
const muiTheme = createTheme();

export function createComponents(config) {
  const { palette } = config;
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          color: "#424040",
          "&.Mui-selected": {
            backgroundColor: "transparent",
            "&.Mui-focusVisible": { background: "red" },
            color: "#FFFFFF"
          }
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 600,
          letterSpacing: 0
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          textTransform: "none"
        },
        sizeSmall: {
          padding: "6px 16px"
        },
        sizeMedium: {
          padding: "8px 20px"
        },
        sizeLarge: {
          padding: "11px 24px"
        },
        textSizeSmall: {
          padding: "7px 12px"
        },
        textSizeMedium: {
          padding: "9px 16px"
        },
        textSizeLarge: {
          padding: "12px 16px"
        },
        backgroundColor: appColors.primaryColor
      }
    },
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          {
            fontFamily: `Setimo`,
            fontStyle: "normal",
            fontDisplay: "swap",
            fontWeight: "400" // your font weight,
          }
        ]
      },
      styleOverrides: {
        "*": {
          boxSizing: "border-box"
        },
        html: {
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%"
        },
        body: {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%"
        },
        "#__next": {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          height: "100%",
          width: "100%"
        },
        "#nprogress": {
          pointerEvents: "none"
        },
        "#nprogress .bar": {
          backgroundColor: palette.primary.main,
          height: 3,
          left: 0,
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 2000
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            opacity: 1
          }
        }
      }
    },

    MuiInput: {
      styleOverrides: {
        input: {
          fontSize: 14,
          fontWeight: 500,
          lineHeight: "24px",
          "&::placeholder": {
            color: "grey"
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          borderRadius: 2,
          borderStyle: "solid",
          borderWidth: 1,
          overflow: "hidden",
          borderColor: palette.neutral[200],
          transition: muiTheme.transitions.create(["border-color", "box-shadow"]),
          "&:hover": {
            backgroundColor: palette.action.hover
          },
          "&:before": {
            display: "none"
          },
          "&:after": {
            display: "none"
          },
          [`&.${filledInputClasses.disabled}`]: {
            backgroundColor: "transparent"
          },
          [`&.${filledInputClasses.focused}`]: {
            backgroundColor: "transparent",
            borderColor: palette.primary.main,
            boxShadow: `${palette.primary.main} 0 0 0 2px`
          },
          [`&.${filledInputClasses.error}`]: {
            borderColor: palette.error.main,
            boxShadow: `${palette.error.main} 0 0 0 2px`
          }
        },
        input: {
          fontSize: 14,
          fontWeight: 500,
          lineHeight: "24px"
        }
      }
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 500,
          [`&.${inputLabelClasses.filled}`]: {
            transform: "translate(12px, 18px) scale(1)"
          },
          [`&.${inputLabelClasses.shrink}`]: {
            [`&.${inputLabelClasses.standard}`]: {
              transform: "translate(0, -1.5px) scale(0.85)"
            },
            [`&.${inputLabelClasses.filled}`]: {
              transform: "translate(12px, 6px) scale(0.85)"
            },
            [`&.${inputLabelClasses.outlined}`]: {
              transform: "translate(14px, -9px) scale(0.85)"
            }
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 500,
          lineHeight: 1.71,
          minWidth: "auto",
          paddingLeft: 0,
          paddingRight: 0,
          textTransform: "none",
          "& + &": {
            marginLeft: 24
          }
        }
      }
    },

    MUIDataTablePagination: {
      styleOverrides: {
        root: {
          marginTop: 20,
          marginBottom: 20,
          border: "1px solid rgba(224, 224, 224, 1)",
          borderRadius: "0.25rem!important",
          color: "#424040"
        },
        select: {
          color: "#424040"
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          border: "1px solid rgba(224, 224, 224, 1)"
        }
      }
    },

    MuiTableBody: {
      styleOverrides: {
        root: {
          border: "1px solid rgba(224, 224, 224, 1)"
        }
      }
    },

    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: "#f9fafb"
        }
      }
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: palette.divider,
          padding: "15px 16px"
        },
        head: {
          color: "#6b7280",
          fontWeight: "bold"
        },
        body: {
          color: "#424040"
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          border: "1px solid rgba(224, 224, 224, 1)",
          minWidth: 10
        }
      }
    },
    MUIDataTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: "#f9fafb"
        }
      }
    }
  };
}
