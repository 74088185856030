import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  // InputAdornment,
  List,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
  TextField,
  Typography
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addressSearch } from "store";
import { clearAddressSearch } from "store";
import { addressPlaced } from "store";
import AdminButton from "components/adminButton";
// import i18next from 'i18next';

function valuetext(value) {
  return `${value}°C`;
}

const FilterDialog = (props) => {
  const {
    badgeData,
    addressData,
    accountData,
    onClick,
    noOfAddsData,
    adStatusData,
    lastLoginData,
    userlist,
    ShowDialog,
    setShowDialog
  } = props;

  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [noOfAdds, setNoOfAdds] = useState([0, 100]);
  const [accountType, setAccountType] = useState("");
  const [address, setAddress] = useState("");
  const [badge, setBadge] = useState("");
  const [adStatus, setAdStatus] = useState("");
  const [lastLogin, setLastLogin] = useState();
  const { addressListData } = useSelector((state) => state.userSlice);

  badgeData(badge);
  addressData(address);
  accountData(accountType);
  noOfAddsData(noOfAdds);
  adStatusData(adStatus);
  lastLoginData(lastLogin);

  const handleChange = (event) => {
    setLastLogin(event.target.value);
  };

  const handleChange1 = (event, newValue) => {
    setNoOfAdds(newValue);
    console.log("noOfAdds == ", noOfAdds);
  };
  const handleChangeAccountType = (event, newValue) => {
    setAccountType(newValue);
  };

  const clearFilter = () => {
    setAccountType("");
    setAddress("");
    setBadge("");
    setAdStatus("");
    setNoOfAdds([0, 100]);
    setLastLogin();
    userlist();
    onClose();
  };

  const handleChangeBadge = (event, newValue) => {
    setBadge(newValue);
  };

  const onClose = () => {
    setShowDialog(!ShowDialog);
  };

  // const handleChangeStatus = (event, newValue) => {
  //   setAdStatus(newValue);
  // };

  const handleSearch = (event, value) => {
    if (value) {
      let data = {
        pattern: value,
        language: i18n.language
      };
      dispatch(addressSearch(data));
    } else {
      dispatch(clearAddressSearch({ data: [] }));
    }
  };
  const handlePlaceId = (value, option) => {
    setAddress(option?.description);
    let data = {
      placeId: option?.place_id,
      language: i18n.language
    };
    dispatch(addressPlaced(data));
  };

  return (
    <Dialog
      open={ShowDialog}
      PaperProps={{
        style: {
          backgroundColor: "#323232",
          boxShadow: "none",
          width: "578px"
        }
      }}>
      <DialogTitle>
        <Typography
          sx={{
            color: "#E7E9EA",
            fontSize: "22px",
            fontFamily: "setimo",
            fontWeight: 700
          }}>
          {t("user.filter")}
        </Typography>

        <IconButton sx={{ float: "right", mt: -4.8 }} onClick={onClose}>
          <Icon
            icon="system-uicons:cross"
            color="#E7E9EA"
            width={"23.63px"}
            height={"23.63px"}
            style={{ fontWeight: 700 }}
          />
        </IconButton>
      </DialogTitle>
      <Divider light />

      <DialogContent>
        <Grid container spacing={1.5} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <FormLabel style={{ color: "#E7E9EA" }} id="demo-row-radio-buttons-group-label">
              Region
            </FormLabel>
            <Autocomplete
              freeSolo
              options={addressListData}
              value={address}
              getOptionLabel={(option) => option.description || address}
              onChange={handlePlaceId}
              onInputChange={handleSearch}
              renderOption={(props, option) => {
                const { description } = option;
                return (
                  <List
                    {...props}
                    style={{
                      backgroundColor: "#323232",
                      marginTop: "-0.5rem",
                      marginBottom: "-0.5rem"
                    }}>
                    <ListItemText
                      sx={{
                        fontSize: "16px",
                        color: "#E7E9EA",
                        fontWeight: 400,
                        lineHeight: "22px",
                        fontFamily: "Setimo"
                      }}>
                      {description}
                    </ListItemText>
                  </List>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Enter the city"
                  InputLabelProps={{
                    style: { color: "#E7E9EA" }
                  }}
                  sx={{
                    mt: 1,

                    input: {
                      color: "#E7E9EA",
                      fontFamily: "setimo"
                    },
                    "& .MuiAutocomplete-inputRoot": {
                      borderRadius: "0px"
                    },
                    "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
                      color: "#E7E9EA",
                      visibility: "visible"
                    }
                  }}
                  InputProps={{
                    ...params.InputProps
                  }}
                />
              )}
            />
            <Divider light sx={{ mt: 3 }} />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel style={{ color: "#E7E9EA" }} id="demo-row-radio-buttons-group-label">
                User
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={accountType}
                onChange={handleChangeAccountType}>
                <FormControlLabel
                  value="personal"
                  control={
                    <Radio
                      sx={{
                        color: "#E7E9EA",
                        "&.Mui-checked": {
                          color: "#E7E9EA"
                        }
                      }}
                    />
                  }
                  label={<Typography sx={{ color: "#E7E9EA" }}>Personal</Typography>}
                />
                <FormControlLabel
                  value="business"
                  control={
                    <Radio
                      sx={{
                        color: "#E7E9EA",
                        "&.Mui-checked": {
                          color: "#E7E9EA"
                        }
                      }}
                    />
                  }
                  label={<Typography sx={{ color: "#E7E9EA" }}>Bussiness</Typography>}
                />
                <FormControlLabel
                  value=""
                  control={
                    <Radio
                      sx={{
                        color: "#E7E9EA",
                        "&.Mui-checked": {
                          color: "#E7E9EA"
                        }
                      }}
                    />
                  }
                  label={<Typography sx={{ color: "#E7E9EA" }}>Both</Typography>}
                />
              </RadioGroup>
            </FormControl>
            <Divider light sx={{ mt: 3 }} />
          </Grid>

          <Grid item xs={12}>
            <FormControl>
              <FormLabel style={{ color: "#E7E9EA" }} id="demo-row-radio-buttons-group-label">
                Badge
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={handleChangeBadge}
                value={badge}>
                <FormControlLabel
                  value="verify"
                  control={
                    <Radio
                      sx={{
                        color: "#E7E9EA",
                        "&.Mui-checked": {
                          color: "#E7E9EA"
                        }
                      }}
                    />
                  }
                  label={<Typography sx={{ color: "#E7E9EA" }}>Verified</Typography>}
                />
                <FormControlLabel
                  value="nonVerify"
                  control={
                    <Radio
                      sx={{
                        color: "#E7E9EA",
                        "&.Mui-checked": {
                          color: "#E7E9EA"
                        }
                      }}
                    />
                  }
                  label={<Typography sx={{ color: "#E7E9EA" }}>Non Verified</Typography>}
                />
                <FormControlLabel
                  value=""
                  control={
                    <Radio
                      sx={{
                        color: "#E7E9EA",
                        "&.Mui-checked": {
                          color: "#E7E9EA"
                        }
                      }}
                    />
                  }
                  label={<Typography sx={{ color: "#E7E9EA" }}>Both</Typography>}
                />
              </RadioGroup>
            </FormControl>
            <Divider light sx={{ mt: 3 }} />
          </Grid>
          <Divider />

          <Grid item xs={12}>
            <FormLabel style={{ color: "#E7E9EA" }} id="demo-row-radio-buttons-group-label">
              Number Of Ads
            </FormLabel>
            <Slider
              getAriaLabel={() => "Temperature range"}
              value={noOfAdds}
              onChange={handleChange1}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={adStatus}
                onChange={handleChangeStatus}>
                <FormControlLabel
                  value="publish"
                  control={
                    <Radio
                      sx={{
                        color: "#E7E9EA",
                        "&.Mui-checked": {
                          color: "#E7E9EA"
                        }
                      }}
                    />
                  }
                  label={<Typography sx={{ color: "#E7E9EA" }}>Active Ad</Typography>}
                />
                <FormControlLabel
                  value="draft"
                  control={
                    <Radio
                      sx={{
                        color: "#E7E9EA",
                        "&.Mui-checked": {
                          color: "#E7E9EA"
                        }
                      }}
                    />
                  }
                  label={<Typography sx={{ color: "#E7E9EA" }}>Draft </Typography>}
                />
                <FormControlLabel
                  value=""
                  control={
                    <Radio
                      sx={{
                        color: "#E7E9EA",
                        "&.Mui-checked": {
                          color: "#E7E9EA"
                        }
                      }}
                    />
                  }
                  label={<Typography sx={{ color: "#E7E9EA" }}>Both </Typography>}
                />
              </RadioGroup>
            </FormControl>
            <Divider light sx={{ mt: 3 }} />
          </Grid> */}

          <Grid item xs={12}>
            <FormControl variant="outlined" color="primary" fullWidth>
              <FormLabel style={{ color: "#E7E9EA" }}>Last Login</FormLabel>
              <Select
                value={lastLogin}
                onChange={handleChange}
                sx={{ mt: 1, color: "#E7E9EA" }}
                inputProps={{
                  MenuProps: {
                    MenuListProps: {
                      sx: {
                        "& .MuiMenuItem-root.Mui-selected": {
                          backgroundColor: "#d66565",
                          color: "white"
                        },
                        "& .MuiMenuItem-root:hover": {
                          backgroundColor: "#d66565",
                          color: "white"
                        }
                      }
                    }
                  }
                }}>
                <MenuItem value={7}>7 days</MenuItem>
                <MenuItem value={15}>15 days</MenuItem>
                <MenuItem value={1}>1 month</MenuItem>
                <MenuItem value={3}>3 month</MenuItem>
              </Select>
            </FormControl>
            <Divider light sx={{ mt: 3 }} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid sx={{ p: 2 }}>
            <AdminButton onClick={onClick} title="Save" />
          </Grid>
          <Grid sx={{ p: 2 }}>
            <AdminButton onClick={clearFilter} title="Reset" />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default FilterDialog;
