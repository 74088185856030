import {
  Autocomplete,
  Avatar,
  Box,
  Grid,
  List,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import AdminButton from "components/adminButton";
import AdrenaInput from "components/adrenaInput";
import { label } from "components/style";
import { useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IMaskInput } from "react-imask";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { addressPlaced } from "store";
import { clearAddressSearch } from "store";
import { addressSearch } from "store";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import uploadImage from "../../../assets/img/uploadImage.png";
import Resizer from "react-image-file-resizer";
import { ADRENA } from "services/apiConstant";
import api from "services";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { addEditUserByAdmin } from "store";
import Loader from "components/loader/loader";
import { getUserDetail } from "store";

export default function AddUser() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const inputProfile = useRef();
  const [userData, setUserData] = useState({});
  const [userDataError, setUserDataError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { addressListData, adressData, getUserDetailData } = useSelector(
    (state) => state.userSlice
  );
  useEffect(() => {
    if (!id) {
      setUserData({});
    }
  }, []);
  useEffect(() => {
    if (id) {
      setIsLoading(true);
      var requestParams = {
        userId: id,
        language: i18n.language
      };
      dispatch(getUserDetail(requestParams, setIsLoading));
    } else {
      setUserData((userData) => ({
        ...userData,
        address: "",
        latitude: "",
        longitude: "",
        region: "",
        postalCode: "",
        country: "",
        city: ""
      }));
    }
  }, [id]);
  useEffect(() => {
    if (getUserDetailData) {
      setUserData(getUserDetailData?.data);
    }
  }, [getUserDetailData]);

  useEffect(() => {
    if (adressData) {
      setUserData((userData) => ({
        ...userData,
        address: adressData?.formatted_address,
        latitude: adressData?.geometry.location?.lat,
        longitude: adressData?.geometry.location?.lng
      }));
      adressData?.address_components?.map((item) => {
        {
          item.types.map((data) => {
            if (data === "administrative_area_level_1") {
              setUserData((userData) => ({
                ...userData,
                region: item.long_name
              }));
            } else if (data === "postal_code") {
              setUserData((userData) => ({
                ...userData,
                postalCode: item.long_name
              }));
            } else if (data === "country") {
              setUserData((userData) => ({
                ...userData,
                country: item.long_name
              }));
            } else if (data === "administrative_area_level_3") {
              setUserData((userData) => ({
                ...userData,
                city: item.long_name
              }));
            }
          });
        }
      });
    }
  }, [adressData]);
  const handleSearch = (event, value) => {
    setUserDataError((userDataError) => ({
      ...userDataError,
      addressError: ""
    }));
    if (value) {
      let data = {
        pattern: value,
        language: i18n.language
      };
      dispatch(addressSearch(data));
    } else {
      dispatch(clearAddressSearch({ data: [] }));
    }
  };
  const handlePlaceId = (value, option) => {
    setUserDataError((userDataError) => ({
      ...userDataError,
      addressError: ""
    }));
    setUserData((userData) => ({
      ...userData,
      address: option?.description
    }));
    let data = {
      placeId: option?.place_id,
      language: i18n.language
    };
    dispatch(addressPlaced(data));
  };

  // upload image

  const resizeFile = (file, width, height) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        width,
        height,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        width,
        height
      );
    });

  const onChange = (event, item) => {
    const file = event.target.files[0];
    let width = 0;
    let height = 0;
    const img = document.createElement("img");
    const objectURL = URL.createObjectURL(file);
    img.onload = async () => {
      width = img.width;
      height = img.height;
      const compressedImage = await resizeFile(file, width, height);
      const formData = new FormData();
      formData.append("file", compressedImage);
      api
        .post(`${ADRENA.uploadProfile}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((response) => {
          let filename = response?.data?.data[0]?.fileName;
          let imageResult = response.data.data[0].accessUrl;
          var imageUrl = imageResult;
          if (imageUrl) {
            setUserData((userData) => ({
              ...userData,
              profileImagePath: imageUrl,
              profileImage: filename
            }));
          }
          toast.success(response.data.message);
          let result = response.data;
          if (result.status) {
            let data = result.data;
            item.value = data.fileUrl;
          }
        })
        .catch(() => {
          ("error");
        });
    };
    img.src = objectURL;
  };
  const removeProfile = () => {
    setUserData((userData) => ({
      ...userData,
      profileImagePath: "",
      profileImage: ""
    }));
  };

  //  update add user

  const handleUsers = () => {
    const validEmail = new RegExp("^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$");

    if (!userData?.email && !userData?.phone) {
      toast.warning(t("register.emailorphone"));
      return;
    }
    if (userData?.email) {
      if (!userData?.email.includes("@")) {
        setUserDataError((userDataError) => ({
          ...userDataError,
          emailError: t("login.emailError1")
        }));
        return;
      } else if (!userData?.email.includes(".")) {
        setUserDataError((userDataError) => ({
          ...userDataError,
          emailError: t("login.emailError1")
        }));
        return;
      } else if (!validEmail.test(userData?.email)) {
        setUserDataError((userDataError) => ({
          ...userDataError,
          emailError: t("login.emailError1")
        }));
        return;
      }
    }
    if (!id && !userData?.password) {
      setUserDataError((userDataError) => ({
        ...userDataError,
        passwordError: t("login.emptyPassword")
      }));
      return;
    }

    if (!userData?.accountType) {
      setUserDataError((userDataError) => ({
        ...userDataError,
        accountTypeError: t("users.accountTypeError")
      }));
      return;
    }

    if (userData?.accountType === "personal") {
      if (!userData?.firstName) {
        setUserDataError((userDataError) => ({
          ...userDataError,
          firstNameError: t("users.firstNameError")
        }));
        return;
      }
    }

    if (userData?.accountType === "business") {
      if (!userData?.companyName) {
        setUserDataError((userDataError) => ({
          ...userDataError,
          companyNameError: t("users.companyNameError")
        }));
        return;
      }
    }
    if (!userData?.address) {
      setUserDataError((userDataError) => ({
        ...userDataError,
        addressError: t("adrenaAds.addressError")
      }));
      return;
    }
    setIsLoading(true);
    let request = {
      userId: id ? id : "",
      language: i18n?.language,
      firstName: userData?.accountType === "personal" ? userData?.firstName : "",
      lastName: userData?.accountType === "personal" ? userData?.lastName : "",
      email: userData?.email,
      password: userData?.password,
      countryCode: userData?.phone ? userData?.countryCode : "",
      phone: userData?.phone,
      dialCode: userData?.phone ? userData?.dialCode : "",
      companyName: userData?.accountType === "personal" ? "" : userData?.companyName,
      accountType: userData?.accountType,
      cpf: userData?.accountType === "personal" ? userData?.cpf : "",
      cnpj: userData?.accountType === "personal" ? "" : userData?.cnpj,
      longitude: userData?.longitude,
      latitude: userData?.latitude,
      address: userData?.address,
      region: userData?.region,
      city: userData?.city,
      country: userData?.country,
      postalCode: userData?.postalCode
    };

    dispatch(addEditUserByAdmin(request, setIsLoading, navigate));
  };

  return (
    <>
      <Grid container sx={{ mb: 5 }}>
        <Grid>
          <Typography sx={{ fontSize: 18, fontWeight: 700, color: "#212b36" }}>
            {t("users.addUser")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          container
          sx={{
            border: " 1px solid rgba(224, 224, 224, 1)",
            background: "white",
            p: 2,
            borderRadius: 1
          }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                sx={{ mb: 1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
                {t("users.userProfile")}
              </Typography>
              <Box
                sx={{
                  border: "1px solid rgba(224, 224, 224, 1)",
                  maxWidth: 200,
                  height: 150,
                  position: "relative",
                  textAlign: "center"
                }}>
                {userData?.profileImagePath && (
                  <>
                    <Avatar
                      sx={{
                        height: 18,
                        width: 18,
                        position: "absolute",
                        cursor: "pointer",
                        right: 0,
                        background: "white",
                        mt: 0.3,
                        mr: 0.3
                      }}>
                      <Icon
                        icon="material-symbols:cancel"
                        color="red"
                        onClick={() => {
                          removeProfile();
                        }}
                        style={{
                          color: "red",
                          fontSize: 20
                        }}
                      />
                    </Avatar>
                  </>
                )}
                <input
                  ref={inputProfile}
                  style={{ display: "none", cursor: "pointer" }}
                  type="file"
                  accept="image/*"
                  onChange={onChange}
                />
                <img
                  onClick={() => {
                    if (!userData?.profileImagePath) {
                      inputProfile.current && inputProfile.current.click();
                    }
                  }}
                  src={userData?.profileImagePath ? userData?.profileImagePath : uploadImage}
                  id="image"
                  alt="logo"
                  width="200px"
                  height="150px"
                />
              </Box>
            </Grid>
            {/* email */}
            <Grid item xs={6}>
              <Typography style={label}>{t("profile.email")}</Typography>
              <AdrenaInput
                value={userData?.email}
                onChange={(val) => {
                  setUserData((userData) => ({
                    ...userData,
                    email: val.target.value
                  }));
                  setUserDataError((userDataError) => ({
                    ...userDataError,
                    emailError: ""
                  }));
                }}
                formError={userDataError?.emailError}
              />
            </Grid>
            {/* phone */}
            <Grid item xs={6}>
              <Typography
                sx={{
                  color: "#212b36",
                  fontWeight: 500,
                  fontSize: 15,
                  display: "block",
                  mb: 0.8
                }}>
                {t("users.phone")}
              </Typography>
              <PhoneInput
                country={"br"}
                placeholder={"(00) 0000-0000"}
                onChange={(value, inputdata) => {
                  console.log("inputdata == ", inputdata, value);
                  setUserData((userData) => ({
                    ...userData,
                    phone: value.slice(inputdata?.dialCode?.length),
                    countryCode: inputdata?.countryCode?.toUpperCase(),
                    dialCode: inputdata?.dialCode
                  }));
                  setUserDataError((userDataError) => ({
                    ...userDataError,
                    phoneError: ""
                  }));
                }}
                value={`${userData?.dialCode}${userData?.phone}`}
                inputStyle={{
                  width: "100%",
                  border: "1px solid #E7E9EA",
                  height: "42px",
                  fontSize: "19px",
                  color: "black"
                }}
              />
            </Grid>
            {/* password */}
            {!id && (
              <Grid item xs={6}>
                <Typography style={label}>{t("users.password")}</Typography>
                <AdrenaInput
                  value={userData?.password}
                  onChange={(val) => {
                    setUserData((userData) => ({
                      ...userData,
                      password: val.target.value
                    }));
                    setUserDataError((userDataError) => ({
                      ...userDataError,
                      passwordError: ""
                    }));
                  }}
                  formError={userDataError.passwordError}
                />
              </Grid>
            )}
            {/* account type */}
            <Grid item xs={6}>
              <Typography
                sx={{
                  mb: -0.9,
                  color: "#212b36",
                  fontWeight: 500,
                  fontSize: 15,
                  display: "block"
                }}>
                {t("user.accountType")}
              </Typography>
              <Select
                fullWidth
                onChange={(val) => {
                  if (val.target.value === "personal") {
                    setUserData((userData) => ({
                      ...userData,
                      accountType: val.target.value,
                      cnpj: "",
                      companyName: ""
                    }));
                  } else {
                    setUserData((userData) => ({
                      ...userData,
                      accountType: val.target.value,
                      cpf: "",
                      firstName: "",
                      lastName: ""
                    }));
                  }

                  setUserDataError((userDataError) => ({
                    ...userDataError,
                    accountTypeError: ""
                  }));
                }}
                value={userData?.accountType ? userData?.accountType : ""}
                inputProps={{
                  MenuProps: {
                    MenuListProps: {
                      sx: {
                        backgroundColor: "white",
                        color: "black",
                        fontFamily: "setimo",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "22px",
                        "& .MuiMenuItem-root.Mui-selected": {
                          backgroundColor: "#d66565",
                          color: "white"
                        },
                        "& .MuiMenuItem-root:hover": {
                          backgroundColor: "#d66565",
                          color: "white"
                        }
                      }
                    }
                  }
                }}
                style={{
                  marginTop: "0.9rem",
                  color: "black",
                  marginBottom: "0.5rem",
                  height: "2.7rem"
                }}>
                <MenuItem value="personal">{t("users.personal")} </MenuItem>
                <MenuItem value="business">{t("users.bussiness")} </MenuItem>
              </Select>
              {userDataError?.accountTypeError && (
                <Typography sx={{ color: "red", fontSize: 15, ml: 1, mt: 0.5 }}>
                  {userDataError?.accountTypeError}
                </Typography>
              )}
            </Grid>

            {userData?.accountType &&
              (userData?.accountType === "personal" ? (
                <>
                  <Grid item xs={6}>
                    <Typography style={label}>{t("profile.firstName")}</Typography>
                    <AdrenaInput
                      value={userData?.firstName}
                      onChange={(val) => {
                        setUserData((userData) => ({
                          ...userData,
                          firstName: val.target.value,
                          companyName: ""
                        }));
                        setUserDataError((userDataError) => ({
                          ...userDataError,
                          firstNameError: ""
                        }));
                      }}
                      formError={userDataError.firstNameError}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={label}>{t("profile.lastName")}</Typography>
                    <AdrenaInput
                      value={userData?.lastName}
                      onChange={(val) => {
                        setUserData((userData) => ({
                          ...userData,
                          lastName: val.target.value,
                          companyName: ""
                        }));
                        setUserDataError((userDataError) => ({
                          ...userDataError,
                          lastNameError: ""
                        }));
                      }}
                      formError={userDataError?.lastNameError}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={6}>
                  <Typography style={label}>{t("users.companyName")}</Typography>
                  <AdrenaInput
                    value={userData?.companyName}
                    onChange={(val) => {
                      setUserData((userData) => ({
                        ...userData,
                        companyName: val.target.value,
                        firstName: "",
                        lastName: ""
                      }));
                      setUserDataError((userDataError) => ({
                        ...userDataError,
                        companyNameError: ""
                      }));
                    }}
                    formError={userDataError?.companyNameError}
                  />
                </Grid>
              ))}
            {userData?.accountType &&
              (userData?.accountType === "personal" ? (
                <Grid item xs={6}>
                  <Typography style={label}>{t("users.cpf")}</Typography>
                  <IMaskInput
                    mask="000.000.000-00"
                    style={{
                      height: "2.7rem",
                      width: "100%",
                      backgroundColor: "transparent",
                      fontSize: "16px",
                      fontWeight: 500,
                      fontFamily: "Setimo",
                      color: "black",
                      paddingLeft: "1.5rem",
                      paddingTop: "0.3rem",
                      border: "1px solid #E7E9EA ",
                      borderRadius: 10,
                      marginTop: "1rem",
                      marginBottom: "0.1rem"
                    }}
                    value={userData?.cpf}
                    onAccept={(val) => {
                      setUserData((userData) => ({
                        ...userData,
                        cpf: val,
                        cnpj: ""
                      }));
                      setUserDataError((userDataError) => ({
                        ...userDataError,
                        cpfError: "",
                        cnpjError: ""
                      }));
                    }}
                    placeholder="000.000.000-00"
                  />
                  {userDataError?.cpfError && (
                    <Typography sx={{ color: "red", fontSize: 15, ml: 1, mt: 0.5 }}>
                      {userDataError?.cpfError}
                    </Typography>
                  )}
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <Typography style={label}>{t("users.cnpj")}</Typography>
                  <IMaskInput
                    mask="00.000.000/0000-00"
                    style={{
                      height: "2.7rem",
                      width: "100%",
                      backgroundColor: "transparent",
                      fontSize: "16px",
                      fontWeight: 500,
                      fontFamily: "Setimo",
                      color: "black",
                      paddingLeft: "1.5rem",
                      paddingTop: "0.3rem",
                      border: "1px solid #E7E9EA ",
                      borderRadius: 10,
                      marginTop: "1rem",
                      marginBottom: "0.1rem"
                    }}
                    value={userData?.cnpj}
                    onAccept={(val) => {
                      setUserData((userData) => ({
                        ...userData,
                        cnpj: val,
                        cpf: ""
                      }));
                      setUserDataError((userDataError) => ({
                        ...userDataError,
                        cnpjError: "",
                        cpfError: ""
                      }));
                    }}
                    placeholder="00.000.000/0000-00"
                  />
                  {userDataError?.cnpjError && (
                    <Typography sx={{ color: "red", fontSize: 15, ml: 1, mt: 0.5 }}>
                      {userDataError?.cnpjError}
                    </Typography>
                  )}
                </Grid>
              ))}

            {/* address */}
            <Grid item xs={6}>
              <Typography style={label}>{t("user.Address")}</Typography>
              <Autocomplete
                sx={{
                  //   width: isMobile ? "100%" : 250,
                  mt: 2,
                  height: 44,
                  paddingTop: 0.1
                }}
                freeSolo
                disableClearable
                options={addressListData}
                value={userData?.address || ""}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    setUserData((userData) => ({
                      ...userData,
                      address: ""
                    }));
                  }
                }}
                getOptionLabel={(option) => option.description || userData?.address || ""}
                onChange={handlePlaceId}
                onInputChange={handleSearch}
                renderOption={(props, option) => {
                  const { description } = option;
                  return (
                    <List
                      {...props}
                      style={{
                        backgroundColor: "white",
                        marginTop: "-0.5rem",
                        marginBottom: "-0.5rem"
                      }}>
                      <ListItemText
                        sx={{
                          fontSize: "16px",
                          color: "black",
                          fontWeight: 400,
                          lineHeight: "22px",
                          fontFamily: "Setimo"
                        }}>
                        {description}
                      </ListItemText>
                    </List>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      style: { color: "black" }
                    }}
                    sx={{
                      input: {
                        color: "black",
                        fontFamily: "setimo",
                        fontSize: 16,
                        height: 10
                      },

                      "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
                        color: "black",
                        visibility: "visible"
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      type: "search"
                    }}
                  />
                )}
              />
              {userDataError?.addressError && (
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "setimo",
                    fontWeight: 400,
                    ml: 1,
                    color: "#E01F26"
                  }}>
                  {userDataError?.addressError}
                </Typography>
              )}
            </Grid>

            {/* <Grid item xs={6}>
              <Typography style={label}>{t("users.dob")}</Typography>
              <AdrenaInput
                type="date"
                value={userData?.dob}
                onKeyDown={(event) => event.preventDefault()}
                onChange={(val) => {
                  setUserData((userData) => ({
                    ...userData,
                    dob: val.target.value
                  }));
                }}
              />
            </Grid> */}
          </Grid>
          <Grid container sx={{ mt: 4 }}>
            <Grid xs={1.5}>
              <AdminButton
                onClick={handleUsers}
                title={id ? t("plans.btnUpdate") : t("plans.btnAdd")}
                sx={{ width: 150 }}
              />
            </Grid>
            <Grid xs={1.5}>
              <AdminButton
                sx={{ bgcolor: "black", "&:hover": { bgcolor: "#757575" }, width: 150 }}
                title={t("users.cancel")}
                onClick={() => {
                  navigate("/users");
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {isLoading && <Loader open={isLoading} text={t("adrena.pleaseWait")} />}
      </Grid>
    </>
  );
}
