import CryptoJS from "crypto-js";

export const localImageURL = (filePath) => {
  return URL.createObjectURL(filePath);
};

export const encrypt = (secretKey) => {
  const cipherText = CryptoJS.AES.encrypt(secretKey, "secret key 123").toString();
  let encodeText = encodeURIComponent(cipherText);
  return encodeText;
};
