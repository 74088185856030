import React, { useEffect, useRef, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Grid, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import AdminButton from "components/adminButton";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { deleteSubCategory, getSubCategoryList } from "store/slice/users";
import { getMomentFormat } from "constants/dateTime.constant";
import moment from "moment";
import TableActionButton from "components/adminTableActionButton";
import AlertDialog from "components/alertDialog";
import AdrenaInput from "components/adrenaInput";
import { useTranslation } from "react-i18next";
import Loader from "components/loader/loader";

export default function SubCategories() {
  var page = 1;
  const perPage = 10;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  var searchInputRef = useRef();
  const [alertDialogRemoveOpen, setAlertDialogRemoveOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [perPageRows, setNumberOfRows] = useState(perPage);
  const [pageCheck, setPageCheck] = useState(1);
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortColumn, setSortColumn] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [count, setCount] = useState(0);
  const reducerObj = useSelector((state) => state, shallowEqual);
  const { subCategoryListData, totalCount } = useSelector((state) => state.userSlice);

  useEffect(() => {
    if (reducerObj && count > 0) {
      setCount(count);
    }
  }, [reducerObj, count]);

  useEffect(() => {
    setIsLoading(true);
    subCategoryList();
  }, []);

  const subCategoryList = (page, numberOfRows, sortingColumnValue, sortingOrder) => {
    var data = {
      page: page ? page : 1,
      limit: numberOfRows ? numberOfRows : perPageRows,
      search: searchInputRef.current.value,
      sort: {
        sortField: sortingColumnValue !== "" ? sortingColumnValue : sortColumn,
        sortIn: sortingOrder !== "" ? sortingOrder : sortOrder
      }
    };

    dispatch(getSubCategoryList(data, setIsLoading));
  };

  const subCategoryEdit = () => {
    navigate("/editSubCategory/" + selectedRowIndex);
  };

  const subCategoryDelete = () => {
    const data = {
      deleteSubCategoryId: selectedRowIndex
    };
    dispatch(deleteSubCategory(data));
  };

  const handleClick = (event, dataIndex) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowIndex(dataIndex);
  };

  const optionMenu = () => {
    return (
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            subCategoryEdit();
          }}>
          {t("category.edit")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setAlertDialogRemoveOpen(true);
          }}>
          {t("category.delete")}
        </MenuItem>

        {/* )} */}
      </Menu>
    );
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length > 2) {
      subCategoryList(page);
    } else if (e.target.value.length === 0) {
      subCategoryList(page);
    }
  };

  const clearSearch = () => {
    setSearchValue("");
    var data = {
      page: 1,
      perPage: 10,
      search: ""
    };
    let isSearch = false;

    dispatch(getSubCategoryList(data, isSearch, setIsLoading));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = {
    count: totalCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: t("category.rowsPerPage"),
        displayRows: "of"
      },
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`
      }
    },
    onColumnSortChange: (changedColumn, direction) => {
      let sortingOrder = direction === "asc" ? 1 : -1;
      setSortOrder(sortingOrder);
      setSortColumn(changedColumn);
      subCategoryList(page, "", changedColumn, sortingOrder);
    },
    onChangePage(currentPage) {
      if (currentPage >= pageCheck) {
        if (currentPage === 0) {
          currentPage = currentPage + 1;
        }
        setPageCheck(currentPage);
        page = currentPage + 1;
        subCategoryList(page, "", "", "");
      } else {
        if (pageCheck > 0) {
          page = pageCheck - 1;
        }
        setPageCheck(page);
        subCategoryList(page, "", "", "");
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      setNumberOfRows(numberOfRows);
      subCategoryList(page, numberOfRows, "", "");
    }
  };

  const columns = [
    {
      name: "code",
      label: t("category.code"),

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography variant="body2">{value}</Typography>;
        }
      }
    },
    {
      name: i18n.language === "en" ? "titleEn" : "titlePt",
      label: t("category.name"),
      options: {
        filter: true,
        sort: false
      },
      customBodyRender: (value) => {
        return (
          <Tooltip title={value}>
            <Typography
              variant="body2"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                maxWidth: 140
              }}>
              {value}
            </Typography>
          </Tooltip>
        );
      }
    },

    {
      name: i18n.language === "en" ? "descriptionEn" : "descriptionPt",
      label: t("category.descriptionInEnglish"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                variant="body2"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  maxWidth: 140
                }}>
                {value}
              </Typography>
            </Tooltip>
          );
        }
      }
    },

    {
      name: "createdAt",
      label: t("category.createdAt"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          var localTime = moment.utc(value).toDate();
          // localTime = moment(value).format("DD/MM/YY - HH:mm") + "h";
          return <Typography variant="body2">{getMomentFormat(localTime)}</Typography>;
        }
      }
    },
    {
      name: "id",
      label: t("category.action"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (dataIndex, rowIndex) => {
          console.log("rowIndex == ", rowIndex);
          return (
            <>
              <TableActionButton
                style={{ marginLeft: 10 }}
                onClick={(event) => handleClick(event, dataIndex)}
              />
              {optionMenu()}
            </>
          );
        }
      }
    }
  ];

  return (
    <Grid container>
      <Grid
        container
        sx={{
          border: " 1px solid rgba(224, 224, 224, 1)",
          p: 2,
          backgroundColor: "white"
        }}>
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Grid sx={{ mt: 1 }}>
            <Typography variant="body1" sx={{ fontSize: 18 }} color="secondary.contrastText">
              {t("subCategory.title")}
            </Typography>
          </Grid>
          <Grid>
            <AdminButton
              sx={{ mt: "3%" }}
              title={t("category.addSubCategory")}
              onClick={() => {
                navigate("/addSubCategory");
              }}
            />
          </Grid>
        </Grid>
        <Grid xs={3}>
          <AdrenaInput
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                subCategoryList(page);
              }
            }}
            inputRef={searchInputRef}
            title={t("subCategory.searchSubCagtegory")}
            type="email"
            endIcon={
              searchValue && (
                <Icon
                  icon="material-symbols:cancel"
                  style={{ cursor: "pointer" }}
                  color="red"
                  onClick={() => clearSearch()}
                />
              )
            }
            onChange={handleSearchChange}
            value={searchValue}
          />
        </Grid>
      </Grid>
      {isLoading ? (
        <>
          <Grid
            container
            sx={{
              border: "1px solid rgba(224, 224, 224, 1)",
              mt: 2,
              height: 300,
              backgroundColor: "white"
            }}>
            <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}></Grid>
          </Grid>
        </>
      ) : (
        <>
          {subCategoryListData && subCategoryListData.length > 0 ? (
            <Grid container>
              <Grid xs={12}>
                <MUIDataTable data={subCategoryListData} columns={columns} options={options} />
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid
                container
                sx={{
                  border: "1px solid rgba(224, 224, 224, 1)",
                  mt: 2,
                  height: 300,
                  backgroundColor: "white"
                }}>
                <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
                  <Typography sx={{ fontSize: 24, fontWeight: 500, mt: "7.5rem" }}>
                    {t("category.noRecordFound")}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}

      {alertDialogRemoveOpen && (
        <AlertDialog
          open={alertDialogRemoveOpen}
          title="Warning"
          text={t("subCategory.deleteSubCategory")}
          handleClose={setAlertDialogRemoveOpen}
          handleAgree={() => {
            subCategoryDelete();
          }}
        />
      )}
      {isLoading && <Loader open={isLoading} text={t("adrena.pleaseWait")} />}
    </Grid>
  );
}
