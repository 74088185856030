import { TextField, Typography } from "@mui/material";
import "./index.css";
import InputAdornment from "@mui/material/InputAdornment";

export default function AdrenaCardInput(props) {
  const {
    title,
    type,
    formError,
    value,
    onChange,
    endIcon,
    PasswordShowClick,
    label,
    labelStyle,
    placeholder,
    startIcon,
    multiline,
    style,
    labelIcon,
    minRows,
    maxLength,
    maxRows,
    onKeyUp,
    onClick,
    disabled,
    name,
    customInput
  } = props;

  return (
    <>
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: 700,
          fontFamily: "Setimo",
          lineHeight: "16px",
          color: "white",
          mb: -1.5,
          mt: 1,
          // ml: -1.3,
          display: "flex"
        }}
        style={labelStyle}>
        {label} &nbsp;&nbsp;<span style={{ marginTop: "-2px" }}>{labelIcon}</span>
      </Typography>
      <TextField
        onKeyUp={onKeyUp}
        controls={false}
        disabled={disabled}
        margin="normal"
        variant="outlined"
        multiline={multiline}
        minRows={minRows}
        maxRows={maxRows}
        required
        fullWidth
        style={style}
        type={type}
        onClick={onClick}
        sx={{
          backgroundColor: "transparent",
          border: formError ? "1px solid red" : ""
        }}
        onChange={(e) => {
          onChange(e);
        }}
        value={value}
        placeholder={placeholder}
        id={type}
        label={title}
        name={name}
        autoComplete={type}
        inputProps={{
          format: "##-##-##",
          maxLength: maxLength,
          style: { color: "white" }
        }}
        InputProps={{
          inputComponent: customInput,
          style: {
            borderRadius: 0
          },
          startAdornment: <InputAdornment position="start">{startIcon}</InputAdornment>,
          endAdornment: (
            <InputAdornment onClick={PasswordShowClick} position="end">
              {endIcon}
            </InputAdornment>
          )
        }}
      />
      <Typography
        sx={{ fontSize: "14px", fontFamily: "setimo", fontWeight: 400, ml: 1, color: "#E01F26" }}>
        {formError ? formError : ""}
      </Typography>
    </>
  );
}
