/** @format */

import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import AdminButton from "components/adminButton";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Slide,
  Grid,
  useMediaQuery,
  useTheme,
  Box,
  Stack,
  Chip,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { boldFont, formFont, paymentTypeFont } from "components/style";
import { Icon } from "@iconify/react";
import AddCreditCardModal from "./addCreaditCardModel";
import CreditCardList from "./creditCardList";
import { useDispatch, useSelector } from "react-redux";
import { getCreditCardList } from "store";
import { getAdminProfileDetail } from "store";
import { toast } from "react-toastify";
import Loader from "components/loader/loader";
import { addCreditCard } from "store";
import { useNavigate } from "react-router-dom";
import { addProduct } from "store";
import { makePayment } from "store";
import AdrenaInput from "components/adrenaInput";
import AdrenaButton from "components/button";
import api from "services";
import { ADRENA } from "services/apiConstant";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdPaymentDialog = (props) => {
  const { setShowDialog, showDialog, adsData, planPrice, handlePaymentCalculation } = props;
  const { productDetails, paymentCalculationData } = useSelector((state) => state.productSlice);
  useEffect(() => {
    adminProfileDetail();
  }, []);
  const adminProfileDetail = () => {
    dispatch(getAdminProfileDetail());
  };
  // const { getAdminProfileDetailData } = useSelector((state) => state.userSlice);
  const dispatch = useDispatch();
  var theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { creditCardList } = useSelector((state) => state.productSlice);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate("");
  const [cardData, setCardData] = useState({});
  const [cardError, setCardError] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [inputs, setInputs] = useState({
    card: "",
    cvv: "",
    cardFirstName: "",
    cardLastName: "",
    expiration: ""
  });
  useEffect(() => {
    dispatch(getCreditCardList(i18n.language));
  }, []);
  // when final amount is more than 0
  const handleMakePayment = (type, id, token, isToken, displayNumber) => {
    let data = {
      userPlanId: productDetails?.userPlan?._id,
      paymentMode: type,
      planPrice: planPrice,
      userServiceId: "",
      servicePrice: 0,
      couponCode: paymentCalculationData?.discountAmount > 0 ? cardData?.couponCode : "",
      token: token,
      paymentMethodId: id,
      isToken: isToken,
      totalAmount: paymentCalculationData?.totalAmount,
      discountAmount: paymentCalculationData?.discountAmount,
      finalAmount: paymentCalculationData?.finalAmount,
      language: i18n?.language,
      productId: productDetails?.id,
      isCancelledPlanExist: "",
      displayNumber: displayNumber,
      changePlanTo: ""
    };
    dispatch(
      makePayment(
        data,
        adsData,
        setIsloading,
        addProduct,
        navigate,
        cardData?.paymentType,
        productDetails
      )
    );
  };
  const handleSubmit = () => {
    if (cardData?.paymentType === "pix") {
      setIsloading(true);
      handleMakePayment("pix", "", "", false, "");
    } else if (cardData?.paymentType === "boleto") {
      setIsloading(true);
      handleMakePayment("boleto", "", "", false, "");
    } else if (cardData?.paymentType === "credit_card") {
      if (cardData?.creditCardId) {
        setIsloading(true);
        handleMakePayment(
          "credit_card",
          cardData?.creditCardId,
          "",
          false,
          cardData?.displayNumber
        );
        // }
      } else {
        setIsloading(true);
        var data = {
          number: inputs.card,
          verification_value: inputs.cvv,
          first_name: inputs.cardFirstName,
          last_name: inputs.cardLastName,
          month: inputs.expiration?.slice(0, 2),
          year: inputs.expiration?.slice(2)
        };
        try {
          window.Iugu.createPaymentToken(data, function (response) {
            if (response.errors) {
              setIsloading(false);
              toast.error("errors", response);
            } else {
              handleMakePayment(
                "credit_card",
                "",
                response?.id,
                true,
                response?.extra_info.display_number
              );
              if (cardData?.isCardSaved) {
                let data = {
                  paymentMethod: "credit_card",
                  paymentToken: response?.id,
                  language: i18n.language,
                  displayNumber: response?.extra_info.display_number
                };
                dispatch(addCreditCard(data));
              }
            }
          });
        } catch (e) {
          return console.error(e.message);
        }
      }
    }
  };
  const handleCheckCoupon = () => {
    let requestParams = {
      couponCode: cardData?.couponCode,
      language: i18n?.language
    };
    try {
      api.post(ADRENA.checkCouponCode, requestParams).then((response) => {
        let result = response.data;
        if (result?.status) {
          handlePaymentCalculation(productDetails, cardData?.couponCode);
        } else {
          setCardData((cardData) => ({
            ...cardData,
            couponCodeError: "Invalid Coupon"
          }));
        }
      });
    } catch (e) {
      return console.error(e.message);
    }
  };
  // when final amount is 0
  const handlePublish = () => {
    setIsloading(true);
    let data = {
      userPlanId:
        productDetails?.upcomingUserPlanData != null
          ? productDetails?.upcomingUserPlanData?._id
          : productDetails?.userPlan?._id,
      paymentMode: "",
      planPrice: planPrice,
      userServiceId: "",
      servicePrice: 0,
      couponCode: paymentCalculationData?.discountAmount > 0 ? cardData?.couponCode : "",
      token: "",
      paymentMethodId: "",
      isToken: "",
      totalAmount: paymentCalculationData?.totalAmount,
      discountAmount: paymentCalculationData?.discountAmount,
      finalAmount: paymentCalculationData?.finalAmount,
      language: i18n?.language,
      productId: productDetails?.id,
      isCancelledPlanExist: "",
      displayNumber: "",
      changePlanTo:
        productDetails?.upcomingUserPlanData != null
          ? productDetails?.upcomingUserPlanData?.upDownType
          : productDetails?.userPlan?.upDownType
    };
    dispatch(
      makePayment(data, adsData, setIsloading, addProduct, navigate, "credit_card", productDetails)
    );
  };
  return (
    <>
      <Dialog
        // maxWidth="md"
        fullWidth
        maxWidth="sm"
        open={showDialog}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: 0
          }
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowDialog(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontFamily: "setimo", fontWeight: 700, fontSize: 20 }}>
            {t("adrenaAds.payment")}
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            backgroundColor: "white",
            paddingTop: 0
          }}>
          <Box
            sx={{
              backgroundColor: "black",
              display: "flex",
              justifyContent: "space-between",
              px: 2,
              py: 1
            }}>
            <Stack>
              <Typography style={formFont} sx={{ color: "white" }}>
                {t("payment.plan")}
              </Typography>
              <Chip
                style={formFont}
                sx={{
                  borderRadius: "unset",
                  backgroundColor: "white",
                  color: "black",
                  height: 19,
                  mt: 0.5
                }}
                label={adsData?.planName}
              />
            </Stack>
            <Stack direction="row">
              {paymentCalculationData?.discountAmount > 0 && (
                <>
                  <Typography
                    sx={{
                      mt: 1,
                      fontSize: "16px",
                      lineHeight: "28px",
                      fontWeight: 700,
                      fontFamily: "setimo",
                      textDecoration: "line-through",
                      textDecorationThickness: 3,
                      color: "white"
                    }}>
                    R$ {paymentCalculationData?.totalAmount}
                  </Typography>
                  &nbsp;&nbsp;
                </>
              )}
              <Typography style={boldFont} sx={{ color: "white", mt: 1.4 }}>
                R$ {paymentCalculationData?.finalAmount}
              </Typography>
            </Stack>
          </Box>
          {paymentCalculationData?.finalAmount > 0 && (
            <>
              <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
                <Typography
                  sx={{
                    fontFamily: "setimoBold",
                    lineHeight: "28px",
                    fontWeight: 700
                  }}>
                  {t("payement.discountCoupon")}
                </Typography>
                {paymentCalculationData?.discountAmount > 0 && (
                  <Typography
                    onClick={() => {
                      handlePaymentCalculation(productDetails, "");
                      setCardData((cardData) => ({
                        ...cardData,
                        couponCode: ""
                      }));
                    }}
                    sx={{
                      fontFamily: "setimoBold",
                      lineHeight: "28px",
                      fontWeight: 700,
                      cursor: "pointer",
                      textDecoration: "underline"
                    }}>
                    {t("payement.removeCoupon")}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: "flex", mt: -2 }}>
                <Box sx={{ width: "100%" }}>
                  <AdrenaInput
                    formError={cardData?.couponCodeError}
                    type="text"
                    placeholder={t("payment.coupon")}
                    value={cardData?.couponCode}
                    onChange={(val) =>
                      setCardData((cardData) => ({
                        ...cardData,
                        couponCode: val?.target?.value?.toUpperCase(),
                        couponCodeError: ""
                      }))
                    }
                  />
                </Box>

                <AdrenaButton
                  text={t("payement.apply")}
                  style={{ marginTop: "1.1rem" }}
                  disabled={cardData?.couponCode ? false : true}
                  onClick={handleCheckCoupon}
                />
              </Box>
              <Typography style={boldFont} sx={{ mt: 3 }}>
                {t("adrenaAds.paymentMethod")}
              </Typography>
              <FormControl sx={{ mt: 2 }}>
                <RadioGroup
                  onChange={(event) =>
                    setCardData((cardData) => ({
                      ...cardData,
                      paymentType: event.target.value
                    }))
                  }
                  value={cardData?.paymentType}
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group">
                  <FormControlLabel
                    value="pix"
                    control={
                      <Radio
                        icon={<Icon icon="clarity:circle-line" color="black" height="22px" />}
                        checkedIcon={<Icon icon="carbon:circle-filled" color="red" height="22px" />}
                      />
                    }
                    label={
                      <Typography style={paymentTypeFont} sx={{ ml: 0.5, pt: 0.5, color: "black" }}>
                        {t("adrenaAds.pix")}
                      </Typography>
                    }
                  />
                  {cardData?.paymentType === "pix" && (
                    <Box
                      sx={{
                        // width: '597px',
                        height: "auto",
                        border: "1px solid #E7E9EA",
                        p: 2,
                        mb: 1,
                        mt: 0.5
                      }}>
                      <Typography style={paymentTypeFont}>
                        {t("adrenaAds.pixCodeForPayment")}
                      </Typography>
                      <Typography style={paymentTypeFont} sx={{ pt: 2 }}>
                        {t("adrenaAds.ourSystemAutomatically")}
                      </Typography>
                    </Box>
                  )}
                  <FormControlLabel
                    value="credit_card"
                    control={
                      <Radio
                        icon={<Icon icon="clarity:circle-line" color="black" height="22px" />}
                        checkedIcon={<Icon icon="carbon:circle-filled" color="red" height="22px" />}
                      />
                    }
                    label={
                      <Typography style={paymentTypeFont} sx={{ ml: 0.5, pt: 0.5 }}>
                        {t("adrenaAds.creditCard")}
                      </Typography>
                    }
                  />
                  {cardData?.paymentType === "credit_card" &&
                    (creditCardList?.length >= 1 ? (
                      <CreditCardList
                        data={creditCardList}
                        setInputs={setInputs}
                        cardData={cardData}
                        setCardData={setCardData}
                        inputs={inputs}
                        setCardError={setCardError}
                        cardError={cardError}
                      />
                    ) : (
                      <AddCreditCardModal
                        setInputs={setInputs}
                        setCardData={setCardData}
                        inputs={inputs}
                        setCardError={setCardError}
                        cardError={cardError}
                      />
                    ))}
                  <FormControlLabel
                    value="boleto"
                    control={
                      <Radio
                        icon={<Icon icon="clarity:circle-line" color="black" height="22px" />}
                        checkedIcon={<Icon icon="carbon:circle-filled" color="red" height="22px" />}
                      />
                    }
                    label={
                      <Typography style={paymentTypeFont} sx={{ ml: 0.5, pt: 0.5 }}>
                        {t("adrenaAds.boleto")}
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}
        </DialogContent>
        <Grid container sx={{ px: 2.8, pb: 2, mt: 3 }} gap={isMobile ? 1 : 2}>
          <Grid xs={isMobile ? 12 : 5.5}>
            <AdminButton
              fullWidth
              onClick={() => setShowDialog(false)}
              color="primary"
              variant="outlined"
              title={t("button.cancel")}
            />
          </Grid>
          <Grid xs={isMobile ? 12 : 5.5}>
            <AdminButton
              fullWidth
              title={t("adrenaAds.payNow")}
              onClick={paymentCalculationData?.finalAmount === 0 ? handlePublish : handleSubmit}
              disabled={
                paymentCalculationData?.finalAmount === 0
                  ? false
                  : cardData?.paymentType
                  ? cardData?.paymentType === "credit_card"
                    ? creditCardList?.length >= 1 && cardData?.creditCardId
                      ? false
                      : inputs.card === "" ||
                        inputs.cvv === "" ||
                        inputs.cardFirstName === "" ||
                        inputs.cardLastName === "" ||
                        inputs.expiration === ""
                      ? true
                      : cardError.firstNameError !== "" ||
                        cardError.lastNameError !== "" ||
                        cardError.expirationError !== "" ||
                        cardError.cvvError !== "" ||
                        cardError.cardNumberError !== ""
                      ? true
                      : false
                    : false
                  : true
              }
              color="primary"
              sx={{ height: "2.5rem" }}
            />
          </Grid>
        </Grid>
      </Dialog>
      {isLoading && <Loader open={isLoading} text={"Please Wait"} />}
    </>
  );
};

AdPaymentDialog.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.any,
  title: PropTypes.string,
  text: PropTypes.string,
  handleAgree: PropTypes.any
};

export default memo(AdPaymentDialog);
