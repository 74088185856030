import { useEffect, useRef } from "react";
import { Box, Grid } from "@mui/material";
import uploadImage from "../../../assets/img/uploadImage.png";
import AdminButton from "components/adminButton";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import AdrenaInput from "components/adrenaInput";
import { useDispatch, useSelector } from "react-redux";
import { addCategory, updateCategory, getCategoryDetail } from "store/slice/users";
import { ADRENA } from "services/apiConstant";
import api from "services";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import i18n from "i18n";
import Loader from "components/loader/loader";
import Resizer from "react-image-file-resizer";

export default function AddCategory() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const inputProfile = useRef(null);
  var dispatch = useDispatch();
  const { id } = useParams();
  const [formError, setFormError] = useState({});
  const [formData, setFormData] = useState({});
  const [src, setSrc] = useState("");
  const [profilename, setProfilename] = useState("");
  const [filetype, setFiletype] = useState("");
  const [loading, setLoading] = useState(false);
  const { getCategoryDetailData } = useSelector((state) => state.userSlice);

  useEffect(() => {
    if (id) {
      categoryDetail();
    }
  }, []);

  useEffect(() => {
    if (id) {
      if (getCategoryDetailData) {
        if (getCategoryDetailData?.data?.titleEn) {
          setFormData((formData) => ({
            ...formData,
            categoryName: getCategoryDetailData?.data?.titleEn
          }));
        }
        if (getCategoryDetailData?.data?.titleEs) {
          setFormData((formData) => ({
            ...formData,
            categoryNameSpanish: getCategoryDetailData?.data?.titleEs
          }));
        }
        if (getCategoryDetailData?.data?.titlePt) {
          setFormData((formData) => ({
            ...formData,
            categoryNamePortuguese: getCategoryDetailData?.data?.titlePt
          }));
        }
        if (getCategoryDetailData?.data?.description) {
          setFormData((formData) => ({
            ...formData,
            description: getCategoryDetailData?.data?.description
          }));
        }
        if (getCategoryDetailData?.data?.descriptionEn) {
          setFormData((formData) => ({
            ...formData,
            description: getCategoryDetailData?.data?.descriptionEn
          }));
        }
        if (getCategoryDetailData?.data?.descriptionEs) {
          setFormData((formData) => ({
            ...formData,
            descriptionSpanish: getCategoryDetailData?.data?.descriptionEs
          }));
        }
        if (getCategoryDetailData?.data?.descriptionPt) {
          setFormData((formData) => ({
            ...formData,
            descriptionPortuguese: getCategoryDetailData?.data?.descriptionPt
          }));
        }
        if (getCategoryDetailData?.data?.descriptionEn) {
          setFormData((formData) => ({
            ...formData,
            descriptionEn: getCategoryDetailData?.data?.descriptionEn
          }));
        }
        if (getCategoryDetailData?.data.imageAccessUrl) {
          setSrc(getCategoryDetailData?.data.imageAccessUrl);
        }
        if (getCategoryDetailData?.data.status) {
          setFormData((formData) => ({
            ...formData,
            status: getCategoryDetailData?.data?.status
          }));
        }
      }
    }
  }, [getCategoryDetailData]);

  const categoryDetail = () => {
    dispatch(getCategoryDetail(id));
  };
  const resizeFile = (file, width, height) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        width,
        height,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        width,
        height
      );
    });

  const onChange = (event, item) => {
    const file = event.target.files[0];
    let width = 0;
    let height = 0;
    const img = document.createElement("img");
    const objectURL = URL.createObjectURL(file);
    img.onload = async () => {
      width = img.width;
      height = img.height;
      const compressedImage = await resizeFile(file, width, height);
      console.log("compressedImage", compressedImage);
      const formData = new FormData();
      formData.append("file", compressedImage);
      api
        .post(`${ADRENA.uploadProfile}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then((response) => {
          console.log(response);
          let filename = response?.data?.data[0]?.fileName;
          setProfilename(filename);
          let imageResult = response.data.data[0].accessUrl;
          var imageUrl = imageResult;
          console.log(imageUrl);
          if (imageUrl) {
            setSrc(imageUrl);
          }
          toast.success(response.data.message);
          let result = response.data;
          if (result.status) {
            let data = result.data;
            item.value = data.fileUrl;
          }
        })
        .catch(() => {
          ("error");
        });
    };
    img.src = objectURL;
  };
  const removeProfile = () => {
    setFiletype("category");

    if (filetype === "category") {
      removeFileUpload();
    }
  };

  const removeFileUpload = () => {
    setSrc("");
  };

  const handleSubmit = () => {
    if (!formData.categoryName) {
      setFormError((formError) => ({
        ...formError,
        categoryNameError: t("category.titleNameError")
      }));
      return;
    }
    if (!formData.categoryNameSpanish) {
      setFormError((formError) => ({
        ...formError,
        categoryNameSpanishError: t("category.categoryNameSpanishError")
      }));
      return;
    }
    if (!formData.categoryNamePortuguese) {
      setFormError((formError) => ({
        ...formError,
        categoryNamePortugueseError: t("category.categoryNamePortugueseError")
      }));
      return;
    }
    if (!formData.description) {
      setFormError((formError) => ({
        ...formError,
        descriptionError: t("category.descriptionError")
      }));
      return;
    }
    if (!formData.descriptionSpanish) {
      setFormError((formError) => ({
        ...formError,
        descriptionSpanishError: t("subCategory.descriptionSpanishError")
      }));
      return;
    }
    if (!formData.descriptionPortuguese) {
      setFormError((formError) => ({
        ...formError,
        descriptionPortugueseError: t("subCategory.descriptionPortugueseError")
      }));
      return;
    }
    if (!src) {
      toast.error(t("category.uploadImage"));
      return;
    }

    if (src === "") {
      let requestParams = {
        type: filetype,
        fileName: getCategoryDetailData?.data?.image
          ? getCategoryDetailData?.data?.image
          : profilename
      };
      api
        .post(`${ADRENA.removeprofile}`, requestParams, {
          headers: {
            "Content-Type": " application/json"
          }
        })
        .then((response) => {
          toast.success(response.data.message);
          let result = response.data;
          if (result.status) {
            if (filetype === "category") {
              setSrc("");
            }
          }
        })
        .catch(() => {
          ("error");
        });
    }

    if (id) {
      var data = {
        image: profilename ? profilename : getCategoryDetailData?.data.image,
        titleEn: formData.categoryName,
        titleEs: formData.categoryNameSpanish,
        titlePt: formData.categoryNamePortuguese,
        description: formData.description,
        descriptionEn: formData.description,
        descriptionEs: formData.descriptionSpanish,
        descriptionPt: formData.descriptionPortuguese,
        language: i18n.language,
        uid: id,
        status: formData?.status
      };
      dispatch(updateCategory(data, navigate, setLoading));
    } else {
      // eslint-disable-next-line no-redeclare
      var data = {
        image: profilename,
        titleEn: formData.categoryName,
        titleEs: formData.categoryNameSpanish,
        titlePt: formData.categoryNamePortuguese,
        descriptionEn: formData.description,
        descriptionEs: formData.descriptionSpanish,
        descriptionPt: formData.descriptionPortuguese,
        language: i18n.language,
        status: "soon"
      };
      dispatch(addCategory(data, navigate, setLoading));
    }
  };
  console.log("formData", formData);
  return (
    <>
      <Grid container sx={{ mb: 5 }}>
        <Grid>
          <Typography sx={{ fontSize: 18, fontWeight: 700, color: "#212b36" }}>
            {t("category.addCategory")}
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: "#555" }}>
            {t("category.createNewCategory")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          container
          sx={{
            border: " 1px solid rgba(224, 224, 224, 1)",
            background: "white",
            p: 2,
            borderRadius: 1
          }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                sx={{ mb: -1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
                {t("category.nameEnglish")}
              </Typography>
              <AdrenaInput
                value={formData.categoryName}
                onChange={(val) => {
                  setFormData((formData) => ({
                    ...formData,
                    categoryName: val.target.value
                  }));
                  setFormError((formError) => ({
                    ...formError,
                    categoryNameError: ""
                  }));
                }}
                formError={formError.categoryNameError}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ mb: -1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
                {t("category.nameSpanish")}
              </Typography>
              <AdrenaInput
                value={formData.categoryNameSpanish}
                onChange={(val) => {
                  setFormData((formData) => ({
                    ...formData,
                    categoryNameSpanish: val.target.value
                  }));
                  setFormError((formError) => ({
                    ...formError,
                    categoryNameSpanishError: ""
                  }));
                }}
                formError={formError.categoryNameSpanishError}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ mb: -1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
                {t("category.namePortuguese")}
              </Typography>
              <AdrenaInput
                value={formData.categoryNamePortuguese}
                onChange={(val) => {
                  setFormData((formData) => ({
                    ...formData,
                    categoryNamePortuguese: val.target.value
                  }));
                  setFormError((formError) => ({
                    ...formError,
                    categoryNamePortugueseError: ""
                  }));
                }}
                formError={formError.categoryNamePortugueseError}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ mb: -1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
                {t("category.descriptionEnglish")}
              </Typography>
              <AdrenaInput
                multiline={true}
                rows={3}
                value={formData.descriptionEn}
                onChange={(val) => {
                  setFormData((formData) => ({
                    ...formData,
                    description: val.target.value
                  }));
                  setFormError((formError) => ({
                    ...formError,
                    descriptionError: ""
                  }));
                }}
                formError={formError.descriptionError}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ mb: -1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
                {t("category.descriptionSpanish")}
              </Typography>
              <AdrenaInput
                multiline={true}
                rows={3}
                value={formData.descriptionSpanish}
                onChange={(val) => {
                  setFormData((formData) => ({
                    ...formData,
                    descriptionSpanish: val.target.value
                  }));
                  setFormError((formError) => ({
                    ...formError,
                    descriptionSpanishError: ""
                  }));
                }}
                formError={formError.descriptionSpanishError}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ mb: -1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
                {t("category.descriptionPortuguese")}
              </Typography>
              <AdrenaInput
                multiline={true}
                rows={3}
                value={formData.descriptionPortuguese}
                onChange={(val) => {
                  setFormData((formData) => ({
                    ...formData,
                    descriptionPortuguese: val.target.value
                  }));
                  setFormError((formError) => ({
                    ...formError,
                    descriptionPortugueseError: ""
                  }));
                }}
                formError={formError.descriptionPortugueseError}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                sx={{ mb: 1, color: "#212b36", fontWeight: 500, fontSize: 14, display: "block" }}>
                {t("subCategory.subCategoryImage")}
              </Typography>
              <Box
                sx={{
                  border: "1px solid rgba(224, 224, 224, 1)",
                  maxWidth: 200,
                  height: 150,
                  position: "relative",
                  textAlign: "center"
                }}>
                {src && (
                  <>
                    <Icon
                      icon="material-symbols:cancel"
                      color="red"
                      onClick={() => {
                        removeProfile();
                      }}
                      style={{
                        position: "absolute",
                        cursor: "pointer",
                        right: 0,
                        color: "red",
                        fontSize: 20,
                        marginTop: -10,
                        marginRight: -10
                      }}
                    />
                  </>
                )}
                <input
                  ref={inputProfile}
                  style={{ display: "none", cursor: "pointer" }}
                  type="file"
                  accept="image/*"
                  onChange={onChange}
                  multiple={true}
                />
                <img
                  onClick={() => inputProfile.current && inputProfile.current.click()}
                  src={src ? src : uploadImage}
                  id="image"
                  alt="logo"
                  width="200px"
                  height="150px"
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 4 }}>
            <Grid xs={2}>
              <AdminButton title={"Save"} onClick={handleSubmit} sx={{ width: 150 }} />
            </Grid>
            <Grid xs={2}>
              <AdminButton
                sx={{ bgcolor: "black", "&:hover": { bgcolor: "#757575" }, width: 150 }}
                title={"Cancel"}
                onClick={() => {
                  navigate("/categories");
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {loading && <Loader open={loading} text={"Please Wait"} />}
    </>
  );
}
