import { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "components/loader/loader";
import MUIDataTable from "mui-datatables";
import { Tooltip } from "@mui/material";
import moment from "moment/moment";
import { getMomentFormat } from "constants/dateTime.constant";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getReportsAdsList } from "store";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
export default function ReportAD() {
  var page = 1;
  const perPage = 10;
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { totalReportsAdCount, reportsAdsList } = useSelector((state) => state.productSlice);
  const [perPageRows, setNumberOfRows] = useState(perPage);
  const [pageCheck, setPageCheck] = useState(1);
  useEffect(() => {
    fetchReortsAdsList();
  }, []);

  const fetchReortsAdsList = (page, numberOfRows) => {
    let data = {
      page: page ? page : 1,
      limit: numberOfRows ? numberOfRows : 10,
      language: i18n?.language
    };
    dispatch(getReportsAdsList(data, setIsLoading));
  };
  const options = {
    count: totalReportsAdCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPage: perPageRows,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: t("category.rowsPerPage"),
        displayRows: "of"
      },
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`
      }
    },
    onChangePage(currentPage) {
      if (currentPage >= pageCheck) {
        if (currentPage === 0) {
          currentPage = currentPage + 1;
        }
        setPageCheck(currentPage);
        page = currentPage + 1;
        fetchReortsAdsList(page, "", "", "");
      } else {
        if (pageCheck > 0) {
          page = pageCheck - 1;
        }
        setPageCheck(page);
        fetchReortsAdsList(page, "", "", "");
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      setNumberOfRows(numberOfRows);
      fetchReortsAdsList(page, numberOfRows, "", "");
    }
  };
  const columns = [
    {
      name: "productName",
      label: t("reportAd.productName"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  maxWidth: 140,
                  fontSize: 14
                }}>
                {value?.charAt(0).toUpperCase() + value?.slice(1)}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "userName",
      label: t("reportAd.userName"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  maxWidth: 140,
                  fontSize: 14
                }}>
                {value?.charAt(0).toUpperCase() + value?.slice(1)}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "userEmail",
      label: t("reportAd.userEmail"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  maxWidth: 140,
                  fontSize: 14
                }}>
                {value ? value : "-"}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "reportMessage",
      label: t("reportAd.reportMessage"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  maxWidth: 140,
                  fontSize: 14
                }}>
                {value ? value : "-"}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "updatedAt",
      label: t("reportAd.reportedTime"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          var localTime = moment.utc(value).toDate();
          return <div style={{ alignItems: "center" }}>{getMomentFormat(localTime)}</div>;
        }
      }
    },
    {
      name: "productId",
      label: t("reportAd.productLink"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Link
              style={{ marginLeft: "1rem" }}
              to={`${process.env.REACT_APP_WEB_URL}/buy/productDetail/${value}`}
              target="_blank">
              <Icon icon="ph:link-bold" color="red" height="25" width="25" />
            </Link>
          );
        }
      }
    }
  ];

  return (
    <>
      <Grid container>
        <Grid
          container
          sx={{
            border: " 1px solid rgba(224, 224, 224, 1)",
            p: 2,
            backgroundColor: "white"
          }}>
          <Grid container sx={{ justifyContent: "space-between" }}>
            <Grid sx={{ mt: 1 }}>
              <Typography variant="body1" sx={{ fontSize: 18 }} color="secondary.contrastText">
                {t("reportAd.title")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {isLoading ? (
          <Grid
            container
            sx={{
              border: "1px solid rgba(224, 224, 224, 1)",
              mt: 2,
              height: 300,
              backgroundColor: "white"
            }}>
            <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}></Grid>
          </Grid>
        ) : (
          <>
            {reportsAdsList && reportsAdsList.length > 0 ? (
              <Grid container>
                <Grid xs={12}>
                  <MUIDataTable
                    // title={"Events List"}
                    data={reportsAdsList}
                    columns={columns}
                    options={options}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid
                  container
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    mt: 2,
                    height: 300,
                    backgroundColor: "white"
                  }}>
                  <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
                    <Typography sx={{ fontSize: 24, fontWeight: 500, mt: "7.5rem" }}>
                      {t("category.noRecordFound")}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}

        {isLoading && <Loader open={isLoading} text={"Please Wait"} />}
      </Grid>
    </>
  );
}
