import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import userSlice from "./slice/users";
import authSlice from "./slice/auth";
import productSlice from "./slice/product";
import couponSlice from "./slice/coupon";

export * from "./slice/auth";
export * from "./slice/users";
export * from "./slice/product";
export * from "./slice/coupon";

const rootReducer = combineReducers({
  authSlice,
  userSlice,
  productSlice,
  couponSlice
});

export const store = configureStore({
  reducer: rootReducer
});
