import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  MuiTableCell: {
    head: {
      backgroundColor: "red !important"
    }
  }
}));
export default useStyles;

export const MuiValue = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  maxWidth: 140,
  fontSize: 14
};
