import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import AdminButton from "components/adminButton";
import React from "react";
import { useTranslation } from "react-i18next";

export default function RefundStatus(props) {
  const { open, handleClose, handleAgree, setRefundStatusData, refundStatusData } = props;
  const { t } = useTranslation();
  //   const Transition = React.forwardRef(function Transition(props, ref) {
  //     return <Slide direction="up" ref={ref} {...props} />;
  //   });
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      keepMounted
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle style={{ paddingLeft: 30, paddingTop: 25, fontFamily: "setimo" }}>
        {t("refund.refundStatus")}
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: "white",
          paddingTop: 0
        }}>
        <Typography variant="body1">{t("refund.selectRefundStatus")}</Typography>
        <FormControl sx={{ mt: 0.5 }}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={refundStatusData?.refundStatus}
            onChange={(event) =>
              setRefundStatusData((refundStatusData) => ({
                ...refundStatusData,
                refundStatus: event.target.value
              }))
            }
            name="radio-buttons-group">
            <FormControlLabel
              value="requested"
              control={
                <Radio checked={refundStatusData?.refundStatus === "requested" ? true : false} />
              }
              label={
                <Typography sx={{ pt: 0.4, fontSize: 17, fontWeight: 400, mr: 5, color: "black" }}>
                  {t("refund.requested")}
                </Typography>
              }
            />
            <FormControlLabel
              value="resolved"
              control={
                <Radio checked={refundStatusData?.refundStatus === "resolved" ? true : false} />
              }
              label={
                <Typography sx={{ pt: 0.4, fontSize: 17, fontWeight: 400, mr: 5, color: "black" }}>
                  {t("refund.resolved")}
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <AdminButton
          onClick={handleAgree}
          color="primary"
          variant="contained"
          title={t("dialog.btnYes")}
        />

        <AdminButton title={t("dialog.btnNo")} onClick={() => handleClose(false)} color="primary" />
      </DialogActions>
    </Dialog>
  );
}
