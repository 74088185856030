export const ADRENA = {
  loginApi: "/admin/adminLogin",
  forgotPassword: "/admin/forgotPassword",
  uploadProfile: "/fileUpload/imageUpload/category",
  uploadSubCategoryProfile: "/fileUpload/imageUpload/subCategory",
  uploadFile: "/users/fileUpload/cv",
  getCategoryList: "/category/list",
  getSubCategoryList: "/category/listAllSubCategoriesWithPagination",
  addSubCategory: "/category/addSubCategory",
  removeprofile: "fileUpload/deleteImageFromS3",
  addCategory: "/category/add",
  ediCandidate: "/users/editCandidate",
  deleteCategory: "/category/delete/",
  deleteSubCategory: "/category/deleteSubCategory/",
  getCategoryDetail: "/category/detail/",
  updateCategory: "/category/update",
  getSubCategoryDetail: "/category/detailSubCategory/",
  updateSubCategory: "/category/updateSubCategory",
  getAdminProfileDetail: "/user/userProfile",
  updateAminProfile: "/user/updateUserProfile",
  changePassword: "/admin/adminResetPassword",
  getUserList: "/admin/allUserList",
  addressSearch: "/user/googleAddressSearch",
  addressPlaced: "/user/googlePlaceDetailByID",
  deleteUser: "/admin/deleteUserByAdmin",
  downloadCsv: "/admin/generateUserCsv",
  suspendAccount: "/admin/ActiveAndSuspendUserAccount",
  getUserDetail: "/admin/getUserDetail",
  addApprovalPanel: "/product/addApprovalPanel",
  updateProductStatus: "/product/updateProductStatus",
  getCouponsList: "/coupon/list",
  addCoupon: "/coupon/add",
  couponDetail: "/coupon/detail",
  couponUpdate: "/coupon/update",
  deleteCoupon: "/coupon/delete",
  downloadProductCsv: "/product/generateProductCsvForAdmin",
  getReportsAdsList: "/adReport/list",
  getDashboardData: "/admin/dashboardData",
  sendNotificationToUser: "/admin/sendNotificationToUsers",

  addPlan: "/payment/v1/createPlan", //payment/createPlan",

  getPlansList: "/payment/getPlans",
  getPlanDetail: "/payment/viewPlan",
  updatePlan: "/payment/v1/editPlan", //Used to edit plan by admin,
  deletePlan: "/payment/deletePlan",
  featuredProduct: "/admin/featureProductByAdmin",
  getPriceRange: "/payment/getPriceRange",
  createService: "/payment/createService",
  getServicesList: "/payment/getServices",
  viewService: "/payment/viewService",
  editService: "/payment/editService",
  deleteService: "/payment/deleteService",
  getRefundList: "/admin/refundRequestList",
  changeRefundStatus: "admin/changeRefundStatus",
  getContractList: "/admin/contractList",
  getOrderList: "/admin/orderList",
  chatMessages: "/chat/getChatlistOnetoOne",
  addEditUserByAdmin: "/admin/v1/addEditUserByAdmin", // add user by admin
  rateAndTagByAdmin: "/admin/rateAndTagByAdmin",
  getSubCategoryById: "/category/getSubcategoriesByCategoryIds",
  imageUpload: "/fileUpload/imageUpload",
  getUserPlansList: "/payment/getPlansForUser",
  getPaymentCalculation: "/payment/v1/paymentCalculation", //"/payment/paymentCalculation",
  getCreditCardList: "/payment/getPaymentMethod",
  addCreditCard: "/payment/addPaymentMethod",
  makePayment: "/payment/v1/makePayment", // "/payment/makePayment",
  changeVisibilityStatus: "/admin/updateVisibilityStatus",

  uploadUserCsv: "/admin/uploadUserCsv", // upload user csv
  uploadProductCsv: "/admin/uploadProductCsv", // upload Product Csv

  checkCouponCode: "/payment/checkCouponCode", // check coupon code
  addProduct: "/product/v1/addProduct", // add product
  getproductDetail: "/product/detail" //get product detail with product and user id
};

export const API_RESPONSE = {
  SUCCESS: 1,
  FAILURE: 0
};
