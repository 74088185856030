import { useEffect, useRef, useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  Grid,
  Menu,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import { Icon } from "@iconify/react";
import AdrenaInput from "components/adrenaInput";
import { useDispatch, useSelector } from "react-redux";
import AlertDialog from "components/alertDialog";
import { useTranslation } from "react-i18next";
import AdminButton from "components/adminButton";
import logo from "assets/img/noimage.jpeg";
import { ADRENA } from "services/apiConstant";
import { toast } from "react-toastify";
import api from "services";
import { addApprovalPanel } from "store";
import TableActionButton from "components/adminTableActionButton";
import { MuiValue } from "../styles";
import { updateProductStatus } from "store";
import ProductFilterDialog from "./filterDialog";
import DeclineProduct from "../../../components/alertDialog/declineProduct";
import Loader from "components/loader/loader";
import { featuredProduct } from "store";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { changeVisibilityStatus } from "store";
import { encrypt } from "constants/appConstant";
import UserDetailDialog from "../users/userDetailDialog";
import { clearImageListData } from "store";
import CSVErrorListDialog from "./csvErrorListDialog";
export default function products() {
  var page = 1;
  const perPage = 10;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  var searchInputRef = useRef();
  const uploadCsv = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [perPageRows, setNumberOfRows] = useState(perPage);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [pageCheck, setPageCheck] = useState(1);
  const [badge, setBadge] = useState("");
  var [proStatus, setProStatus] = useState("");
  var [address, setAddress] = useState("");
  const [range, setRange] = useState([]);
  const [csvData, setCsvData] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [opeUserDialog, setOpenUserDialog] = useState(false);
  const [userData, setUserData] = useState("");
  const [approveDialog, setApproveDialog] = useState(false);
  const [declineDialog, setDeclineDialog] = useState(false);
  const [reason, setReason] = useState("");
  const [adStatus, setAdStatus] = useState("");
  const [csvErrorDialog, setCSVErrorDialog] = useState(false);
  const [csvErrorData, setCSVErrorData] = useState([]);
  const { productListData, totalProduct } = useSelector((state) => state.productSlice);
  var theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const label = { inputProps: { "aria-label": "Color switch demo" } };
  useEffect(() => {
    productList();
  }, []);
  useEffect(() => {
    if (productListData) {
      setIsLoading(false);
    }
  }, [productListData]);
  useEffect(() => {
    if (csvData) {
      let path = csvData;
      console.log(path);
      const link = document.createElement("a");
      link.href = csvData;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [csvData]);

  const downloadCsv = () => {
    const data = {
      language: i18n.language
    };
    api
      .post(`${ADRENA.downloadProductCsv}`, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((response) => {
        setCsvData(response?.data?.path);
        toast.success(response.data.message);
      })
      .catch(() => {
        ("error");
      });
  };

  const productList = (page, numberOfRows) => {
    var data = {
      search: searchInputRef.current.value,
      language: i18n.language,
      limit: numberOfRows ? numberOfRows : perPageRows,
      page: page ? page : 1,
      productStatus: proStatus,
      sellerVerificationStatus: badge,
      region: "",
      minRange: range[0],
      maxRange: range[1],
      location: address ? address : ""
    };

    dispatch(addApprovalPanel(data, setIsLoading));
  };

  const saveFilter = (page, numberOfRows) => {
    var data = {
      page: page ? page : 1,
      limit: numberOfRows ? numberOfRows : perPageRows,
      search: searchInputRef.current.value,
      productStatus: proStatus,
      sellerVerificationStatus: badge,
      region: "",
      minRange: range[0],
      maxRange: range[1],
      language: i18n.language,
      location: address
    };
    dispatch(addApprovalPanel(data, setIsLoading, setOpen));
  };

  const handleClick = (event, dataIndex) => {
    setAnchorEl(event.currentTarget);
    const index = productListData.findIndex((item) => item.id === dataIndex);
    setAdStatus(productListData[index]?.productStatus);
    setSelectedRowIndex(dataIndex);
  };
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length > 2) {
      productList(page);
    } else if (e.target.value.length === 0) {
      productList(page);
    }
  };

  const clearSearch = () => {
    setSearchValue("");
    var data = {
      page: 1,
      limit: 10,
      search: ""
    };
    let isSearch = false;

    dispatch(addApprovalPanel(data, isSearch, setIsLoading));
  };

  const handleApprove = () => {
    let data = {
      language: i18n.language,
      adId: selectedRowIndex,
      productStatus: "approve",
      reason: ""
    };
    dispatch(updateProductStatus(data, setIsLoading));
  };
  const handleDecline = () => {
    let data = {
      language: i18n.language,
      adId: selectedRowIndex,
      productStatus: "decline",
      reason: reason
    };
    dispatch(updateProductStatus(data, setIsLoading));
  };
  const handleFeaturedAd = (id, index) => {
    let requestParams = {
      productId: id,
      isFeature: !productListData[index].isFeaturedByAdmin,
      language: i18n?.language
    };
    dispatch(featuredProduct(requestParams));
  };
  const optionMenu = () => {
    let index = productListData?.findIndex((item) => item?.id == selectedRowIndex);

    return (
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem
          onClick={() => {
            navigate(`/adrenaAds/addAds/${selectedRowIndex}/${productListData[index]?.userId}`);
          }}>
          {t("category.edit")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setUserData(productListData[index]);
            setAnchorEl(null);
            setOpenUserDialog(true);
          }}>
          {t("user.detail")}
        </MenuItem>
        <>
          {(adStatus != "approve" || adStatus === "publish") && (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setApproveDialog(true);
              }}>
              {t("product.approveAd")}
            </MenuItem>
          )}
          {(adStatus != "decline" || adStatus === "publish") && (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setDeclineDialog(true);
              }}>
              {t("product.declineAd")}
            </MenuItem>
          )}
        </>
      </Menu>
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = {
    count: totalProduct,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: t("category.rowsPerPage"),
        displayRows: "of"
      },
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`
      }
    },
    onColumnSortChange: (changedColumn, direction) => {
      let sortingOrder = direction === "asc" ? 1 : -1;

      productList(page, "", changedColumn, sortingOrder);
    },
    onChangePage(currentPage) {
      if (currentPage >= pageCheck) {
        if (currentPage === 0) {
          currentPage = currentPage + 1;
        }
        setPageCheck(currentPage);
        page = currentPage + 1;
        productList(page, "", "", "");
      } else {
        if (currentPage > 0) {
          page = currentPage - 1;
        }
        setPageCheck(currentPage);
        productList(page, "", "", "");
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      setNumberOfRows(numberOfRows);
      productList(page, numberOfRows, "", "");
    }
  };
  const columns = [
    {
      name: "coverPhotoFullUrl",
      label: t("category.image"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <img
                src={value ? value : logo}
                style={{
                  width: 50,
                  height: 50,
                  objectFit: "cover",
                  border: "0.5px solid #232323",
                  borderRadius: 5
                }}
              />
            </Grid>
          );
        }
      }
    },

    {
      name: "title",
      label: t("products.title"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  wordWrap: "break-word",
                  maxWidth: 100,
                  fontSize: 14
                }}>
                {value ? value.charAt(0).toUpperCase() + value.slice(1) : "-"}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "description",
      label: t("category.description"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography style={MuiValue}>{value ? value : "-"}</Typography>
            </Tooltip>
          );
        }
      }
    },

    {
      name: "price",
      label: t("products.price"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography style={MuiValue}>{value ? value : "-"}</Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "address",
      label: t("user.Address"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography style={MuiValue}>{value ? value : 0}</Typography>
            </Tooltip>
          );
        }
      }
    },

    {
      name: "conservationState",
      label: t("products.conservationState"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography style={MuiValue}>{value ? value : 0}</Typography>
            </Tooltip>
          );
        }
      }
    },

    {
      name: "productStatus",
      label: t("products.productStatus"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip
              title={
                value === "approve"
                  ? "Approved"
                  : value === "publish"
                  ? "Published"
                  : value === "decline"
                  ? "Declined"
                  : value === "sold"
                  ? "Sold"
                  : value === "processing"
                  ? "Processing"
                  : value === "draft"
                  ? "Draft"
                  : "-"
              }>
              <Typography style={MuiValue}>
                {value === "approve"
                  ? "Approved"
                  : value === "publish"
                  ? "Published"
                  : value === "decline"
                  ? "Declined"
                  : value === "sold"
                  ? "Sold"
                  : value === "processing"
                  ? "Processing"
                  : value === "draft"
                  ? "Draft"
                  : "-"}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "badgeVerified",
      label: t("products.seller"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Typography style={MuiValue}>{value === true ? "Verified" : "Not Verified"}</Typography>
          );
        }
      }
    },
    {
      name: "visibilityStatus",
      label: t("product.visibilityStatus"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Select
              fullWidth
              onChange={(val) => {
                let request = {
                  productIds: [productListData[tableMeta?.rowIndex]?.id],
                  visibilityStatus: val.target.value,
                  language: i18n?.language
                };
                dispatch(changeVisibilityStatus(request));
              }}
              value={value}
              inputProps={{
                MenuProps: {
                  MenuListProps: {
                    sx: {
                      backgroundColor: "white",
                      color: "black",
                      fontFamily: "setimo",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "22px",
                      "& .MuiMenuItem-root.Mui-selected": {
                        backgroundColor: "#d66565",
                        color: "white"
                      },
                      "& .MuiMenuItem-root:hover": {
                        backgroundColor: "#d66565",
                        color: "white"
                      }
                    }
                  }
                }
              }}
              style={{
                marginTop: "0.9rem",
                color: "black",
                marginBottom: "0.5rem",
                height: "2.7rem"
              }}>
              <MenuItem value={1}>{t("product.visibilityHigh")} </MenuItem>
              <MenuItem value={2}>{t("product.visibilityMedium")} </MenuItem>
              <MenuItem value={3}>{t("product.visibilityLow")} </MenuItem>
            </Select>
          );
        }
      }
    },
    {
      name: "createdAt",
      label: t("products.createdAt"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography style={MuiValue}>{value ? value : "-"}</Typography>;
        }
      }
    },
    {
      name: "updatedAt",
      label: t("products.updatedAt"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return <Typography style={MuiValue}>{value ? value : "-"}</Typography>;
        }
      }
    },
    {
      name: "id",
      label: t("category.featuredAd"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          let index = productListData.findIndex((item) => item.id === value);
          return (
            <>
              <Switch
                {...label}
                checked={productListData[index].isFeaturedByAdmin}
                color="error"
                onChange={() => handleFeaturedAd(value, index)}
              />
            </>
          );
        }
      }
    },
    {
      name: "id",
      label: t("reportAd.productLink"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Link
              style={{ marginLeft: "1rem" }}
              to={
                productListData[productListData.findIndex((item) => item.id === value)]
                  ?.productStatus === "publish"
                  ? `${process.env.REACT_APP_WEB_URL}/admin/${value}`
                  : `${process.env.REACT_APP_WEB_URL}/buy/productName/details/${encrypt(value)}`
              }
              target="_blank">
              <Icon icon="ph:link-bold" color="red" height="25" width="25" />
            </Link>
          );
        }
      }
    },
    {
      name: "id",
      label: t("category.action"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (dataIndex) => {
          // let status =
          //   productListData[productListData.findIndex((item) => item.id === dataIndex)]
          //     ?.productStatus;
          return (
            <>
              <>
                <TableActionButton
                  style={{ marginLeft: 10 }}
                  onClick={(event) => handleClick(event, dataIndex)}
                />
                {optionMenu(dataIndex)}
              </>
            </>
          );
        }
      }
    }
  ];

  const badgeData = (badgeData) => {
    setBadge(badgeData);
  };

  const productStatus = (productStatus) => {
    proStatus = productStatus;
    setProStatus(productStatus);
  };
  const addressData = (addressData) => {
    address = addressData;
    setAddress(addressData);
  };
  const rangeData = (rangeData) => {
    setRange(rangeData);
  };
  // upload csv

  const handleUploadCsv = (event) => {
    setIsLoading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    api
      .post(`${ADRENA?.uploadProductCsv}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((response) => {
        if (response.data?.status) {
          if (response.data?.errorData?.length > 0) {
            setCSVErrorData(response?.data?.errorData);
            setCSVErrorDialog(true);
          } else {
            toast.success(response.data.message);
          }
          productList();
        } else {
          setIsLoading(false);
          toast.error(response.data.message);
        }
      })
      .catch(() => {
        setIsLoading(false)("error");
      });
  };
  return (
    <Grid container>
      {csvErrorData?.length > 0 && (
        <CSVErrorListDialog
          data={csvErrorData}
          setData={setCSVErrorData}
          ShowDialog={csvErrorDialog}
          setShowDialog={setCSVErrorDialog}
        />
      )}
      {userData && (
        <UserDetailDialog
          userDetaiData={userData}
          ShowDialog={opeUserDialog}
          setShowDialog={setOpenUserDialog}
        />
      )}
      <Grid
        container
        sx={{
          border: " 1px solid rgba(224, 224, 224, 1)",
          p: 2,
          backgroundColor: "white"
        }}>
        <Grid sx={{ mt: 1 }}>
          <Typography variant="body1" sx={{ fontSize: 18 }} color="secondary.contrastText">
            {t("products.productList")}
          </Typography>
        </Grid>
        <Grid container>
          <Grid xs={isMobile ? 12 : 3}>
            <AdrenaInput
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  productList(page);
                }
              }}
              inputRef={searchInputRef}
              title={t("product.searchProduct")}
              type="email"
              endIcon={
                searchValue && (
                  <Icon
                    icon="material-symbols:cancel"
                    style={{ cursor: "pointer" }}
                    color="red"
                    onClick={() => clearSearch()}
                  />
                )
              }
              onChange={handleSearchChange}
              value={searchValue}
            />
          </Grid>
          <Grid xs={isMobile ? 5 : 1} sx={{ mt: 2, mr: isMobile ? 2 : 0 }}>
            <ProductFilterDialog
              ShowDialog={open}
              badgeData={badgeData}
              productList={productList}
              productdata={productStatus}
              addressData={addressData}
              rangeData={rangeData}
              userlist={productList}
              onClick={() => {
                saveFilter();
                setOpen(!open);
              }}
              setShowDialog={setOpen}
            />
            <AdminButton
              title="Filter"
              startIcon={<Icon icon="clarity:filter-solid" color="white" />}
              onClick={() => setOpen(true)}
              sx={{ height: 41, ml: 1, mt: 0.2 }}
            />
          </Grid>
          <Grid xs={isMobile ? 6.2 : 2} sx={{ mt: 2 }}>
            &nbsp;{" "}
            <AdminButton
              title="Download CSV"
              startIcon={<Icon icon="material-symbols:download" color="white" />}
              onClick={downloadCsv}
              sx={{ height: 41, mt: 0.2 }}
            />
          </Grid>
          <Grid
            xs={isMobile ? 12 : 6}
            sx={{ justifyContent: isMobile ? "start" : "end", display: "flex" }}>
            <AdminButton
              title={t("user.uploadCsv")}
              startIcon={<Icon icon="material-symbols:upload-sharp" color="white" />}
              onClick={() => uploadCsv?.current?.click()}
              sx={{ height: 41, mt: 2.5 }}
            />
            &nbsp;&nbsp;
            <input
              ref={uploadCsv}
              style={{ display: "none", cursor: "pointer" }}
              type="file"
              accept=".csv"
              onChange={handleUploadCsv}
            />
            <AdminButton
              title={t("adrenaAds.addAds")}
              onClick={() => {
                dispatch(clearImageListData());
                navigate("/adrenaAds/addAds");
              }}
              sx={{ height: 41, mt: 2.5 }}
            />
          </Grid>
        </Grid>
      </Grid>
      {isLoading ? (
        <>
          <Grid
            container
            sx={{
              border: "1px solid rgba(224, 224, 224, 1)",
              mt: 2,
              height: 300,
              backgroundColor: "white"
            }}>
            <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}></Grid>
          </Grid>
        </>
      ) : (
        <>
          {productListData && productListData.length > 0 ? (
            <Grid container>
              <Grid xs={12}>
                <MUIDataTable data={productListData} columns={columns} options={options} />
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid
                container
                sx={{
                  border: "1px solid rgba(224, 224, 224, 1)",
                  mt: 2,
                  height: 300,
                  backgroundColor: "white"
                }}>
                <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
                  <Typography sx={{ fontSize: 24, fontWeight: 500, mt: "7.5rem" }}>
                    {t("category.noRecordFound")}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}

      <AlertDialog
        open={approveDialog}
        title="Warning"
        text={t("product.approveProduct")}
        handleClose={setApproveDialog}
        handleAgree={handleApprove}
      />
      <DeclineProduct
        open={declineDialog}
        title="Warning"
        text={t("product.declineProduct")}
        handleClose={setDeclineDialog}
        handleAgree={handleDecline}
        setReason={setReason}
        reason={reason}
      />
      {isLoading && <Loader open={isLoading} text={"Please Wait"} />}
    </Grid>
  );
}
