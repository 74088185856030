import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import protugueseLang from "assets/translations/pt.json";
import englishLang from "assets/translations/en.json";

const resources = {
  en: {
    translation: englishLang
  },
  pt: {
    translation: protugueseLang
  }
};

const fallbackLng = ["en"];
const availableLanguages = ["en", "pt"];

i18n
  .use(HttpApi) // load translations using http (default public/locals/en/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    interpolation: { escapeValue: true }, // React already does escaping
    resources,
    fallbackLng, // fallback language is english.
    preload: ["en", "pt"],
    detection: {
      checkWhitelist: true // options for l anguage detection
    },
    supportedLngs: availableLanguages,
    react: {
      useSuspense: false,
      updateTransComponent: true
    },
    debug: false,
    whitelist: availableLanguages,
    defaultTransParent: "div",
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i"]
  });

export default i18n;
