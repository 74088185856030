import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import { DialogContent, Grid, IconButton, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

const CSVErrorListDialog = (props) => {
  const { t } = useTranslation();
  const { ShowDialog, setShowDialog, data } = props;
  const onClose = () => {
    setShowDialog(!ShowDialog);
  };
  return (
    <Dialog
      open={ShowDialog}
      PaperProps={{
        style: {
          backgroundColor: "#323232",
          boxShadow: "none",
          width: "578px"
        }
      }}>
      <DialogTitle>
        <Typography
          sx={{
            color: "#E7E9EA",
            fontSize: "22px",
            fontFamily: "setimo",
            fontWeight: 700
          }}>
          {t("csv.errors")}
        </Typography>

        <IconButton sx={{ float: "right", mt: -4.8 }} onClick={onClose}>
          <Icon
            icon="system-uicons:cross"
            color="#E7E9EA"
            width={"23.63px"}
            height={"23.63px"}
            style={{ fontWeight: 700 }}
          />
        </IconButton>
      </DialogTitle>
      <Divider light />

      <DialogContent>
        <Grid container spacing={1.5} sx={{ mt: 1 }}>
          {data?.map((item, index) => {
            return (
              <Grid item key={index} xs={12} sx={{ fontWeight: 500, fontSize: 20, color: "white" }}>
                <Icon icon="mdi:circle" width={15} />
                &nbsp; {item}
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={12} sx={{ fontWeight: 500, mt: 3, fontSize: 20, color: "white" }}>
          {t("csv.errorInfo")}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CSVErrorListDialog;
