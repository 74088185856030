import React, { useEffect } from "react";
import {
  AppBar,
  Avatar,
  Button,
  Grid,
  Popover,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import CardWithIcon from "./countBox";
import { Icon } from "@iconify/react";
import { Box } from "@mui/material";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useState } from "react";
import "./index.css";
import SplitButton from "./splitButton";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData } from "store";
import CategoryBox from "./categoryBox";
import FilterByCity from "./filterByCity";
import { pt } from "date-fns/locale";
import enUS from "date-fns/esm/locale/en-US";
import { useTheme } from "@mui/styles";

export default function DashBoard() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { dashboardData } = useSelector((state) => state.productSlice);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  var theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  useEffect(() => {
    fetchdashBoardData();
  }, []);
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection"
    }
  ]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const fetchdashBoardData = (start, end, city) => {
    let obj = {
      startDate: start ? start : "",
      endDate: end ? end : "end",
      city: city ? city : "",
      language: i18n?.language
    };
    dispatch(getDashboardData(obj));
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setDate([selection]);
    setStartDate(moment(selection.startDate.toString()).format("DD MMM,YYYY"));
    setEndDate(moment(selection.endDate.toString()).format("DD MMM,YYYY"));
    fetchdashBoardData(selection.startDate.toString(), selection.endDate.toString());
  };

  const handleClearfilter = () => {
    const date = [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection"
      }
    ];
    setDate(date);
    setStartDate("");
    setEndDate("");
    fetchdashBoardData("", "");
  };

  return (
    <>
      {isMobile ? (
        <>
          <AppBar component="nav" sx={{ backgroundColor: "#e9e9e9", mt: 8, pl: 9, pr: 2, pt: 5 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", pb: 2 }}>
              <Typography sx={{ fontSize: 25, fontWeight: 700, color: "#212b36" }}>
                {t("menu.dashboard")}
              </Typography>
              <SplitButton setStartDate={setStartDate} fetchdashBoardData={fetchdashBoardData} />
            </Box>
            <Stack direction="row" sx={{ pb: 2 }}>
              <Box sx={{ width: "90%", backgroundColor: "#dad6d6", p: 1 }}>
                {/* <Typography sx={{ pt: 0.4, color: "black" }}>{`${startDate && startDate} ${
                  startDate && "-"
                } ${endDate && endDate}`}</Typography> */}
                <Typography sx={{ pt: 0.4, color: "black" }}>
                  {startDate
                    ? `${startDate && startDate} ${startDate && "-"} ${endDate && endDate}`
                    : "Select date"}
                </Typography>
              </Box>
              <Avatar sx={{ bgcolor: "red" }} variant="square">
                <Icon
                  icon="clarity:calendar-solid"
                  color="white"
                  width="25"
                  height="25"
                  aria-describedby={id}
                  variant="contained"
                  onClick={handleClick}
                />
              </Avatar>
            </Stack>
            <FilterByCity fetchdashBoardData={fetchdashBoardData} isMobile={isMobile} />
          </AppBar>
        </>
      ) : (
        <>
          {/* <AppBar
            component="nav"
            sx={{
              backgroundColor: "#e9e9e9",
              pt: 12,
              px: 15,
              boxShadow: 0
            }}> */}

          <Box sx={{ display: "flex", justifyContent: "space-between", position: "relative" }}>
            <Typography sx={{ fontSize: 25, fontWeight: 700, color: "#212b36" }}>
              {t("menu.dashboard")}
            </Typography>
            <Stack direction="row">
              <Box sx={{ width: 200, backgroundColor: "#dad6d6", p: 1 }}>
                <Typography sx={{ pt: 0.4, color: "black", pl: !startDate && 2 }}>
                  {startDate
                    ? `${startDate && startDate} ${startDate && "-"} ${endDate && endDate}`
                    : "Select date"}
                </Typography>
              </Box>
              <Avatar sx={{ bgcolor: "red", height: "42px" }} variant="square">
                <Icon
                  icon="clarity:calendar-solid"
                  color="white"
                  width="25"
                  height="25"
                  aria-describedby={id}
                  variant="contained"
                  onClick={handleClick}
                />
              </Avatar>
              &nbsp; &nbsp;
              <SplitButton setStartDate={setStartDate} fetchdashBoardData={fetchdashBoardData} />
              &nbsp; &nbsp;
              <FilterByCity fetchdashBoardData={fetchdashBoardData} />
            </Stack>
          </Box>
          {/* </AppBar> */}
        </>
      )}
      <Box sx={{ px: { xs: 1.5, sm: 1.5, md: 5, lg: 5 }, pt: isMobile ? 25 : 2 }}>
        <Typography sx={{ fontSize: 25, fontWeight: 700, mt: 3, pb: 2 }}>
          {t("menu.users")}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <CardWithIcon
              Icon={<Icon icon="lucide:users-2" color="black" width="70" height="70" />}
              value={dashboardData?.totalUsers}
              title={t("dashBoard.totalUsers")}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <CardWithIcon
              Icon={<Icon icon="uiw:login" color="black" width="70" height="70" />}
              value={dashboardData?.lastLoginUsers}
              title={t("dashBoard.lastLogin")}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <CardWithIcon
              Icon={<Icon icon="tabler:user-dollar" color="black" width="70" height="70" />}
              value={dashboardData?.totalSellers}
              title={t("dashBoard.totalSellers")}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <CardWithIcon
              Icon={
                <Icon
                  icon="streamline:interface-user-lock-actions-lock-geometric-human-person-single-up-user"
                  color="black"
                  width="70"
                  height="70"
                />
              }
              value={dashboardData?.totalBuyers}
              title={t("dashBoard.totalBuyers")}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <CardWithIcon
              Icon={<Icon icon="mdi:user-check-outline" color="black" width="70" height="70" />}
              value={dashboardData?.verifiedUsers}
              title={t("dashBoard.totalVerifiedSeller")}
            />
          </Grid>
        </Grid>

        <Typography sx={{ fontSize: 25, fontWeight: 700, pt: 8, pb: 2 }}>
          {t("dashBoard.ads")}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <CardWithIcon
              Icon={<Icon icon="ri:product-hunt-line" color="black" width="70" height="70" />}
              value={dashboardData?.totalAds}
              title={t("user.totalAds")}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <CardWithIcon
              Icon={
                <Icon icon="carbon:intent-request-active" color="black" width="70" height="70" />
              }
              value={dashboardData?.totalActiveAds}
              title={t("dashBoard.activeAds")}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <CardWithIcon
              Icon={
                <Icon
                  icon="ic:baseline-motion-photos-paused"
                  color="black"
                  width="70"
                  height="70"
                />
              }
              value={dashboardData?.totalPausedAds}
              title={t("dashBoard.pauseAds")}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <CardWithIcon
              Icon={
                <Icon icon="carbon:intent-request-uninstall" color="black" width="70" height="70" />
              }
              value={dashboardData?.totalCloseAds}
              title={t("dashBoard.closedAds")}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <CardWithIcon
              Icon={<Icon icon="ic:outline-report-problem" color="black" width="70" height="70" />}
              value={dashboardData?.reportedAds}
              title={t("dashBoard.reportedAds")}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <CardWithIcon
              Icon={<Icon icon="mdi:favourite-border" color="black" width="70" height="70" />}
              value={dashboardData?.favoriteAds}
              title={t("dashBoard.favouriteAds")}
            />
          </Grid>
        </Grid>
        <Typography sx={{ fontSize: 25, fontWeight: 700, pt: 8, pb: 2 }}>
          {t("menu.categories")}
        </Typography>
        <Grid container spacing={3}>
          {dashboardData?.categories?.map((category, index) => {
            return (
              <Grid item xs={12} sm={6} lg={3} xl={2} key={index}>
                <CategoryBox data={category} isMobile={isMobile} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}>
        <DateRange
          locale={i18n.language === "pt" ? pt : enUS}
          onChange={handleOnChange}
          rangeColors={"#E01F26"}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={date}
          direction="horizontal"
        />
        <Box>
          <Button onClick={handleClearfilter}>Clear All</Button>
        </Box>
      </Popover>
    </>
  );
}
