// component
import { Icon } from "@iconify/react";
import { Translation } from "react-i18next";

const navConfig = [
  {
    title: (
      <Translation sx={{ height: 10 }}>{(t) => <span>{t("menu.dashboard")}</span>}</Translation>
    ),
    path: "/dashboard",
    icon: <Icon icon="clarity:dashboard-line" fontSize={20} />
  },
  {
    title: <Translation>{(t) => <span>{t("menu.categories")}</span>}</Translation>,
    path: "/categories",
    icon: <Icon icon="ic:outline-category" fontSize={20} />
  },

  {
    title: <Translation>{(t) => <span>{t("menu.subCategories")}</span>}</Translation>,
    path: "/subCategories",
    icon: <Icon icon="carbon:category" fontSize={20} />
  },
  {
    title: <Translation>{(t) => <span>{t("menu.users")}</span>}</Translation>,
    path: "/users",
    icon: <Icon icon="clarity:users-line" fontSize={20} />
  },
  {
    title: <Translation>{(t) => <span>{t("menu.products")}</span>}</Translation>,
    path: "/adds",
    icon: <Icon icon="ri:product-hunt-line" fontSize={20} />
  },
  {
    title: <Translation>{(t) => <span>{t("menu.coupons")}</span>}</Translation>,
    path: "/coupon",
    icon: <Icon icon="mdi:coupon-outline" fontSize={20} />
  },
  {
    title: <Translation>{(t) => <span>{t("menu.reportsAd")}</span>}</Translation>,
    path: "/reportAd",
    icon: <Icon icon="ic:outline-report" fontSize={20} />
  },
  {
    title: <Translation>{(t) => <span>{t("menu.badge")}</span>}</Translation>,
    path: "/badge",
    icon: <Icon icon="mdi:badge-outline" fontSize={20} />
  },
  {
    title: <Translation>{(t) => <span>{t("menu.plans")}</span>}</Translation>,
    path: "/plans",
    icon: <Icon icon="carbon:currency" fontSize={20} />
  },
  {
    title: <Translation>{(t) => <span>{t("menu.notification")}</span>}</Translation>,
    path: "/notification",
    icon: <Icon icon="mdi:message-notification-outline" fontSize={20} />
  },
  {
    title: <Translation>{() => <span>Verified Plan</span>}</Translation>,
    path: "/VerifiedPlan",
    icon: <Icon icon="ic:outline-verified" fontSize={20} />
  },
  {
    title: <Translation>{(t) => <span>{t("refund.title")}</span>}</Translation>,
    path: "/refund",
    icon: <Icon icon="gridicons:refund" fontSize={20} />
  },
  {
    title: <Translation>{(t) => <span>{t("contract.title")}</span>}</Translation>,
    path: "/contract",
    icon: <Icon icon="material-symbols:contract-edit-outline" fontSize={20} />
  },
  {
    title: <Translation>{(t) => <span>{t("orders.title")}</span>}</Translation>,
    path: "/orders",
    icon: <Icon icon="raphael:cart" fontSize={20} />
  },

  {
    title: <Translation>{(t) => <span>{t("menu.settings")}</span>}</Translation>,
    path: "/settings",
    icon: <Icon icon="carbon:settings" fontSize={20} />
  }
];

export default navConfig;
