/** @format */

import { useState } from "react";
import { Grid, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PaymentIcon from "react-payment-icons";
import { Icon } from "@iconify/react";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import AdrenaCardInput from "components/carInput/cardInput";
import { PatternFormat } from "react-number-format";

export const CardNumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      format="#### #### #### ####"
    />
  );
};
export const DateFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      format="##/####"
    />
  );
};
const AddCreditCardModal = (props) => {
  //   const dispatch = useDispatch();
  const { setInputs, inputs, setCardError, cardError, setCardData, checkBoxSx, disabled, show } =
    props;
  const { t } = useTranslation();
  const [cardBrandName, setCardBrandName] = useState("");
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    let cardName = "";
    if (name === "cardFirstName") {
      if (value.length === 0) {
        setCardError((cardError) => ({
          ...cardError,
          firstNameError: t("cardError.firstName")
        }));
      } else {
        setCardError((cardError) => ({
          ...cardError,
          firstNameError: ""
        }));
      }
    }
    if (name === "cardLastName") {
      if (value.length === 0) {
        setCardError((cardError) => ({
          ...cardError,
          lastNameError: t("cardError.lastName")
        }));
      } else {
        setCardError((cardError) => ({
          ...cardError,
          lastNameError: ""
        }));
      }
    }
    if (name === "card") {
      // eslint-disable-next-line no-undef
      let isCardValid = window.Iugu.utils.validateCreditCardNumber(value); // Retorna true
      cardName = window.Iugu.utils.getBrandByCreditCardNumber(value);
      setCardBrandName(cardName);
      if (!isCardValid) {
        setCardError((cardError) => ({
          ...cardError,
          cardNumberError: t("cardError.card")
        }));
      } else {
        setCardError((cardError) => ({
          ...cardError,
          cardNumberError: ""
        }));
      }
    }

    if (name === "cvv") {
      let val = value.toString();
      let isCvvValid = window.Iugu.utils.validateCVV(val, cardBrandName.toString());
      if (!isCvvValid) {
        setCardError((cardError) => ({
          ...cardError,
          cvvError: t("cardError.cvv")
        }));
      } else {
        setCardError((cardError) => ({
          ...cardError,
          cvvError: ""
        }));
      }
    }
    if (name === "expiration") {
      let isExpirationValid = window.Iugu.utils.validateExpiration(
        value?.slice(0, 2),
        value.slice(2)
      );
      if (!isExpirationValid) {
        setCardError((cardError) => ({
          ...cardError,
          expirationError: t("cardError.expiration")
        }));
      } else {
        setCardError((cardError) => ({
          ...cardError,
          expirationError: ""
        }));
      }
    }

    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  return (
    <>
      <Box
        sx={{
          height: "auto",
          border: "1px solid white",
          px: 2,
          py: 2,
          mb: 1,
          position: "relative"
        }}>
        <AdrenaCardInput
          label={t("card.cardNumber")}
          customInput={CardNumberFormatCustom}
          placeholder={t("card.enterCardNumber")}
          type="text"
          name="card"
          value={inputs?.card}
          onChange={handleFormChange}
          endIcon={
            cardBrandName === "" ? (
              <Icon icon="bytesize:creditcard" color="white" width={28} />
            ) : (
              <PaymentIcon id={cardBrandName} style={{ width: 28 }} className="payment-icon" />
            )
          }
          formError={cardError?.cardNumberError}
        />

        <Grid container spacing={2} sx={{ pt: 1 }}>
          <Grid item xs={6}>
            <AdrenaCardInput
              label={t("card.nameOnCard")}
              name="cardFirstName"
              placeholder={t("card.firstName")}
              type="text"
              value={inputs?.cardFirstName}
              onChange={handleFormChange}
              formError={cardError?.firstNameError}
            />
          </Grid>
          <Grid item xs={6}>
            <AdrenaCardInput
              // label={'NAME ON CARD'}
              name="cardLastName"
              placeholder={t("card.lastName")}
              type="text"
              value={inputs?.cardLastName}
              onChange={handleFormChange}
              formError={cardError?.lastNameError}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ pt: 1 }}>
          <Grid item xs={6}>
            <AdrenaCardInput
              label={t("card.expireDate")}
              name="expiration"
              placeholder="MM/YYYY"
              customInput={DateFormatCustom}
              type="text"
              value={inputs?.expiration}
              onChange={handleFormChange}
              formError={cardError?.expirationError}
            />
          </Grid>
          <Grid item xs={6}>
            <AdrenaCardInput
              label={t("card.cvv")}
              name="cvv"
              placeholder={t("card.enterCvv")}
              type="text"
              value={inputs?.cvv}
              onChange={handleFormChange}
              formError={cardError?.cvvError}
            />
          </Grid>
        </Grid>
      </Box>
      {!show && (
        <FormControlLabel
          sx={checkBoxSx}
          control={
            <Checkbox
              disabled={disabled}
              onChange={(e) =>
                setCardData((cardData) => ({
                  ...cardData,
                  isCardSaved: e.target.checked
                }))
              }
              icon={<Icon icon="pixelarticons:checkbox-on" color="white" height="20" width="20" />}
              checkedIcon={<Icon icon="ion:checkbox-sharp" color="red" height="20" width="20" />}
            />
          }
          label={
            <Typography
              sx={{
                mt: 0.8,
                color: "white",
                fontFamily: "setimo",
                fontSize: "16px",
                lineHeight: "22px",
                fontWeight: 400
              }}>
              {t("card.saveCard")}
            </Typography>
          }
        />
      )}
    </>
  );
};
export default AddCreditCardModal;
