const AdBrandConfig = [
  {
    id: "New",
    heading: "New",
    subHeading: "conservationState.new"
  },
  {
    id: "Great condition",
    heading: "Great condition",
    subHeading: "conservationState.greatCondition"
  },
  {
    id: "Good state",
    heading: "Good state",
    subHeading: "conservationState.goodState"
  },
  {
    id: "Middle state",
    heading: "Middle state",
    subHeading: "conservationState.middleStates"
  },
  {
    id: "Bad state",
    heading: "Bad state",
    subHeading: "conservationState.badState"
  }
];
export const documentArray = [
  {
    value: "invoice",
    title: "adDocument.invoice"
  },
  {
    value: "importDocument",
    title: "adDocument.importDocument"
  },
  {
    value: "foreignTaxCoupon",
    title: "adDocument.foreignTaxCoupon"
  },
  {
    value: "foreignTaxAndImportDocument",
    title: "adDocument.foreignTaxAndImportDocument"
  },
  {
    value: "noDocument",
    title: "adDocument.noDocument"
  }
];
export default AdBrandConfig;
