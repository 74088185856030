import React, { useEffect, useRef, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Grid, Menu, MenuItem, Switch, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { Icon } from "@iconify/react";
import AdrenaInput from "components/adrenaInput";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  clearUserDetail,
  getUserDetail,
  getUserList,
  suspendAccount,
  userDelete
} from "store/slice/users";
import { getMomentFormat } from "constants/dateTime.constant";
import moment from "moment";
import TableActionButton from "components/adminTableActionButton";
import AlertDialog from "components/alertDialog";
import { useTranslation } from "react-i18next";
import FilterDialog from "./filterDialog";
import AdminButton from "components/adminButton";
import Loader from "components/loader/loader";
import { ADRENA } from "services/apiConstant";
import { toast } from "react-toastify";
import api from "services";
import UserDetailDialog from "./userDetailDialog";
import { useNavigate } from "react-router-dom";
import UserRatingDialog from "components/alertDialog/userRatingDialog";
import UserLabelDialog from "components/alertDialog/userLabelDialog";
import { useTheme } from "@mui/styles";
export default function Users() {
  let page = 1;
  const perPage = 10;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  var searchInputRef = useRef();
  const uploadCsv = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [opeUserDialog, setOpenUserDialog] = useState(false);
  const [alertDialogRemoveOpen, setAlertDialogRemoveOpen] = useState(false);
  const [alertDialogRemoveOpen1, setAlertDialogRemoveOpen1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [perPageRows, setNumberOfRows] = useState(perPage);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState(-1);
  const [pageCheck, setPageCheck] = useState(1);
  const [badge, setBadge] = useState("");
  const [accountType, setAccountType] = useState("");
  const [address, setAddress] = useState("");
  const [adStatus, setAdStatus] = useState("");
  const [noOfAdds, setNoOfAdds] = useState([]);
  const [lastLogin, setLastLogin] = useState();
  const [csvData, setCsvData] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [userRatingDialog, setUserRatingDialog] = useState(false);
  const [userLabelDialog, setUserLabelDialog] = useState(false);
  const [totalCountUser, setTotalCountUser] = useState(0);
  const { userListData, totalCount, getUserDetailData } = useSelector((state) => state.userSlice);
  const reducerObj = useSelector((state) => state, shallowEqual);
  var theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    setIsLoading(true);
    userList();
  }, []);

  useEffect(() => {
    if (reducerObj && totalCount > 0) {
      setTotalCountUser(totalCount);
    }
  }, [reducerObj, totalCount]);

  useEffect(() => {
    if (csvData) {
      const link = document.createElement("a");
      link.href = csvData;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [csvData]);

  const viewDetail = (type) => {
    var requestParams = {
      userId: selectedRowIndex,
      language: i18n.language
    };
    dispatch(getUserDetail(requestParams, setIsLoading));
    if (type) {
      setOpenUserDialog(false);
    } else {
      setOpenUserDialog(true);
    }
  };

  const downloadCsv = () => {
    const data = {
      language: i18n.language
    };
    api
      .post(`${ADRENA.downloadCsv}`, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((response) => {
        setCsvData(response?.data?.path);
        toast.success(response.data.message);
      })
      .catch(() => {
        ("error");
      });
  };
  const options = {
    count: totalCountUser,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPage: [perPageRows],
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: t("category.rowsPerPage"),
        displayRows: "of"
      },
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`
      }
    },
    onColumnSortChange: (changedColumn, direction) => {
      let sortingOrder = direction === "asc" ? 1 : -1;
      setSortOrder(sortingOrder);
      setSortColumn(changedColumn);
      userList("", "", changedColumn, sortingOrder);
    },

    onChangePage(currentPage) {
      if (currentPage >= pageCheck) {
        if (currentPage === 0) {
          currentPage = currentPage + 1;
        }
        setPageCheck(currentPage);
        page = currentPage + 1;
        userList(page, "", "", "");
      } else {
        if (pageCheck > 0) {
          page = pageCheck - 1;
        }
        setPageCheck(page);
        userList(page, "", "", "");
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      setNumberOfRows(numberOfRows);
      userList("", numberOfRows, "", "");
    }
  };

  const columns = [
    {
      name: "email",
      label: t("login.email"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  maxWidth: 140,
                  fontSize: 14,
                  textAlign: value ? "" : "center"
                }}>
                {value ? value : "-"}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "phone",
      label: t("users.phone"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  maxWidth: 140,
                  fontSize: 14,
                  textAlign: "center"
                }}>
                {value ? value : "-"}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "firstName",
      label: t("user.firstName"),
      options: {
        filter: true,
        sort: false,

        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  width: 100
                }}>
                {value ? value.charAt(0).toUpperCase() + value.slice(1) : "-"}
              </Typography>
            </Tooltip>
          );
        }
      }
    },

    {
      name: "lastName",
      label: t("user.lastName"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  width: 100
                }}>
                {value ? value.charAt(0).toUpperCase() + value.slice(1) : "-"}
              </Typography>
            </Tooltip>
          );
        }
      }
    },

    {
      name: "accountType",
      label: t("user.accountType"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography>
                {value ? value.charAt(0).toUpperCase() + value.slice(1) : "-"}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "companyName",
      label: t("user.companyName"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  width: 100,
                  fontSize: 14
                }}>
                {value ? value : "-"}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "address",
      label: t("user.address"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  width: 140,
                  fontSize: 14
                }}>
                {value ? value : "-"}
              </Typography>
            </Tooltip>
          );
        }
      }
    },

    {
      name: "loginType",
      label: t("user.loginType"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  maxWidth: 140,
                  fontSize: 14
                }}>
                {value ? value.charAt(0).toUpperCase() + value.slice(1) : "-"}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    // {
    //   name: "badgeVerified",
    //   label: t("user.badgeVerified"),
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: (value) => {
    //       return (
    //         <Tooltip title={value}>
    //           <Typography
    //             style={{
    //               overflow: "hidden",
    //               textOverflow: "ellipsis",
    //               display: "-webkit-box",
    //               WebkitLineClamp: 2,
    //               WebkitBoxOrient: "vertical",
    //               maxWidth: 140,
    //               fontSize: 14
    //             }}>
    //             {value === true ? "Yes" : "No"}
    //           </Typography>
    //         </Tooltip>
    //       );
    //     }
    //   }
    // },

    // {
    //   name: "tagsByAdmin",
    //   label: t("users.label"),
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: (value) => {
    //       return (
    //         <Tooltip title={value}>
    //           <Typography
    //             style={{
    //               overflow: "hidden",
    //               textOverflow: "ellipsis",
    //               display: "-webkit-box",
    //               WebkitLineClamp: 2,
    //               WebkitBoxOrient: "vertical",
    //               maxWidth: 100,
    //               fontSize: 14
    //             }}>
    //             {value.length >= 1 ? value[0]?.charAt(0).toUpperCase() + value[0]?.slice(1) : "-"}
    //           </Typography>
    //         </Tooltip>
    //       );
    //     }
    //   }
    // },
    {
      name: "allProducts",
      label: t("user.totalAds"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  maxWidth: 80,
                  fontSize: 14
                }}>
                {value ? value : 0}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "activeProducts",
      label: t("user.activeAds"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  maxWidth: 80,
                  fontSize: 14
                }}>
                {value ? value : 0}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "draftProducts",
      label: t("user.draftAds"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  maxWidth: 80,
                  fontSize: 14
                }}>
                {value ? value : 0}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "approveProducts",
      label: t("user.approvedAds"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  maxWidth: 80,
                  fontSize: 14
                }}>
                {value ? value : 0}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "declineProducts",
      label: t("user.declineAds"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  maxWidth: 80,
                  fontSize: 14
                }}>
                {value ? value : 0}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "soldProducts",
      label: t("user.soldAds"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  maxWidth: 80,
                  fontSize: 14
                }}>
                {value ? value : 0}
              </Typography>
            </Tooltip>
          );
        }
      }
    },

    {
      name: "pauseProducts",
      label: t("user.pauseAds"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  maxWidth: 80,
                  fontSize: 14
                }}>
                {value ? value : 0}
              </Typography>
            </Tooltip>
          );
        }
      }
    },

    {
      name: "diableProducts",
      label: t("user.disableAds"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  maxWidth: 80,
                  fontSize: 14
                }}>
                {value ? value : 0}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "processingProducts",
      label: t("user.processingAds"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  maxWidth: 80,
                  fontSize: 14
                }}>
                {value ? value : 0}
              </Typography>
            </Tooltip>
          );
        }
      }
    },

    {
      name: "",
      label: t("user.active"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowObj = userListData[tableMeta.rowIndex];
          return (
            <>
              <Switch
                name="checkedA"
                inputProps={{ "aria-label": "primary checkbox" }}
                color="primary"
                checked={rowObj.status === "Suspended" ? false : true}
                onChange={() => handleToggle(tableMeta)}
              />
            </>
          );
        }
      }
    },

    {
      name: "createdAt",
      label: t("category.createdAt"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          var localTime = moment.utc(value).toDate();
          return <div style={{ alignItems: "center" }}>{getMomentFormat(localTime)}</div>;
        }
      }
    },
    {
      name: "id",
      label: t("category.action"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (dataIndex) => {
          return (
            <>
              <TableActionButton
                style={{ marginLeft: 10 }}
                onClick={(event) => handleClick(event, dataIndex)}
              />
              {optionMenu()}
            </>
          );
        }
      }
    }
  ];

  const userList = (page, numberOfRows, sortingColumnValue, sortingOrder) => {
    var data = {
      page: page ? page : 1,
      limit: numberOfRows ? numberOfRows : perPageRows,
      search: searchInputRef.current.value,
      sort: {
        sortField: sortingColumnValue !== "" ? sortingColumnValue : sortColumn,
        sortIn: sortingOrder !== "" ? sortingOrder : sortOrder
      }
    };
    let isSearch = searchInputRef.current.value ? true : false;
    dispatch(getUserList(data, isSearch, setIsLoading));
  };

  const saveFilter = (page, numberOfRows) => {
    var data = {
      page: page ? page : 1,
      limit: numberOfRows ? numberOfRows : perPageRows,
      search: searchInputRef.current.value,
      accountType: accountType,
      badgeVerified: badge,
      region: address,
      minRange: noOfAdds[0],
      maxRange: noOfAdds[1],
      adStatus: adStatus,
      lastLogin: lastLogin,
      language: i18n.language
    };

    dispatch(getUserList(data, setIsLoading, setOpen));
  };

  const handleClick = (event, dataIndex) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowIndex(dataIndex);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length > 2) {
      userList();
    } else if (e.target.value.length === 0) {
      userList();
    }
  };

  const clearSearch = () => {
    setSearchValue("");
    var data = {
      page: 1,
      limit: 10,
      search: ""
    };
    let isSearch = false;

    dispatch(getUserList(data, isSearch, setIsLoading));
  };

  const handleToggle = (rowValue) => {
    setSelectedIndex(rowValue.rowIndex);
    if (alertDialogRemoveOpen) {
      setAlertDialogRemoveOpen(false);
    } else {
      setAlertDialogRemoveOpen(true);
    }
  };

  const optionMenu = () => {
    return (
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            navigate(`/users/addUser/${selectedRowIndex}`);
          }}>
          {t("users.edit")}
        </MenuItem>

        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            viewDetail();
          }}>
          {t("user.userDetail")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setUserRatingDialog(true);
            viewDetail("rating");
          }}>
          {t("users.rating")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setUserLabelDialog(true);
            viewDetail("lebel");
          }}>
          {t("users.label")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setAlertDialogRemoveOpen1(true);
          }}>
          {t("category.delete")}
        </MenuItem>
      </Menu>
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const userDeleteButton = () => {
    const data = {
      userId: selectedRowIndex,
      language: i18n.language
    };
    dispatch(userDelete(data, setIsLoading));
  };
  const suspendAccountSubmit = () => {
    const rowObj = userListData[selectedIndex];
    var data = {
      userId: rowObj.id,
      status: rowObj.status === "Suspended" ? 1 : 2,
      language: i18n.language
    };
    dispatch(suspendAccount(data, setIsLoading, userList));
  };

  const badgeData = (badgeData) => {
    setBadge(badgeData);
  };

  const accountData = (accountData) => {
    setAccountType(accountData);
  };
  const addressData = (addressData) => {
    setAddress(addressData);
  };
  const noOfAddsData = (noOfAddsData) => {
    setNoOfAdds(noOfAddsData);
  };

  const adStatusData = (adStatusData) => {
    setAdStatus(adStatusData);
  };

  const lastLoginData = (lastLoginData) => {
    setLastLogin(lastLoginData);
  };
  // upload csv

  const handleUploadCsv = (event) => {
    setIsLoading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    api
      .post(`${ADRENA?.uploadUserCsv}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((response) => {
        if (response.data?.status) {
          userList();
          toast.success(response.data.message);
        } else {
          setIsLoading(false);
          toast.error(response.data.message);
        }
      })
      .catch(() => {
        setIsLoading(false)("error");
      });
  };

  //  handle download demo csv

  // const handleDownloadDemoCsv = () => {
  //   const link = document.createElement("a");
  //   link.href = demoCSV;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };
  return (
    <Grid container>
      <Grid
        container
        sx={{
          border: " 1px solid rgba(224, 224, 224, 1)",
          p: 2,
          backgroundColor: "white"
        }}>
        <Grid sx={{ mt: 1 }}>
          <Typography variant="body1" sx={{ fontSize: 18 }} color="secondary.contrastText">
            {t("users.title")}
          </Typography>
        </Grid>
        <Grid container>
          <Grid xs={6} sm={6} md={3} lg={3}>
            <AdrenaInput
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  userList("");
                }
              }}
              inputRef={searchInputRef}
              title={t("user.searchUser")}
              type="email"
              endIcon={
                searchValue && (
                  <Icon
                    icon="material-symbols:cancel"
                    color="red"
                    onClick={() => clearSearch()}
                    style={{ cursor: "pointer" }}
                  />
                )
              }
              onChange={handleSearchChange}
              value={searchValue}
            />
          </Grid>

          <Grid xs={6} sm={6} md={1} lg={1} sx={{ mt: 2 }}>
            <FilterDialog
              ShowDialog={open}
              badgeData={badgeData}
              accountData={accountData}
              addressData={addressData}
              noOfAddsData={noOfAddsData}
              adStatusData={adStatusData}
              lastLoginData={lastLoginData}
              userlist={userList}
              onClick={() => {
                saveFilter();
              }}
              setShowDialog={setOpen}
            />

            <AdminButton
              title={t("user.filter")}
              startIcon={<Icon icon="clarity:filter-solid" color="white" />}
              onClick={() => setOpen(true)}
              sx={{ height: 41, ml: 1, mt: 0.2, justifyContent: "center" }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={2} lg={2} sx={{ display: isMobile ? "flex" : "" }}>
            &nbsp;
            <AdminButton
              title={t("user.downloadCsv")}
              startIcon={<Icon icon="material-symbols:download" color="white" />}
              onClick={downloadCsv}
              sx={{ height: 41, mt: isMobile ? "" : 2.2 }}
            />
            {isMobile && (
              <>
                &nbsp; &nbsp;
                <AdminButton
                  title={t("users.addUser")}
                  onClick={() => {
                    dispatch(clearUserDetail(navigate));
                  }}
                  sx={{ height: 41, mt: 0.2 }}
                />
              </>
            )}
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
            {/* <AdminButton
              title={t("user.downloadDemoCsv")}
              startIcon={<Icon icon="material-symbols:upload-sharp" color="white" />}
              onClick={handleDownloadDemoCsv}
              sx={{ height: 41, mt: 0.2 }}
            />{" "}
            &nbsp; &nbsp; */}
            <AdminButton
              title={t("user.uploadCsv")}
              startIcon={<Icon icon="material-symbols:upload-sharp" color="white" />}
              onClick={() => uploadCsv?.current?.click()}
              sx={{ height: 41, mt: 0.2 }}
            />
            <input
              ref={uploadCsv}
              style={{ display: "none", cursor: "pointer" }}
              type="file"
              accept=".csv"
              onChange={handleUploadCsv}
            />
            {!isMobile && (
              <>
                &nbsp; &nbsp;
                <AdminButton
                  title={t("users.addUser")}
                  onClick={() => {
                    dispatch(clearUserDetail(navigate));
                  }}
                  sx={{ height: 41, mt: 0.2 }}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      {isLoading ? (
        <>
          <Grid
            container
            sx={{
              border: "1px solid rgba(224, 224, 224, 1)",
              mt: 2,
              height: 300,
              backgroundColor: "white"
            }}>
            <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}></Grid>
          </Grid>
        </>
      ) : (
        <>
          {userListData && userListData.length > 0 ? (
            <Grid container>
              <Grid xs={12}>
                <MUIDataTable data={userListData} columns={columns} options={options} />
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid
                container
                sx={{
                  border: "1px solid rgba(224, 224, 224, 1)",
                  mt: 2,
                  height: 300,
                  backgroundColor: "white"
                }}>
                <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
                  <Typography sx={{ fontSize: 24, fontWeight: 500, mt: "7.5rem" }}>
                    {t("category.noRecordFound")}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
      {alertDialogRemoveOpen1 && (
        <AlertDialog
          open={alertDialogRemoveOpen1}
          title="Warning"
          text="Are you sure to delete this user?"
          handleClose={setAlertDialogRemoveOpen1}
          handleAgree={() => {
            userDeleteButton();
          }}
        />
      )}
      {alertDialogRemoveOpen && (
        <AlertDialog
          open={alertDialogRemoveOpen}
          title="Warning"
          text={
            userListData[selectedIndex]?.status === "Suspended"
              ? "Are you sure to active this account?"
              : "Are you sure to suspend this account?"
          }
          handleClose={setAlertDialogRemoveOpen}
          handleAgree={() => {
            suspendAccountSubmit();
          }}
        />
      )}
      <UserRatingDialog
        showDialog={userRatingDialog}
        setShowDialog={setUserRatingDialog}
        userId={selectedRowIndex}
        userDetaiData={getUserDetailData?.data}
      />
      <UserLabelDialog
        showDialog={userLabelDialog}
        setShowDialog={setUserLabelDialog}
        userId={selectedRowIndex}
        userDetaiData={getUserDetailData?.data}
      />
      <UserDetailDialog
        userDetaiData={getUserDetailData?.data}
        ShowDialog={opeUserDialog}
        setShowDialog={setOpenUserDialog}
      />
      {isLoading && <Loader open={isLoading} text={t("adrena.pleaseWait")} />}
    </Grid>
  );
}
