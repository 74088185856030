import {
  Autocomplete,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  RadioGroup
} from "@mui/material";
import { Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { Radio } from "@mui/material";
import { TextField } from "@mui/material";
import AdminButton from "components/adminButton";
import AdrenaInput from "components/adrenaInput";
import { getUserList } from "store";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendNotificationToUser } from "store";
import { updateNotificationStatus } from "store";
import InputEmojiWithRef from "react-input-emoji";
import "./index.css";
import { toast } from "react-toastify";
export default function Notification() {
  useEffect(() => {
    userList();
  }, []);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [note, setNote] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [isSentToAll, setIsSentToAll] = useState(false);
  const [title, setTitle] = useState("");
  const [userId, setUserId] = useState([]);
  const { userListData, sendNotificationStatus } = useSelector((state) => state.userSlice);
  const [formError, setFormError] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    if (sendNotificationStatus) {
      setNote("");
      setTitle("");
      handleuserIdArray([]);
      dispatch(updateNotificationStatus());
    }
  });

  const userList = (search) => {
    var data = {
      language: i18n?.language,
      limit: 100,
      page: 1,
      search: search
    };
    dispatch(getUserList(data, "", ""));
  };
  const handleuserIdArray = (array) => {
    let temp = [];
    if (array.length >= 1) {
      array.map((data) => {
        temp.push(data.id);
        setUserId(temp);
      });
      setFormError((formError) => ({
        ...formError,
        userError: ""
      }));
    } else {
      setUserId(array);
    }
  };

  const handleReset = () => {
    setIsSentToAll(false);
    setShowInput(false);
    setTitle("");
    setNote("");
    setFormError({});
  };

  const handleSend = () => {
    if (!isSentToAll && !showInput) {
      setFormError((formError) => ({
        ...formError,
        sendToError: t("notification.sendToError")
      }));
      return;
    }

    if (!isSentToAll && userId.length < 1) {
      setFormError((formError) => ({
        ...formError,
        userError: t("notification.userError")
      }));
      return;
    }
    if (!title) {
      setFormError((formError) => ({
        ...formError,
        titleError: t("notification.titleError")
      }));
      return;
    }
    if (!note) {
      toast.error(t("notification.noteError"));
      return;
    }
    let data = {
      userIds: isSentToAll ? [] : userId,
      isSentToAll: isSentToAll,
      title: title,
      message: note,
      language: i18n?.language
    };
    dispatch(sendNotificationToUser(data));
  };
  return (
    <>
      <Grid container sx={{ mb: 5 }}>
        <Grid>
          <Typography sx={{ fontSize: 18, fontWeight: 700, color: "#212b36" }}>
            {t("notification.heading")}
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 400, color: "#555" }}>
            {t("notification.subHeading")}
          </Typography>
        </Grid>
      </Grid>

      <Container
        maxWidth="xxl"
        sx={{
          border: " 1px solid rgba(224, 224, 224, 1)",
          background: "white",
          p: { xs: 2, sm: 2, md: 5, lg: 5 },
          px: 25,
          borderRadius: 1
        }}>
        <Box>
          <RadioGroup
            onChange={(event) => {
              if (event.target.value === "All") {
                setIsSentToAll(true);
                setShowInput(false);
                setFormError((formError) => ({
                  ...formError,
                  sendToError: ""
                }));
              } else {
                setIsSentToAll(false);
                setShowInput(true);
                setFormError((formError) => ({
                  ...formError,
                  sendToError: ""
                }));
              }
            }}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group">
            <FormControlLabel
              value="All"
              control={<Radio />}
              label={
                <Typography sx={{ pt: 0.1, fontSize: 17, fontWeight: 700, mr: 5, color: "black" }}>
                  {t("notification.all")}
                </Typography>
              }
            />
            <FormControlLabel
              value="ChooseUser"
              control={<Radio />}
              label={
                <Typography sx={{ pt: 0.1, fontSize: 17, fontWeight: 700, color: "black" }}>
                  {t("notification.chooseUser")}
                </Typography>
              }
            />
          </RadioGroup>
          <Typography sx={{ color: "red", fontSize: 15, pt: 1 }}>
            {formError?.sendToError ? formError?.sendToError : ""}
          </Typography>
          {showInput && (
            <>
              <Autocomplete
                sx={{
                  pt: 1,
                  "& .MuiChip-label": {
                    color: "black"
                  },
                  "& .MuiChip-deleteIcon": {
                    color: "black"
                  }
                }}
                multiple
                id="checkboxes-tags-demo"
                options={userListData ? userListData : []}
                disableCloseOnSelect
                getOptionLabel={(option) => option.email}
                onChange={(event, values) => {
                  handleuserIdArray(values);
                  userList("");
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    <Typography sx={{ color: "black", pt: 0.8 }}> {option.email}</Typography>
                  </li>
                )}
                style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(event) => {
                      if (event?.target.value?.length >= 1) {
                        userList(event.target.value);
                      } else {
                        userList("");
                      }
                    }}
                  />
                )}
              />
              <Typography sx={{ color: "red", fontSize: 15, pt: 1 }}>
                {formError?.userError ? formError?.userError : ""}
              </Typography>
            </>
          )}
          <Grid item xs={12}>
            <Typography
              sx={{
                mb: -1,
                color: "#212b36",
                fontWeight: 500,
                fontSize: 14,
                display: "block",
                pt: 3
              }}>
              {t("products.title")}
            </Typography>
            <AdrenaInput
              value={title}
              onChange={(val) => {
                setTitle(val.target.value);
                setFormError((formError) => ({
                  ...formError,
                  titleError: ""
                }));
              }}
              formError={formError.titleError}
            />
          </Grid>
          <Box
            sx={{
              border: "1px solid #82848a",
              p: 2,
              mt: 6,
              lineHeight: 1
            }}>
            <InputEmojiWithRef
              value={note}
              onChange={setNote}
              borderRadius={0}
              shouldReturn
              keepOpened
              placeholder="Type a message"
              language={i18n?.language}
            />
          </Box>
        </Box>
        <Grid container sx={{ mt: 2 }}>
          <AdminButton
            title={t("button.send")}
            onClick={handleSend}
            sx={{ width: 150, mr: 1 }}
            // isLoading={isLoading}
          />
          <AdminButton
            sx={{ bgcolor: "black", "&:hover": { bgcolor: "#757575" }, width: 150 }}
            title={t("button.reset")}
            onClick={handleReset}
          />
        </Grid>
      </Container>
    </>
  );
}
