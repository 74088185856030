import React from "react";

import Typography from "@mui/material/Typography";
import { Container, Stack } from "@mui/material";

export default function Settings() {
  return (
    <Container maxWidth="xl">
      <>
        <Stack direction="column" justifyContent="center" alignItems="center">
          <Typography
            component="div"
            color="secondary.contrastText"
            sx={{ my: 1, fontSize: "3em", mt: 25 }}>
            Coming Soon
          </Typography>
        </Stack>
      </>
    </Container>
  );
}
