import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../services";
import { ADRENA } from "../../services/apiConstant";

// Slice
const slice = createSlice({
  name: "userSlice",
  initialState: {
    categoryListData: null,
    candidateListCount: null,
    userListData: null,
    userListCount: null,
    getCategoryDetailData: null,
    suspendAccountData: null,
    addCategoryData: null,
    subCategoryListData: null,
    addSubCategoryData: null,
    editCategoryData: null,
    subCategoryDetailData: null,
    getAdminProfileDetailData: null,
    updateCategoryData: null,
    changePasswordData: null,
    getUserDetailData: null,
    addressListData: [],
    subCateogryIdData: null,
    adressData: null,
    sendNotificationStatus: false
  },
  reducers: {
    addCategorySuccess: (state, action) => {
      state.addCategoryData = action.payload;
    },
    addCategoryFailed: (state, action) => {
      state.errorMessage = action.payload;
    },
    updateCategorySuccess: (state, action) => {
      state.editCategoryData = action.payload;
    },
    updateCategoryFailed: (state, action) => {
      state.errorMessage = action.payload;
    },
    addSubCategorySuccess: (state, action) => {
      state.addSubCategoryData = action.payload;
    },
    addSubCategoryFailed: (state, action) => {
      state.errorMessage = action.payload;
    },

    categoryListSuccess: (state, action) => {
      if (action.payload.page === 1) {
        state.categoryListData = [];
      }
      state.categoryListData = action.payload.data; //[...state.categoryListData, ...action.payload.data];
      state.totalCount = action.payload.totalItems;
    },
    subCategoryListSuccess: (state, action) => {
      if (action.payload.page === 1) {
        state.subCategoryListData = [];
      }
      state.subCategoryListData = action.payload.data; //[...state.subCategoryListData, ...action.payload.data];
      state.totalCount = action.payload.totalCount;
    },
    subCategoryListFailed: (state, action) => {
      state.errorMessage = action.payload;
    },
    deleteCategorySuccess: (state, action) => {
      let deleteIndexId = action.payload.deleteIndexId;
      state.categoryListData = state.categoryListData.filter((item) => item.id !== deleteIndexId);
    },
    deleteUserSuccess: (state, action) => {
      let deleteIndexId = action.payload.deleteIndexId;
      state.userListData = state.userListData.filter((item) => item.id !== deleteIndexId);
    },
    deleteSubCategorySuccess: (state, action) => {
      let deleteIndexId = action.payload.deleteIndexId;
      state.subCategoryListData = state.subCategoryListData.filter(
        (item) => item.id !== deleteIndexId
      );
    },
    categoryDetailSuccess: (state, action) => {
      state.getCategoryDetailData = action.payload;
    },
    categoryDetailFailed: (state, action) => {
      state.errorMessage = action.payload;
    },
    userDetailSuccess: (state, action) => {
      state.getUserDetailData = action.payload;
    },
    clearUserDetailSuccess: (state, action) => {
      state.getUserDetailData = action.payload;
    },
    userDetailFailed: (state, action) => {
      state.errorMessage = action.payload;
    },
    adminProfileDetailSuccess: (state, action) => {
      state.getAdminProfileDetailData = action.payload;
    },
    adminProfileDetaiFailed: (state, action) => {
      state.errorMessage = action.payload;
    },
    subCategoryDetailSuccess: (state, action) => {
      state.subCategoryDetailData = action.payload;
    },
    subCategoryDetailFailed: (state, action) => {
      state.errorMessage = action.payload;
    },
    updateAdminProfileSuccess: (state, action) => {
      state.updateCategoryData = action.payload;
    },
    updateAdminProfileFailed: (state, action) => {
      state.errorMessage = action.payload;
    },

    changePasswordSuccess: (state, action) => {
      state.changePasswordData = action.payload;
    },
    changePasswordFailed: (state, action) => {
      state.errorMessage = action.payload;
    },
    userListSuccess: (state, action) => {
      if (action.payload.page === 1) {
        state.userListData = [];
      }
      state.userListData = action.payload.data;
      state.totalCount = action.payload.count;
    },
    userListFailed: (state, action) => {
      state.errorMessage = action.payload;
    },
    addressSearchSuccess: (state, action) => {
      state.addressListData = action.payload;
    },
    clearAddressSearchSuccess: (state, action) => {
      state.addressListData = action.payload.data;
    },
    addressPlacedSuccess: (state, action) => {
      state.adressData = action.payload;
    },
    suspendAccoutSuccess: (state, action) => {
      state.suspendAccountData = action.payload;
    },
    sendNotificationToUserSuccess: (state, action) => {
      state.sendNotificationStatus = action.payload;
    },
    updateNotificationStatusSuccess: (state) => {
      state.sendNotificationStatus = false;
    },
    rateAndTagByAdminSuccess: (state, action) => {
      let index = state.userListData.findIndex((item) => item.id == action.payload.id);
      state.userListData[index] = action.payload;
    },
    getSubCategoryByIdSuccess: (state, action) => {
      state.subCateogryIdData = action.payload.data;
    },
    updateCategoryStatusSuccess: (state, action) => {
      let index = state.categoryListData.findIndex((item) => item.id === action.payload.id);
      state.categoryListData[index] = action.payload;
    }
  }
});

const {
  rateAndTagByAdminSuccess,
  updateNotificationStatusSuccess,
  sendNotificationToUserSuccess,
  categoryListSuccess,
  categoryListFailed,
  subCategoryListSuccess,
  subCategoryListFailed,
  addSubCategorySuccess,
  addSubCategoryFailed,
  categoryDetailSuccess,
  categoryDetailFailed,
  suspendAccoutSuccess,
  suspendAccoutFailed,
  addCategorySuccess,
  addCategoryFailed,
  deleteCategorySuccess,
  deleteSubCategorySuccess,
  updateCategorySuccess,
  updateCategoryFailed,
  subCategoryDetailSuccess,
  subCategoryDetailFailed,
  adminProfileDetailSuccess,
  adminProfileDetaiFailed,
  updateAdminProfileSuccess,
  updateAdminProfileFailed,
  changePasswordSuccess,
  changePasswordFailed,
  userListSuccess,
  userListFailed,
  addressSearchSuccess,
  clearAddressSearchSuccess,
  addressPlacedSuccess,
  deleteUserSuccess,
  userDetailSuccess,
  userDetailFailed,
  getSubCategoryByIdSuccess,
  clearUserDetailSuccess,
  updateCategoryStatusSuccess
} = slice.actions;

export const getCategoryList = (data, setIsLoading) => async (dispatch) => {
  try {
    await api.post(ADRENA.getCategoryList, data).then((response) => {
      if (response) {
        dispatch(
          categoryListSuccess({
            ...response.data,
            page: data.page
          })
        );
      } else {
        dispatch(categoryListFailed(response.error));
        toast.error(response.error);
      }
      setIsLoading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getUserList = (data, isSearch, setIsLoading, setOpen) => async (dispatch) => {
  try {
    await api.post(ADRENA.getUserList, data).then((response) => {
      let result = response.data;
      if (setIsLoading) {
        setIsLoading(false);
      }
      if (result.status) {
        dispatch(
          userListSuccess({
            ...result,
            page: data.page
          })
        );
        if (setOpen) {
          setOpen(false);
        }
      } else {
        dispatch(userListFailed(response.error));
        toast.error(response.error);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getSubCategoryList = (data, setIsLoading) => async (dispatch) => {
  try {
    await api.post(ADRENA.getSubCategoryList, data).then((response) => {
      if (response) {
        dispatch(
          subCategoryListSuccess({
            ...response.data,
            page: data.page
          })
        );
      } else {
        dispatch(subCategoryListFailed(response.error));
        toast.error(response.error);
      }

      setIsLoading(false);
    });
  } catch (e) {
    // setIsLoading(false);
    return console.error(e.message);
  }
};
export const getSubCategoryById = (data) => async (dispatch) => {
  api
    .post(`${ADRENA.getSubCategoryById}`, data)
    .then((response) => {
      let result = response.data;
      if (result) {
        dispatch(getSubCategoryByIdSuccess(response.data));
      } else {
        toast.error();
      }
    })
    .catch(() => {});
};
export const addCategory = (data, navigate, setIsLoading) => async (dispatch) => {
  setIsLoading(true);
  try {
    await api
      .post(ADRENA.addCategory, data, {
        headers: {
          "Content-Type": " application/json"
        }
      })
      .then((response) => {
        console.log(response);
        if (response) {
          dispatch(addCategorySuccess(data));
          toast.success(response.data.message);
          navigate("/categories", { replace: true });
          setIsLoading(false);
        } else {
          dispatch(addCategoryFailed(response.error));
          toast.error(response.data.message);
        }
        setIsLoading(false);
      });
  } catch (e) {
    return console.error(e.message);
  }
};

export const addSubCategory = (data, navigate, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    await api
      .post(ADRENA.addSubCategory, data, {
        headers: {
          "Content-Type": " application/json"
        }
      })
      .then((response) => {
        console.log(response);
        if (response) {
          dispatch(addSubCategorySuccess(data));
          toast.success(response.data.message);
          navigate("/subCategories", { replace: true });
          setLoading(false);
        } else {
          dispatch(addSubCategoryFailed(response.error));
          toast.error(response.data.message);
        }
        setLoading(false);
      });
  } catch (e) {
    return console.error(e.message);
  }
};

export const suspendAccount = (requestParams, setIsLoading, userList) => async (dispatch) => {
  setIsLoading(true);
  try {
    await api.post(ADRENA.suspendAccount, requestParams).then((response) => {
      if (response) {
        console.log("responseStatus == ", response);
        dispatch(suspendAccoutSuccess(response));
        setIsLoading(false);
        toast.success(response.data.message);
        userList();
      } else {
        dispatch(suspendAccoutFailed(response.error));
      }
      setIsLoading(false);
    });
  } catch (e) {
    setIsLoading(false);
    return console.error(e.message);
  }
};

export const deleteCategory = (requestParams) => async (dispatch) => {
  try {
    await api.delete(ADRENA.deleteCategory + requestParams.deleteCategoryId).then((response) => {
      if (response) {
        let id = requestParams.deleteCategoryId.toString();
        dispatch(
          deleteCategorySuccess({
            deleteIndexId: id
          })
        );
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const userDelete = (requestParams, setIsLoading) => async (dispatch) => {
  setIsLoading(true);
  try {
    await api.post(ADRENA.deleteUser, requestParams).then((response) => {
      if (response) {
        let id = requestParams.userId.toString();
        dispatch(
          deleteUserSuccess({
            deleteIndexId: id
          })
        );
        toast.success(response.data.message);
      }
      setIsLoading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const deleteSubCategory = (requestParams) => async (dispatch) => {
  try {
    await api
      .delete(ADRENA.deleteSubCategory + requestParams.deleteSubCategoryId)
      .then((response) => {
        if (response) {
          let id = requestParams.deleteSubCategoryId.toString();
          dispatch(
            deleteSubCategorySuccess({
              deleteIndexId: id
            })
          );
        }
      });
  } catch (e) {
    return console.error(e.message);
  }
};
export const updateCategory = (requestParams, navigate, setIsLoading, t) => async (dispatch) => {
  if (setIsLoading) {
    setIsLoading(true);
  }

  try {
    await api.post(ADRENA.updateCategory, requestParams).then((response) => {
      if (response) {
        dispatch(updateCategorySuccess(response.data.data));
        if (t) {
          toast.success(t("category.statusUpdateSuccesfully"));
          dispatch(updateCategoryStatusSuccess(response.data.data));
        }
        if (navigate) {
          navigate("/categories", { replace: true });
        }
        if (setIsLoading) {
          setIsLoading(false);
        }
      } else {
        dispatch(updateCategoryFailed(response.error));
      }
      setIsLoading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const updateAminProfile = (requestParams) => async (dispatch) => {
  try {
    await api.post(ADRENA.updateAminProfile, requestParams).then((response) => {
      if (response) {
        dispatch(updateAdminProfileSuccess(response.data.data));
        toast.success(response.data.message);
        // navigate("/dashboard/categories", { replace: true });
      } else {
        dispatch(updateAdminProfileFailed(response.error));
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const updateSubCategory = (requestParams, navigate, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    await api.post(ADRENA.updateSubCategory, requestParams).then((response) => {
      if (response) {
        dispatch(updateCategorySuccess(response.data.data));
        navigate("/subCategories", { replace: true });
        setLoading(false);
      } else {
        dispatch(updateCategoryFailed(response.error));
      }
      setLoading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const changePassword = (requestParams) => async (dispatch) => {
  try {
    await api.post(ADRENA.changePassword, requestParams).then((response) => {
      if (response) {
        dispatch(changePasswordSuccess(response.data.data));
        toast.success(response.data.message);
      } else {
        dispatch(changePasswordFailed(response.error));
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getCategoryDetail = (requestParams) => async (dispatch) => {
  try {
    await api.get(ADRENA.getCategoryDetail + requestParams).then((response) => {
      if (response) {
        let result = response.data;
        if (result) {
          dispatch(categoryDetailSuccess(result));
        }
      } else {
        dispatch(categoryDetailFailed(response.error));
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getUserDetail = (requestParams, setIsLoading) => async (dispatch) => {
  try {
    await api.post(ADRENA.getUserDetail, requestParams).then((response) => {
      if (response) {
        let result = response.data;
        if (result) {
          dispatch(userDetailSuccess(result));
        }
      } else {
        dispatch(userDetailFailed(response.error));
      }
      setIsLoading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const rateAndTagByAdmin = (requestParams, setIsLoading, message) => async (dispatch) => {
  try {
    await api.post(ADRENA.rateAndTagByAdmin, requestParams).then((response) => {
      if (response) {
        let result = response.data;
        if (result.status) {
          dispatch(rateAndTagByAdminSuccess(result.data));
          toast.success(message);
        }
      } else {
        toast.error(response.data.message);
      }
      setIsLoading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const addEditUserByAdmin = (requestParams, setIsLoading, navigate) => async () => {
  try {
    await api.post(ADRENA.addEditUserByAdmin, requestParams).then((response) => {
      if (response) {
        let result = response.data;
        console.log("result", result);
        if (result.status) {
          // dispatch(userDetailSuccess(result));
          navigate("/users");
        } else {
          toast.error(response.data.message);
        }
      } else {
        toast.error(response.data.message);
      }
      setIsLoading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const getAdminProfileDetail = () => async (dispatch) => {
  try {
    await api.get(ADRENA.getAdminProfileDetail).then((response) => {
      if (response) {
        let result = response.data;
        if (result) {
          dispatch(adminProfileDetailSuccess(result));
        }
      } else {
        dispatch(adminProfileDetaiFailed(response.error));
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const getSunCategoryDetail = (requestParams) => async (dispatch) => {
  try {
    await api.get(ADRENA.getSubCategoryDetail + requestParams).then((response) => {
      if (response) {
        let result = response.data;
        if (result) {
          dispatch(subCategoryDetailSuccess(result));
        }
      } else {
        dispatch(subCategoryDetailFailed(response.error));
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};

export const addressSearch = (data) => async (dispatch) => {
  api
    .post(`${ADRENA.addressSearch}`, data)
    .then((response) => {
      if (response?.data?.status) {
        dispatch(addressSearchSuccess(response.data.data.predictions));
      } else {
        toast.error();
      }
    })
    .catch(() => {});
};
export const clearAddressSearch = (data) => (dispatch) => {
  dispatch(clearAddressSearchSuccess(data));
};

export const addressPlaced = (data) => async (dispatch) => {
  api
    .post(`${ADRENA.addressPlaced}`, data)
    .then((response) => {
      if (response.data.status) {
        dispatch(addressPlacedSuccess(response.data.data.result));
      } else {
        toast.error();
      }
    })
    .catch(() => {});
};

export const sendNotificationToUser = (data) => async (dispatch) => {
  api
    .post(`${ADRENA.sendNotificationToUser}`, data)
    .then((response) => {
      if (response?.data?.status) {
        dispatch(sendNotificationToUserSuccess(response.data.status));
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
    })
    .catch(() => {});
};
export const updateNotificationStatus = () => (dispatch) => {
  dispatch(updateNotificationStatusSuccess());
};
export const clearUserDetail = (navigate) => (dispatch) => {
  dispatch(clearUserDetailSuccess(null));
  navigate("/users/addUser");
};
export default slice.reducer;
