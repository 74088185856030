/** @format */

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  setCss: {
    width: "calc(100vw - 1px)",
    flexGrow: "1",
    minHeight: "100vh",
    backgroundColor: "black",
    paddingTop: "5%",
    paddingBottom: "5%"
  }
}));
export default useStyles;
