import { createSlice } from "@reduxjs/toolkit";
import api from "../../services";
import { ADRENA } from "../../services/apiConstant";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";

// create slice
// Slice

const slice = createSlice({
  name: "authSlice",
  initialState: {
    loginData: null,
    isLoading: false
  },
  reducers: {
    apiFetching: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, action) => {
      state.loginData = action.payload.data;
      state.isLoading = false;
    },
    loginFailed: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    },
    forgotPasswordSuccess: (state, action) => {
      state.loginData = action.payload.data;
      state.isLoading = false;
    },
    forgotPasswordFailure: (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    }
  }
});

const { loginSuccess, loginFailed } = slice.actions;
const { apiFetching, forgotPasswordSuccess, forgotPasswordFailure } = slice.actions;

export const adminLogin = (data, navigate) => async (dispatch) => {
  dispatch(apiFetching());
  try {
    await api.post(ADRENA.loginApi, data).then((response) => {
      console.log(response.data);
      let result = response.data;
      if (result.status) {
        secureLocalStorage.setItem(process.env.REACT_APP_TOKEN_STORAGE_KEY, result.token);
        secureLocalStorage.setItem(
          process.env.REACT_APP_USER_STORAGE_KEY,
          JSON.stringify(result.data)
        );

        dispatch(loginSuccess(result.data));
        navigate("/", { replace: true });
      } else {
        dispatch(loginFailed(response.data));
        toast.error(response.data.message);
      }
    });
  } catch (e) {
    return toast.error(e.message);
  }
};

export const forgotPasswordAdmin = (data, navigate) => async (dispatch) => {
  console.log(data);
  try {
    await api.post(ADRENA.forgotPassword, data).then((response) => {
      if (response) {
        console.log("response == ", response);
        if (response.data.status === true) {
          console.log("true");
          dispatch(forgotPasswordSuccess(response.data));
          toast.success(response.data.message);
          navigate("/login", { replace: true });
        } else {
          console.log("false");
          dispatch(forgotPasswordFailure(response.error));
          toast.error(response.data.message);
        }
      }
    });
  } catch (e) {
    return toast.error(e.message);
  }
};

export default slice.reducer;
