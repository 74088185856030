import { Icon } from "@iconify/react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { actionFont, boldFont } from "components/style";
import AlertDialog from "..";
import AddCreditCardModal from "./addCreaditCardModel";
import { removeCreditCard } from "store";
export default function CreditCardList(props) {
  const { setInputs, inputs, setCardError, cardError, setCardData, data, cardData } = props;
  var theme = useTheme();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [ShowDialog, setShowDialog] = useState(false);
  const [cardId, setCardId] = useState("");
  const handleDelete = () => {
    let request = {
      paymentMethodId: cardId,
      language: i18n?.language
    };
    dispatch(removeCreditCard(request, setShowDialog));
  };
  return (
    <>
      <AlertDialog
        open={ShowDialog}
        title="Warning"
        text={t("Are you sure you want remove this card?")}
        handleClose={setShowDialog}
        handleAgree={handleDelete}
      />
      <Box
        sx={{
          backgroundColor: "#323232",
          mx: isMobile ? 0 : 2,
          px: 2,
          py: 4
        }}>
        <FormControl>
          <RadioGroup
            value={cardData?.creditCardId}
            onChange={(event) => {
              setCardData((cardData) => ({
                ...cardData,
                creditCardId: event.target.value,
                displayNumber:
                  data[data.findIndex((item) => item.id === event.target.value)]?.data
                    ?.display_number
              }));
            }}
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            sx={{ gap: 2.5 }}
            name="radio-buttons-group">
            <Box sx={{ border: "1px solid white", px: 2, pb: 2 }}>
              {data?.map((card, index) => {
                return (
                  <FormControlLabel
                    sx={{ pt: 2 }}
                    key={index}
                    value={card?.id}
                    control={
                      <Radio
                        icon={<Icon icon="clarity:circle-line" color="#e7e9ea" height="22px" />}
                        checkedIcon={
                          <Icon icon="carbon:circle-filled" color="#e7e9ea" height="22px" />
                        }
                      />
                    }
                    label={
                      <>
                        <Box
                          sx={{
                            pl: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            width: isMobile ? "auto" : 400
                          }}>
                          <Stack>
                            <Typography style={boldFont}>{card?.data?.holder_name}</Typography>
                            <Typography style={actionFont} sx={{ pt: 0.5 }}>
                              {card?.data?.brand} {card?.data?.display_number}
                            </Typography>
                          </Stack>
                          {/* <IconButton> */}
                          <Icon
                            onClick={() => {
                              setShowDialog(true);
                              setCardId(card?.id);
                            }}
                            icon="mdi:trash"
                            color="red"
                            height="22px"
                            style={{ marginTop: "1rem" }}
                          />
                          {/* </IconButton> */}
                        </Box>
                      </>
                    }
                  />
                );
              })}
            </Box>
            <AddCreditCardModal
              disabled={cardData?.creditCardId ? true : false}
              checkBoxSx={{
                pl: 0.5,
                mt: -3
              }}
              setInputs={setInputs}
              setCardData={setCardData}
              inputs={inputs}
              setCardError={setCardError}
              cardError={cardError}
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </>
  );
}
