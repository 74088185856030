import { createSlice } from "@reduxjs/toolkit";
import api from "../../services";
import { ADRENA } from "../../services/apiConstant";
import { toast } from "react-toastify";

// create slice
// Slice

const slice = createSlice({
  name: "productSlice",
  initialState: {
    productListData: null,
    totalProduct: null,
    reportsAdsList: null,
    totalReportsAdCount: null,
    dashboardData: null,
    imageListData: [],
    imageNameList: [],
    userPlansListData: null,
    productDetails: null,
    paymentCalculationData: null,
    creditCardList: null
  },
  reducers: {
    addApprovalPanelSuccess: (state, action) => {
      if (action.payload.page === 1) {
        state.productListData = [];
      }
      state.productListData = action.payload.data;
      state.totalProduct = action.payload.totalCount;
    },
    updateProductStatusSuccess: (state, action) => {
      let objId = action.payload.data.id;
      console.log("objId", objId);
      const index = state.productListData.findIndex((item) => item.id === objId);
      console.log("index", index);
      state.productListData[index] = action.payload.data;
    },
    getReortAdListSuccess: (state, action) => {
      if (action.payload.page === 1) {
        state.reportsAdsList = [];
      }
      state.reportsAdsList = action.payload.data; //[...state.categoryListData, ...action.payload.data];
      state.totalReportsAdCount = action.payload.totalItems;
    },
    getDashboardDataSuccess: (state, action) => {
      state.dashboardData = action.payload;
    },
    featuredProductSuccess: (state, action) => {
      let index = state.productListData.findIndex((item) => item.id === action.payload.productId);
      state.productListData[index].isFeaturedByAdmin = action.payload.isFeature;
    },
    imagesListSuccess: (state, action) => {
      if (state.imageListData.length < 9) {
        state.imageListData = state.imageListData.concat(action.payload[0].accessUrl);
        state.imageNameList = state.imageNameList.concat(action.payload[0].fileName);
      }
    },
    getImagelistDataSuccess: (state, action) => {
      let index = state.imageNameList.findIndex((item) => item == action.payload.data.fileName);
      if (index < 0) {
        state.imageListData = state.imageListData.concat(action.payload.data.fullPath);
        state.imageNameList = state.imageNameList.concat(action.payload.data.fileName);
      }
    },
    getUserPlansListSuccess: (state, action) => {
      state.userPlansListData = action.payload;
    },
    addProductSuccess: (state, action) => {
      state.productDetails = action.payload;
    },
    paymentCalculationSuccess: (state, action) => {
      state.paymentCalculationData = action.payload;
    },
    getCreditCardListSuccess: (state, action) => {
      state.creditCardList = action.payload;
    },
    removeCreditCardSuccess: (state, action) => {
      state.creditCardList = state.creditCardList.filter((item) => item.id !== action.payload.id);
    },
    clearImageListDataSuccess: (state) => {
      state.imageListData = [];
      state.productDetails = null;
      state.imageNameList = [];
    },
    removeImageDataFromListSuccess: (state, action) => {
      state.imageListData.splice(action.payload, 1);
      state.imageNameList.splice(action.payload, 1);
    },
    changeVisibilityStatusSuccess: (state, action) => {
      let index = state.productListData.findIndex(
        (item) => item.id === action.payload?.productIds[0]
      );
      state.productListData[index].visibilityStatus = action.payload.visibilityStatus;
    },
    getproductDetailSuccess: (state, action) => {
      state.productDetails = action.payload;
    }
  }
});

const {
  addApprovalPanelSuccess,
  updateProductStatusSuccess,
  getReortAdListSuccess,
  getDashboardDataSuccess,
  featuredProductSuccess,
  imagesListSuccess,
  getImagelistDataSuccess,
  getUserPlansListSuccess,
  addProductSuccess,
  paymentCalculationSuccess,
  getCreditCardListSuccess,
  removeCreditCardSuccess,
  clearImageListDataSuccess,
  removeImageDataFromListSuccess,
  changeVisibilityStatusSuccess,
  getproductDetailSuccess
} = slice.actions;

export const addApprovalPanel = (requestParams, setIsLoading) => async (dispatch) => {
  try {
    await api.post(ADRENA.addApprovalPanel, requestParams).then((response) => {
      if (response) {
        dispatch(
          addApprovalPanelSuccess({
            ...response.data,
            page: requestParams.page
          })
        );
      } else {
        toast.error(response.data.message);
      }
      setIsLoading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const updateProductStatus = (requestParams, setIsLoading) => async (dispatch) => {
  setIsLoading(true);
  try {
    await api.post(ADRENA.updateProductStatus, requestParams).then((response) => {
      if (response) {
        let result = response.data;
        if (result) {
          dispatch(updateProductStatusSuccess(result));
        }
      } else {
        toast.error(response.data.message);
      }
      setIsLoading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const featuredProduct = (requestParams) => async (dispatch) => {
  try {
    await api.post(ADRENA.featuredProduct, requestParams).then((response) => {
      if (response) {
        let result = response.data;
        if (result) {
          dispatch(featuredProductSuccess(requestParams));
        }
      } else {
        toast.error(response.data.message);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const getReportsAdsList = (requestParams, setIsLoading) => async (dispatch) => {
  setIsLoading(true);
  try {
    await api.post(ADRENA.getReportsAdsList, requestParams).then((response) => {
      if (response) {
        let result = response.data;
        console.log("result", result);
        if (result) {
          dispatch(
            getReortAdListSuccess({
              ...response.data,
              page: requestParams.page
            })
          );
        }
      } else {
        toast.error(response.data.message);
      }
      setIsLoading(false);
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const getDashboardData = (requestParams) => async (dispatch) => {
  try {
    await api.post(ADRENA.getDashboardData, requestParams).then((response) => {
      if (response) {
        let result = response.data;
        if (result) {
          dispatch(getDashboardDataSuccess(result.data));
        }
      } else {
        toast.error(response.data.message);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
export const imagesList = (data, folderName, language) => (dispatch) => {
  api
    .post(`${ADRENA.imageUpload}/${folderName}?previousFile=search&language=${language}`, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then((response) => {
      let result = response.data;
      if (result.status === 1) {
        dispatch(imagesListSuccess(result.data));
      } else {
        toast.error(response.data.message);
      }
    })
    .catch(() => {});
};
export const getUserPlansList = (data) => async (dispatch) => {
  api
    .post(`${ADRENA.getUserPlansList}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(getUserPlansListSuccess(result.data));
      } else {
        toast.error(response.data.message);
      }
    })
    .catch(() => {});
};
export const addProduct =
  (data, handlePaymentCalculation, navigate, setIsloading) => async (dispatch) => {
    api
      .post(`${ADRENA.addProduct}`, data)
      .then((response) => {
        let result = response.data;
        if (result.status) {
          if (handlePaymentCalculation) {
            handlePaymentCalculation(result.data);
          }

          if (navigate) {
            navigate("/adds");
          }

          dispatch(addProductSuccess(result.data));
        } else {
          toast.error(response.data.message);
        }
        if (setIsloading) {
          setIsloading(false);
        }
      })
      .catch(() => {});
  };
export const getPaymentCalculation =
  (requestParams, setIsLoading, setShowPaymentDialog, isCoupon) => async (dispatch) => {
    try {
      await api.post(ADRENA.getPaymentCalculation, requestParams).then((response) => {
        let result = response.data;
        if (result?.status) {
          dispatch(paymentCalculationSuccess(result.data));
          setIsLoading(false);
          if (!isCoupon) {
            setShowPaymentDialog(true);
          }
        } else {
          setIsLoading(false);
          toast.error(response.data.message);
        }
      });
    } catch (e) {
      return console.error(e.message);
    }
  };
export const getCreditCardList = (data) => async (dispatch) => {
  api
    .post(`${ADRENA.getCreditCardList}`, {
      language: data
    })
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(getCreditCardListSuccess(result.data));
      } else {
        toast.error(response.data.message);
      }
    })
    .catch(() => {});
};
export const removeCreditCard = (data, setShowDialog) => async (dispatch) => {
  api
    .post(`${ADRENA.removeCreditCard}`, data)
    .then((response) => {
      let result = response.data;
      if (result.status) {
        dispatch(removeCreditCardSuccess(result.data));
        setShowDialog(false);
      } else {
        toast.error(response.data.message);
      }
    })
    .catch(() => {});
};
export const addCreditCard = (data) => async () => {
  api
    .post(`${ADRENA.addCreditCard}`, data)
    .then((response) => {
      let result = response.data;
      console.log("result", result);
      if (result.status) {
        // dispatch(getPlansListSuccess(result.data));
      } else {
        toast.error(response.data.message);
      }
    })
    .catch(() => {});
};
export const makePayment =
  (requestParams, adFormData, setIsloading, addproduct, navigate, type, productDetails) =>
  async (dispatch) => {
    try {
      await api.post(ADRENA.makePayment, requestParams).then((response) => {
        let result = response.data;
        if (result?.status) {
          if (result?.invoiceUrl) {
            window.sessionStorage.setItem("paymentData", JSON.stringify(result));
          } else {
            sessionStorage.clear();
          }

          if (adFormData) {
            let data = {
              productId: productDetails?.id,
              language: adFormData?.language,
              subscriptionType: adFormData?.userPlan?.subscriptionType,
              productStatus: type ? (type == "credit_card" ? "approve" : "publish") : "publish"
            };
            const newObj = { ...adFormData, ...data };
            dispatch(addproduct(newObj, "", navigate, setIsloading));
          }
        } else {
          setIsloading(false);
          toast.error(response.data.message);
        }
      });
    } catch (e) {
      return console.error(e.message);
    }
  };

export const clearImageListData = () => async (dispatch) => {
  dispatch(clearImageListDataSuccess());
};

export const removeImageDataFromList = (index) => async (dispatch) => {
  dispatch(removeImageDataFromListSuccess(index));
};

export const changeVisibilityStatus = (requestParams) => async (dispatch) => {
  try {
    await api.post(ADRENA.changeVisibilityStatus, requestParams).then((response) => {
      if (response) {
        let result = response.data;
        if (result.status) {
          dispatch(changeVisibilityStatusSuccess(requestParams));
          toast.success(result?.mssage);
        }
      } else {
        toast.error(response.data.message);
      }
    });
  } catch (e) {
    return console.error(e.message);
  }
};
// get product details

export const getproductDetail = (productId, userId, language, setLoader) => async (dispatch) => {
  api
    .get(`${ADRENA.getproductDetail}/${productId}?userId=${userId}&language=${language}`, {
      headers: {
        key: process.env.REACT_APP_PUBLIC_API_AUTH_KEY
      }
    })
    .then((response) => {
      if (response.data.status) {
        dispatch(getproductDetailSuccess(response.data.data));
        setLoader(false);
      }
    })
    .catch(() => {});
};

// get images from product details
export const getImagelistfromProductDetails = (data) => (dispatch) => {
  dispatch(getImagelistDataSuccess(data));
};
export default slice.reducer;
