import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import { Avatar, DialogActions, DialogContent, Grid, IconButton, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import AdminButton from "components/adminButton";
import logo from "assets/img/noimage.jpeg";

const UserDetailDialog = (props) => {
  const { t } = useTranslation();
  const { ShowDialog, setShowDialog, userDetaiData } = props;
  const onClose = () => {
    setShowDialog(!ShowDialog);
  };

  return (
    <Dialog
      open={ShowDialog}
      PaperProps={{
        style: {
          backgroundColor: "#323232",
          boxShadow: "none",
          width: "578px"
        }
      }}>
      <DialogTitle>
        <Typography
          sx={{
            color: "#E7E9EA",
            fontSize: "22px",
            fontFamily: "setimo",
            fontWeight: 700
          }}>
          {t("user.detail")}
        </Typography>

        <IconButton sx={{ float: "right", mt: -4.8 }} onClick={onClose}>
          <Icon
            icon="system-uicons:cross"
            color="#E7E9EA"
            width={"23.63px"}
            height={"23.63px"}
            style={{ fontWeight: 700 }}
          />
        </IconButton>
      </DialogTitle>
      <Divider light />

      <DialogContent>
        <Grid container spacing={1.5} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <Avatar
              alt="Remy Sharp"
              src={userDetaiData?.profileImagePath ? userDetaiData?.profileImagePath : logo}
              sx={{ width: 100, height: 100, textAlign: "center" }}
            />
          </Grid>
          <Grid container sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>{t("profile.email")} </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>
                {userDetaiData?.email ? userDetaiData?.email : "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>{t("profile.firstName")}</Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>
                {userDetaiData?.firstName ? userDetaiData?.firstName : "-"}
              </Typography>
            </Grid>
          </Grid>

          <Grid container sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>{t("profile.lastName")} </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>
                {userDetaiData?.lastName ? userDetaiData?.lastName : "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>{t("users.companyName")} </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>
                {userDetaiData?.companyName ? userDetaiData?.companyName : "-"}
              </Typography>
            </Grid>
          </Grid>

          <Grid container sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>{t("user.accountType")} </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>
                {userDetaiData?.accountType ? userDetaiData?.accountType : "N/A"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>{t("users.rating")} </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>
                {userDetaiData?.ratingByAdmin}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>{t("users.label")} </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>
                {userDetaiData?.tagsByAdmin?.length >= 1 ? userDetaiData?.tagsByAdmin[0] : "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>{t("user.totalAds")} </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>
                {userDetaiData?.totalAds || userDetaiData?.totalAdCount
                  ? userDetaiData?.totalAds || userDetaiData?.totalAdCount
                  : "0"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>{t("users.userStatus")} </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>
                {userDetaiData?.status ? userDetaiData?.status : "N/A"}
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid container sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>{t("user.badgeVerified")} </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>
                {userDetaiData?.badgeVerified ? t("dialog.btnYes") : t("dialog.btnNo")}
              </Typography>
            </Grid>
          </Grid> */}
          <Grid container sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>
                {t("user.isEmailVerified")}
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>
                {userDetaiData?.isEmailVerified || userDetaiData?.isUserEmailVerified
                  ? t("dialog.btnYes")
                  : t("dialog.btnNo")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>
                {t("user.isPhoneVerified")}{" "}
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>
                {userDetaiData?.isPhoneVerified || userDetaiData?.isUserPhoneVerified
                  ? t("dialog.btnYes")
                  : t("dialog.btnNo")}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>
                {t("user.isWhatsAppVerified")}{" "}
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={{ mb: 1.5, color: "#E7E9EA" }}>
                {userDetaiData?.isWhatsAppVerified || userDetaiData?.isUserWhatsAppVerified
                  ? t("dialog.btnYes")
                  : t("dialog.btnNo")}
              </Typography>
            </Grid>
          </Grid>

          <Divider />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid sx={{ p: 2 }}>
            <AdminButton onClick={() => setShowDialog(false)} title={t("users.ok")} />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default UserDetailDialog;
