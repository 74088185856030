import React, { useEffect } from "react";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import AdminButton from "components/adminButton";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AdrenaInput from "components/adrenaInput";
// import { ADRENA, API_RESPONSE } from "services/apiConstant";
// import api from "services";
import { useDispatch, useSelector } from "react-redux";
// import { useEffect } from "react";
// import { useState } from "react";
import { changePassword, getAdminProfileDetail, updateAminProfile } from "store/slice/users";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function AdminProfile() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formError, setFormError] = useState({});
  const [formData, setFormData] = useState({});
  // const { id } = useParams();
  const [value, setValue] = useState(0);
  const [showOldPassword, setShowOldPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const { getAdminProfileDetailData } = useSelector((state) => state.userSlice);

  // console.log("imageSelected == ",imageSelected)
  var dispatch = useDispatch();

  useEffect(() => {
    adminProfileDetail();
  }, []);

  const adminProfileDetail = () => {
    dispatch(getAdminProfileDetail());
  };

  useEffect(() => {
    if (getAdminProfileDetailData) {
      setFormData(getAdminProfileDetailData?.data);
    }
  }, [getAdminProfileDetailData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validEmail = new RegExp("^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$");
    if (!formData.firstName) {
      setFormError((formError) => ({
        ...formError,
        firstNameError: "Please enter first name"
      }));
      return;
    }
    if (!formData.lastName) {
      setFormError((formError) => ({
        ...formError,
        lastNameError: "Please enter last name"
      }));
      return;
    }
    if (!formData.email) {
      setFormError((formError) => ({
        ...formError,
        email: t("login.emailError")
      }));
      return;
    } else if (!formData.email.includes("@")) {
      setFormError((formError) => ({
        ...formError,
        email: t("login.emailError1")
      }));
      return;
    } else if (!formData.email.includes(".")) {
      setFormError((formError) => ({
        ...formError,
        email: t("login.emailError1")
      }));
      return;
    } else if (!validEmail.test(formData.email)) {
      setFormError((formError) => ({
        ...formError,
        email: t("login.emailError1")
      }));
      return;
    }
    var data = {
      image: "test",
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      cnpj: formData.cnpj
    };
    dispatch(updateAminProfile(data));
  };

  const handleSubmitChangePassword = (event) => {
    event.preventDefault();
    if (!oldPassword) {
      setFormError((formError) => ({
        ...formError,
        oldPassword: "Please enter a old password"
      }));
      return;
    }
    if (!newPassword) {
      setFormError((formError) => ({
        ...formError,
        newPassword: "Please enter a new password"
      }));
      return;
    }
    var data = {
      oldPassword: oldPassword,
      newPassword: newPassword
    };
    dispatch(changePassword(data));
  };
  console.log("formData?.cnpj", formData);
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Admin Profile" {...a11yProps(0)} />
            <Tab label="Change Password" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Grid container>
            <Grid
              container
              sx={{ border: " 1px solid rgba(224, 224, 224, 1)", p: 2, borderRadius: 1 }}>
              <Grid container>
                <Grid
                  xs={12}
                  sx={{
                    borderRadius: "0 10px 0 0"
                  }}></Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography
                    sx={{
                      mb: -1,
                      color: "#212b36",
                      fontWeight: 500,
                      fontSize: 14,
                      display: "block"
                    }}>
                    {t("profile.firstName")}
                  </Typography>
                  <AdrenaInput
                    value={formData.firstName}
                    onChange={(val) => {
                      setFormData((formData) => ({
                        ...formData,
                        firstName: val.target.value
                      }));
                      setFormError((formError) => ({
                        ...formError,
                        firstNameError: ""
                      }));
                    }}
                    formError={formError.firstNameError}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography
                    sx={{
                      mb: -1,
                      color: "#212b36",
                      fontWeight: 500,
                      fontSize: 14,
                      display: "block"
                    }}>
                    {t("profile.lastName")}
                  </Typography>
                  <AdrenaInput
                    value={formData.lastName}
                    onChange={(val) => {
                      setFormData((formData) => ({
                        ...formData,
                        lastName: val.target.value
                      }));
                      setFormError((formError) => ({
                        ...formError,
                        lastNameError: ""
                      }));
                    }}
                    formError={formError.lastNameError}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography
                    sx={{
                      mb: -1,
                      color: "#212b36",
                      fontWeight: 500,
                      fontSize: 14,
                      display: "block"
                    }}>
                    {t("profile.email")}
                  </Typography>
                  <AdrenaInput
                    value={formData.email}
                    onChange={(val) => {
                      setFormData((formData) => ({
                        ...formData,
                        email: val.target.value
                      }));
                      setFormError((formError) => ({
                        ...formError,
                        email: ""
                      }));
                    }}
                    disabled={true}
                    formError={formError.email}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography
                    sx={{
                      mb: -1,
                      color: "#212b36",
                      fontWeight: 500,
                      fontSize: 14,
                      display: "block"
                    }}>
                    {t("users.cnpj")}
                  </Typography>
                  <AdrenaInput
                    value={formData?.cnpj}
                    onChange={(val) => {
                      setFormData((formData) => ({
                        ...formData,
                        lastName: val.target.value
                      }));
                      setFormError((formError) => ({
                        ...formError,
                        lastNameError: ""
                      }));
                    }}
                    formError={formError.lastNameError}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 1 }} gap={2}>
                <Grid xs={12} sm={12} md={2} lg={1.5}>
                  <AdminButton
                    fullWidth
                    title={"Save"}
                    onClick={(event) => {
                      handleSubmit(event);
                    }}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={2} lg={1.5}>
                  <AdminButton
                    fullWidth
                    sx={{ bgcolor: "black", "&:hover": { bgcolor: "#757575" } }}
                    title={"Cancel"}
                    onClick={() => {
                      navigate("/categories");
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container>
            <Grid
              container
              sx={{ border: " 1px solid rgba(224, 224, 224, 1)", p: 2, borderRadius: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography
                    sx={{
                      mb: -1,
                      color: "#212b36",
                      fontWeight: 500,
                      fontSize: 14,
                      display: "block"
                    }}>
                    {t("profile.passwordOld")}
                  </Typography>
                  <AdrenaInput
                    type={showOldPassword ? "text" : "password"}
                    value={oldPassword}
                    onChange={(val) => {
                      if (val) {
                        setOldPassword(val.target.value);
                        setFormError((formError) => ({
                          ...formError,
                          oldPassword: ""
                        }));
                      }
                    }}
                    formError={formError.oldPassword}
                    endIcon={
                      showOldPassword ? (
                        <Icon icon="tabler:eye" width="28" height="28" color="black" />
                      ) : (
                        <Icon icon="tabler:eye-off" width="28" height="28" color="black" />
                      )
                    }
                    PasswordShowClick={() => setShowOldPassword(!showOldPassword)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography
                    sx={{
                      mb: -1,
                      color: "#212b36",
                      fontWeight: 500,
                      fontSize: 14,
                      display: "block"
                    }}>
                    {t("profile.passwordNew")}
                  </Typography>
                  <AdrenaInput
                    type={showNewPassword ? "text" : "password"}
                    value={newPassword}
                    onChange={(val) => {
                      if (val) {
                        setNewPassword(val.target.value);
                        setFormError((formError) => ({
                          ...formError,
                          newPassword: ""
                        }));
                      }
                    }}
                    formError={formError.newPassword}
                    endIcon={
                      showNewPassword ? (
                        <Icon icon="tabler:eye" width="28" height="28" color="black" />
                      ) : (
                        <Icon icon="tabler:eye-off" width="28" height="28" color="black" />
                      )
                    }
                    PasswordShowClick={() => setShowNewPassword(!showNewPassword)}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 1 }} gap={2}>
                <Grid xs={12} sm={12} md={2} lg={1.5}>
                  <AdminButton
                    fullWidth
                    title={"Save"}
                    onClick={(event) => {
                      handleSubmitChangePassword(event);
                    }}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={2} lg={1.5}>
                  <AdminButton
                    fullWidth
                    sx={{ bgcolor: "black", "&:hover": { bgcolor: "#757575" } }}
                    title={"Cancel"}
                    onClick={() => {
                      navigate("/categories");
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </>
  );
}
