import { useEffect, useRef, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Grid, Menu, MenuItem, Tooltip, Typography, Select } from "@mui/material";
import { Icon } from "@iconify/react";
import AdrenaInput from "../../../components/adrenaInput";
import AdminButton from "../../../components/adminButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteCategory, getCategoryList, updateCategory } from "store/slice/users";
import { getMomentFormat } from "../../../constants/dateTime.constant";
import moment from "moment";
import TableActionButton from "../../../components/adminTableActionButton";
import AlertDialog from "../../../components/alertDialog";
import { useTranslation } from "react-i18next";
import Loader from "components/loader/loader";

export default function Categories() {
  var page = 1;
  const perPage = 10;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  var searchInputRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [perPageRows, setNumberOfRows] = useState(perPage);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [alertDialogRemoveOpen, setAlertDialogRemoveOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState(-1);
  const [sortColumn, setSortColumn] = useState("");
  const [pageCheck, setPageCheck] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const { categoryListData, totalCount } = useSelector((state) => state.userSlice);

  useEffect(() => {
    setIsLoading(true);
    categoryList();
  }, []);

  const categoryList = (page, numberOfRows, sortingColumnValue, sortingOrder) => {
    var data = {
      page: page ? page : 1,
      limit: numberOfRows ? numberOfRows : perPageRows,
      search: searchInputRef.current.value,
      sort: {
        sortField: sortingColumnValue !== "" ? sortingColumnValue : sortColumn,
        sortIn: sortingOrder !== "" ? sortingOrder : sortOrder
      }
    };
    // let isSearch = searchInputRef.current.value ? true : false;

    dispatch(getCategoryList(data, setIsLoading));
  };
  const handleClick = (event, dataIndex) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowIndex(dataIndex);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value.length > 2) {
      categoryList(page);
    } else if (e.target.value.length === 0) {
      categoryList(page);
    }
  };

  const clearSearch = () => {
    setSearchValue("");
    var data = {
      page: 1,
      perPage: 10,
      search: ""
    };
    let isSearch = false;
    dispatch(getCategoryList(data, isSearch, setIsLoading));
  };

  const optionMenu = () => {
    return (
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            categoryEdit();
          }}>
          {t("category.edit")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setAlertDialogRemoveOpen(true);
          }}>
          {t("category.delete")}
        </MenuItem>

        {/* )} */}
      </Menu>
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const categoryDelete = () => {
    const data = {
      deleteCategoryId: selectedRowIndex
    };
    dispatch(deleteCategory(data));
  };
  const categoryEdit = () => {
    navigate("/editCategory/" + selectedRowIndex);
  };

  const options = {
    count: totalCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: t("category.rowsPerPage"),
        displayRows: "of"
      },
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`
      }
    },
    onColumnSortChange: (changedColumn, direction) => {
      let sortingOrder = direction === "asc" ? 1 : -1;
      setSortOrder(sortingOrder);
      setSortColumn(changedColumn);
      categoryList(page, "", changedColumn, sortingOrder);
    },
    onChangePage(currentPage) {
      if (currentPage >= pageCheck) {
        if (currentPage === 0) {
          currentPage = currentPage + 1;
        }
        setPageCheck(currentPage);
        page = currentPage + 1;
        categoryList(page, "", "", "");
      } else {
        if (pageCheck > 0) {
          page = pageCheck - 1;
        }
        setPageCheck(page);
        categoryList(page, "", "", "");
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      setNumberOfRows(numberOfRows);
      categoryList(page, numberOfRows, "", "");
    }
  };

  const columns = [
    {
      name: "code",
      label: t("category.code"),

      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "imageAccessUrl",
      label: t("category.image"),

      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Grid>
              <img
                src={value}
                style={{
                  width: "50px",
                  minHeight: "50px",
                  objectFit: "cover"
                }}
              />
            </Grid>
          );
        }
      }
    },
    {
      name: i18n.language === "en" ? "titleEn" : "titlePt",
      label: t("category.name"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  maxWidth: 140,
                  fontSize: 14
                }}>
                {value?.charAt(0).toUpperCase() + value?.slice(1)}
              </Typography>
            </Tooltip>
          );
        }
      }
    },

    {
      name: i18n.language === "en" ? "descriptionEn" : "description",
      label: t("category.description"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  maxWidth: 140,
                  fontSize: 14
                }}>
                {value?.charAt(0).toUpperCase() + value?.slice(1)}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "status",
      label: t("category.status"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Select
              fullWidth
              onChange={(val) => {
                let obj = categoryListData[tableMeta?.rowIndex];
                let requestObj = {
                  uid: categoryListData[tableMeta?.rowIndex]?.id,
                  status: val.target.value
                };
                let request = {
                  ...obj,
                  ...requestObj
                };
                dispatch(updateCategory(request, "", "", t));
              }}
              value={value}
              inputProps={{
                MenuProps: {
                  MenuListProps: {
                    sx: {
                      backgroundColor: "white",
                      color: "black",
                      fontFamily: "setimo",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "22px",
                      "& .MuiMenuItem-root.Mui-selected": {
                        backgroundColor: "#d66565",
                        color: "white"
                      },
                      "& .MuiMenuItem-root:hover": {
                        backgroundColor: "#d66565",
                        color: "white"
                      }
                    }
                  }
                }
              }}
              style={{
                marginTop: "0.9rem",
                color: "black",
                marginBottom: "0.5rem",
                height: "2.7rem"
              }}>
              <MenuItem value={"active"}>{t("user.active")} </MenuItem>
              <MenuItem value={"soon"}>{t("category.soon")} </MenuItem>
              <MenuItem value={"closed"}>{t("category.closed")} </MenuItem>
            </Select>
          );
        }
      }
    },
    {
      name: "createdAt",
      label: t("category.createdAt"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          var localTime = moment.utc(value).toDate();
          // localTime = moment(value).format("DD/MM/YY - HH:mm") + "h";
          return <div style={{ alignItems: "center" }}>{getMomentFormat(localTime)}</div>;
        }
      }
    },
    {
      name: "id",
      label: t("category.action"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (dataIndex) => {
          return (
            <>
              <TableActionButton
                style={{ marginLeft: 10 }}
                onClick={(event) => handleClick(event, dataIndex)}
              />
              {optionMenu()}
            </>
          );
        }
      }
    }
  ];

  return (
    <Grid container>
      <Grid
        container
        sx={{
          border: " 1px solid rgba(224, 224, 224, 1)",
          p: 2,
          backgroundColor: "white"
        }}>
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Grid sx={{ mt: 1 }}>
            <Typography variant="body1" sx={{ fontSize: 18 }} color="secondary.contrastText">
              {t("category.title")}
            </Typography>
          </Grid>
          <Grid>
            <AdminButton
              sx={{ mt: "3%" }}
              title={t("category.addCategory")}
              onClick={() => {
                navigate("/addCategory");
              }}
            />
          </Grid>
        </Grid>
        <Grid xs={3}>
          <AdrenaInput
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                categoryList(page);
              }
            }}
            inputRef={searchInputRef}
            title={t("category.searchCagtegory")}
            type="email"
            endIcon={
              searchValue && (
                <Icon
                  icon="material-symbols:cancel"
                  style={{ cursor: "pointer" }}
                  color="red"
                  onClick={() => clearSearch()}
                />
              )
            }
            onChange={handleSearchChange}
            value={searchValue}
          />
        </Grid>
      </Grid>

      {isLoading ? (
        <Grid
          container
          sx={{
            border: "1px solid rgba(224, 224, 224, 1)",
            mt: 2,
            height: 300,
            backgroundColor: "white"
          }}>
          <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}></Grid>
        </Grid>
      ) : (
        <>
          {categoryListData && categoryListData.length > 0 ? (
            <Grid container>
              <Grid xs={12}>
                <MUIDataTable
                  // title={"Events List"}
                  data={categoryListData}
                  columns={columns}
                  options={options}
                />
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid
                container
                sx={{
                  border: "1px solid rgba(224, 224, 224, 1)",
                  mt: 2,
                  height: 300,
                  backgroundColor: "white"
                }}>
                <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
                  <Typography sx={{ fontSize: 24, fontWeight: 500, mt: "7.5rem" }}>
                    {t("category.noRecordFound")}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}

      {alertDialogRemoveOpen && (
        <AlertDialog
          open={alertDialogRemoveOpen}
          title="Warning"
          text={t("category.deleteCategory")}
          handleClose={setAlertDialogRemoveOpen}
          handleAgree={() => {
            categoryDelete();
          }}
        />
      )}

      {isLoading && <Loader open={isLoading} text={t("adrena.pleaseWait")} />}
    </Grid>
  );
}
