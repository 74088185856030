import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import AdminInput from "components/adminInput";
import AdminButton from "components/adminButton";
import { useState } from "react";
import useStyles from "./styles";
import AdrenaLogo from "assets/img/app_logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordAdmin } from "store/slice/auth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const classes = useStyles();
  const [formError, setFormError] = useState("");
  const { isLoading } = useSelector((state) => state.authSlice);

  const handleSubmit = (event) => {
    event.preventDefault();
    const validEmail = new RegExp("^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$");
    if (!email) {
      setFormError((formError) => ({
        ...formError,
        email: "Please Enter Your Email Address"
      }));
      return;
    } else if (!email.includes("@")) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter a valid email address"
      }));
      return;
    } else if (!email.includes(".")) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter a valid email address"
      }));
      return;
    } else if (!validEmail.test(email)) {
      setFormError((formError) => ({
        ...formError,
        email: "Please enter a valid email address"
      }));
      return;
    }
    // secureLocalStorage.setItem("authenticated", true);
    // Navigate("/dashboard");

    if (email) {
      const data = {
        email: email
      };
      dispatch(forgotPasswordAdmin(data, navigate));
    }
  };

  return (
    <div className={classes.setCss}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            flexDirection: "column",
            alignItems: "center"
          }}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid>
              <img src={AdrenaLogo} alt="logo" />
              <Typography style={{ color: "white", marginTop: 20 }}>
                {t("forgotPassword.heading")}
              </Typography>
            </Grid>
            <Grid>
              <Typography style={{ color: "white", marginTop: 20 }}>
                {t("forgotPassword.title")}
              </Typography>
            </Grid>
          </Grid>

          <Box noValidate sx={{ mt: 1 }}>
            <AdminInput
              title="Email Adress"
              type="email"
              value={email}
              onChange={(val) => {
                if (val) {
                  setEmail(val.target.value);
                  setFormError((formError) => ({
                    ...formError,
                    email: ""
                  }));
                }
              }}
              isLoading={isLoading}
              formError={formError.email}
            />

            <Link
              sx={{
                float: "right",
                textDecoration: "none",
                fontSize: 15,
                fontWeight: 600,
                my: 1,
                color: "white"
              }}
              variant="body2"
              href="login">
              {t("forgotPassword.backToLogin")}
            </Link>
            <AdminButton
              onClick={(event) => {
                handleSubmit(event);
              }}
              title={"SUBMIT"}
              fullWidth={true}
            />
          </Box>
        </Box>
      </Container>
    </div>
  );
}
