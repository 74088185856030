import { TextField, Typography } from "@mui/material";
import React from "react";
import "./index.css";
import InputAdornment from "@mui/material/InputAdornment";

export default function AdrenaInput({ ...props }) {
  const {
    heading,
    title,
    type,
    formError,
    value,
    onChange,
    endIcon,
    PasswordShowClick,
    inputRef,
    disabled,
    placeholder,
    maxLength,
    minDate,
    startIcon,
    maxRows,
    minRows,
    multiline,
    onKeyUp,
    onKeyDown,
    sx
  } = props;
  return (
    <>
      {heading && (
        <Typography
          sx={{
            mb: -1,
            color: "#212b36",
            fontWeight: 500,
            fontSize: 14,
            display: "block"
          }}>
          {heading}
        </Typography>
      )}

      <TextField
        margin="normal"
        required
        fullWidth
        type={type}
        InputLabelProps={{
          style: { color: "black", marginTop: -3 }
        }}
        variant="outlined"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        id={type}
        label={title}
        minRows={minRows}
        maxRows={maxRows}
        multiline={multiline}
        name={type}
        inputRef={inputRef}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        autoComplete={type}
        disabled={disabled}
        sx={{ ...sx, input: { color: "black" } }}
        inputProps={{ maxLength: maxLength, min: minDate }}
        InputProps={{
          style: { height: "2.8rem" },
          startAdornment: <InputAdornment position="start">{startIcon}</InputAdornment>,
          endAdornment: (
            <InputAdornment onClick={PasswordShowClick} position="end">
              {endIcon}
            </InputAdornment>
          )
        }}
      />
      <Typography sx={{ color: "red", fontSize: 15, ml: 1 }}>
        {formError ? formError : ""}
      </Typography>
    </>
  );
}
