/** @format */

import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { Icon } from "@iconify/react";

export default function TableActionButton({ ...props }) {
  // local
  return (
    <Button
      onClick={props.onClick}
      sx={{
        "&:hover": { backgroundColor: "red" },
        backgroundColor: "rgba(134, 92, 226, 0.1)",
        minWidth: "25px",
        minHeight: "20px"
      }}>
      <Icon icon="ph:dots-three" color="black" width="20" height="20" />
    </Button>
  );
}
TableActionButton.propTypes = {
  onClick: PropTypes.any
};
