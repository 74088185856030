import { Button } from "@mui/material";
import React from "react";

export default function AdrenaButton(props) {
  const {
    text,
    style,
    fullWidth,
    startIcon,
    endIcon,
    onClick,
    disabled,
    textTransform,
    textAlign
  } = props;
  return (
    <Button
      fullWidth={fullWidth}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      sx={{
        backgroundColor: "#E01F26",
        borderRadius: "unset",
        mt: 0.5,
        fontFamily: "SetimoBold",
        color: "#FFFFFF",
        height: "2.8rem",
        textAlign: { textAlign },
        textTransform: { textTransform },
        fontSize: "15px ",
        fontWeight: "bold",
        lineHeight: "17.6px",
        letterSpacing: "0.5px",
        ":hover": {
          color: "#FFFFFF",
          backgroundColor: "#B5000D"
        },
        "&.Mui-disabled": {
          background: "#C8C9CB",
          color: "#9D9EA0"
        }
      }}
      style={style}
      onClick={onClick}>
      {text}
    </Button>
  );
}
