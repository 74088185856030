/** @format */

import React, { memo, useEffect, useState } from "react";

import PropTypes from "prop-types";
import AdminButton from "components/adminButton";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Slide,
  Typography
} from "@mui/material";
import AdrenaInput from "components/adrenaInput";
import { Icon } from "@iconify/react";
import { Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// import { addCoupon } from "store";
import { clearCouponDetails } from "store";
import { updateCoupon } from "store";
import moment from "moment";
import "./index.css";
import { addCoupon } from "store";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddCouponDialog = (props) => {
  const { setShowDialog, showDialog, action, couponId } = props;
  const { couponDetail } = useSelector((state) => state.couponSlice);
  const [couponData, setCoupanData] = useState({});
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  let currentdate = moment().format("YYYY-MM-DD hh:mm");
  useEffect(() => {
    if (couponDetail) {
      setCoupanData(couponDetail);
    }
  }, [couponDetail]);
  const handleClose = () => {
    setShowDialog(false);
    setCoupanData((couponData) => ({
      ...couponData,
      couponCode: "",
      type: undefined,
      expiryDate: "",
      percent: ""
    }));
    dispatch(clearCouponDetails());
  };

  const handleAddCoupon = () => {
    if (!couponData.couponCode) {
      setCoupanData((couponData) => ({
        ...couponData,
        couponCodeError: "Please Enter coupon code "
      }));
      return;
    }
    if (couponData?.couponCode?.length < 5) {
      setCoupanData((couponData) => ({
        ...couponData,
        couponCodeError: " Coupon code must be atleast 5 characters"
      }));
      return;
    }
    if (!couponData.type) {
      setCoupanData((couponData) => ({
        ...couponData,
        typeError: "Please select type"
      }));
      return;
    }
    if (!couponData.percent) {
      setCoupanData((couponData) => ({
        ...couponData,
        percentError: "Please Enter Discount"
      }));
      return;
    }
    if (!couponData.expiryDate) {
      setCoupanData((couponData) => ({
        ...couponData,
        expiryDateError: "Please select date and time"
      }));
      return;
    }
    if (action === "Add") {
      let data = {
        couponCode: couponData?.couponCode,
        type: couponData?.type,
        expiryDate: moment(couponData?.expiryDate).utc().format("YYYY-MM-DD HH:mm"),
        percent: couponData?.percent * 1,
        language: i18n?.language
      };
      console.log("data", data);
      dispatch(addCoupon(data));
    } else {
      let data = {
        couponCode: couponData?.couponCode,
        couponId: couponId,
        expiryDate: moment(couponData?.expiryDate).utc().format("YYYY-MM-DD HH:mm"),
        language: i18n?.language,
        percent: couponData?.percent * 1
      };

      dispatch(updateCoupon(data));
    }
    handleClose();
    dispatch(clearCouponDetails());
  };
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={showDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontFamily: "setimo", fontWeight: 700, fontSize: 20 }}>
            {t("coupon.addCoupon")}
          </Typography>
          <Icon
            icon="akar-icons:cross"
            onClick={handleClose}
            color="primary"
            width="20px"
            height="20px"
          />
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              mb: -1.8,
              color: "black",
              fontWeight: 500,
              fontSize: 13,
              display: "block",
              mt: 2
            }}>
            {t("coupon.name")}
          </Typography>
          <AdrenaInput
            formError={couponData?.couponCodeError}
            maxLength={12}
            placeholder={t("coupon.enterCouponName")}
            onChange={(val) =>
              setCoupanData((couponData) => ({
                ...couponData,
                couponCode: val.target.value.toUpperCase().trim(),
                couponCodeError: ""
              }))
            }
            value={couponData?.couponCode}
          />
          <Typography
            sx={{
              mb: -1.8,
              color: "black",
              fontWeight: 500,
              fontSize: 13,
              display: "block",
              pt: 1
            }}>
            {t("coupon.type")}
          </Typography>
          <Select
            disabled={action === "Edit" ? true : false}
            fullWidth
            onChange={(val) =>
              setCoupanData((couponData) => ({
                ...couponData,
                type: val.target.value,
                typeError: ""
              }))
            }
            value={couponData?.type ? couponData?.type : ""}
            inputProps={{
              MenuProps: {
                MenuListProps: {
                  sx: {
                    backgroundColor: "white",
                    color: "black",
                    fontFamily: "setimo",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "22px",
                    "& .MuiMenuItem-root.Mui-selected": {
                      backgroundColor: "#d66565",
                      color: "white"
                    },
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: "#d66565",
                      color: "white"
                    }
                  }
                }
              }
            }}
            style={{
              marginTop: "0.9rem",
              color: "black",
              marginBottom: "0.5rem",
              height: "2.7rem"
            }}>
            <MenuItem value="single">{t("coupon.single")} </MenuItem>
            <MenuItem value="multiple">{t("coupon.multiple")} </MenuItem>
          </Select>
          {couponData?.typeError ? couponData?.typeError : ""}

          <Typography
            sx={{
              mb: -1.8,
              color: "black",
              fontWeight: 500,
              fontSize: 13,
              display: "block",
              mt: 2
            }}>
            {t("DISCOUNT").toUpperCase()}
          </Typography>
          <AdrenaInput
            type="number"
            endIcon={<Icon icon="material-symbols:percent" />}
            formError={couponData?.percentError}
            maxLength={15}
            placeholder={t("coupon.enterCouponDiscount")}
            onChange={(val) =>
              setCoupanData((couponData) => ({
                ...couponData,
                percent: val.target.value < 100 ? val.target.value : 100,
                percentError: ""
              }))
            }
            value={couponData?.percent}
          />
          <Typography
            sx={{
              mb: -1.8,
              color: "black",
              fontWeight: 500,
              fontSize: 13,
              display: "block",
              mt: 1
            }}>
            {t("coupon.expirydate").toUpperCase()}
          </Typography>
          <AdrenaInput
            minDate={currentdate}
            placeholder="MM/DD/YYYY"
            type={"datetime-local"}
            onKeyDown={(event) => event.preventDefault()}
            formError={couponData?.expiryDateError}
            onChange={(val) =>
              setCoupanData((couponData) => ({
                ...couponData,
                expiryDate: val.target.value,
                expiryDateError: ""
              }))
            }
            value={couponData?.expiryDate}
          />
        </DialogContent>
        <Grid container sx={{ px: 2.8, pb: 2 }} gap={2}>
          <Grid xs={5.7}>
            <AdminButton
              fullWidth
              onClick={handleClose}
              color="primary"
              variant="outlined"
              title="Cancel"
            />
          </Grid>
          <Grid xs={5.7}>
            <AdminButton
              fullWidth
              title={t("button.save")}
              onClick={handleAddCoupon}
              color="primary"
              sx={{ height: "2.5rem" }}
            />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

AddCouponDialog.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.any,
  title: PropTypes.string,
  text: PropTypes.string,
  handleAgree: PropTypes.any
};

export default memo(AddCouponDialog);
