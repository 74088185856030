import { Navigate, useRoutes } from "react-router-dom";
// layouts

import AuthLayout from "layout/auth";
// import Login from "pages/auth/login";
import secureLocalStorage from "react-secure-storage";
import ForgotPassword from "pages/auth/forgot";
import Users from "pages/dashboard/users/users";
import DashboardLayout from "layout/dashboard";
import Login from "pages/auth/login";
import Adds from "pages/dashboard/products/products";
import Badge from "pages/dashboard/badge";
import Settings from "pages/dashboard/settings";
import Categories from "pages/dashboard/category/categories";
import AddCategory from "pages/dashboard/category/addCategory";
import SubCategories from "pages/dashboard/category/subCategory/subCategories";
import AddSubCategory from "pages/dashboard/category/subCategory/addSubCategory";
import AdminProfile from "pages/dashboard/adminProfile";
import Coupons from "pages/coupons";
import ReportAd from "pages/dashboard/reportAd";
import Plans from "pages/dashboard/plans";
import AddUpdatePlan from "pages/dashboard/plans/addPlan";
import Notification from "pages/dashboard/notification";
import UserDashBoard from "pages/dashboard/dashboard";
import VerifiedPlan from "pages/dashboard/verifiedPlan";
import AddUpdateVerifyPlan from "pages/dashboard/verifiedPlan/addVerifyPlan";
import Refund from "pages/dashboard/refund";
import ContractList from "pages/dashboard/contract";
import OrdersList from "pages/dashboard/orders";
import AddUser from "pages/dashboard/users/addUser";
import AddAds from "pages/dashboard/products/addAds";
export default function Router() {
  const isLoggedIn = secureLocalStorage.getItem(process.env.REACT_APP_TOKEN_STORAGE_KEY);
  const routes = useRoutes([
    {
      path: "/",
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard" />, index: true },
        { path: "adminProfile", element: <AdminProfile /> },
        { path: "dashboard", element: <UserDashBoard /> },
        { path: "categories", element: <Categories /> },
        { path: "subCategories", element: <SubCategories /> },
        { path: "addSubCategory", element: <AddSubCategory /> },
        { path: "editSubCategory/:id", element: <AddSubCategory /> },
        { path: "users", element: <Users /> },
        { path: "users/addUser", element: <AddUser /> },
        { path: "users/addUser/:id", element: <AddUser /> },
        { path: "adrenaAds/addAds", element: <AddAds /> },
        { path: "adrenaAds/addAds/:id/:userId", element: <AddAds /> },
        { path: "adds", element: <Adds /> },
        { path: "badge", element: <Badge /> },
        { path: "reportAd", element: <ReportAd /> },
        { path: "coupon", element: <Coupons /> },
        { path: "plans", element: <Plans /> },
        { path: "refund", element: <Refund /> },
        { path: "contract", element: <ContractList /> },
        { path: "orders", element: <OrdersList /> },
        { path: "create-plan", element: <AddUpdatePlan /> },
        { path: "plan/:id", element: <AddUpdatePlan /> },
        { path: "VerifiedPlan", element: <VerifiedPlan /> },
        { path: "VerifiedPlan/:id", element: <AddUpdateVerifyPlan /> },
        { path: "notification", element: <Notification /> },
        { path: "settings", element: <Settings /> },
        { path: "addCategory", element: <AddCategory /> },
        { path: "editCategory/:id", element: <AddCategory /> }
      ]
    },
    {
      path: "/",
      element: !isLoggedIn ? <AuthLayout /> : <Navigate to="/dashboard" />,
      children: [
        { path: "login", element: <Login /> },
        { path: "forgotPassword", element: <ForgotPassword /> },
        { path: "/", element: <Navigate to="/login" /> }
      ]
    }
  ]);

  return routes;
}
