import { useEffect, useState } from "react";
import { Grid, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Loader from "components/loader/loader";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { Menu } from "@mui/material";
import { Tooltip } from "@mui/material";
import moment from "moment/moment";
import { getMomentFormat } from "constants/dateTime.constant";
import TableActionButton from "components/adminTableActionButton";
import { getRefundList } from "store";
import RefundStatus from "components/refundStatusDialog";
import { changeRefundStatus } from "store";
export default function Refund() {
  var page = 1;
  const perPage = 10;
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { refundListData, totalRefundsCount } = useSelector((state) => state.couponSlice);
  const [anchorEl, setAnchorEl] = useState(null);
  const [refundStatusData, setRefundStatusData] = useState({});
  const [resolveDialog, setResolveDialog] = useState(false);
  const [perPageRows, setNumberOfRows] = useState(perPage);
  const [pageCheck, setPageCheck] = useState(1);
  useEffect(() => {
    if (refundListData) {
      setIsLoading(false);
    }
  }, [refundListData]);
  useEffect(() => {
    fetchRefundList();
  }, []);

  const fetchRefundList = (page, numberOfRows) => {
    let data = {
      page: page ? page : 1,
      perPage: numberOfRows ? numberOfRows : 10,
      language: i18n?.language
    };
    dispatch(getRefundList(data, setIsLoading));
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event, dataIndex) => {
    setRefundStatusData((refundStatusData) => ({
      ...refundStatusData,
      refundRequestId: dataIndex,
      refundStatus:
        refundListData[refundListData?.findIndex((item) => item.id === dataIndex)]?.refundStatus
    }));
    setAnchorEl(event.currentTarget);
  };

  const handleResolve = () => {
    setAnchorEl(null);
    setResolveDialog(true);
  };
  const handleResolveService = () => {
    let data = {
      refundRequestId: refundStatusData?.refundRequestId,
      refundStatus: refundStatusData?.refundStatus,
      language: i18n?.language
    };
    dispatch(changeRefundStatus(data, setResolveDialog));
  };
  const optionMenu = () => {
    return (
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={handleResolve}>{t("refund.resolve")}</MenuItem>
      </Menu>
    );
  };
  const options = {
    count: totalRefundsCount,
    filter: false,
    pagination: true,
    selectableRows: "none",
    usePaperPlaceholder: false,
    elevation: 0,
    filterType: "dropdown",
    responsive: "vertical",
    print: false,
    download: false,
    viewColumns: false,
    search: false,
    serverSide: true,
    rowsPerPage: perPageRows,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: t("category.rowsPerPage"),
        displayRows: "of"
      },
      body: {
        noMatch: `Record Not Found`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`
      }
    },
    onChangePage(currentPage) {
      if (currentPage >= pageCheck) {
        if (currentPage === 0) {
          currentPage = currentPage + 1;
        }
        setPageCheck(currentPage);
        page = currentPage + 1;
        fetchRefundList(page, "");
      } else {
        if (pageCheck > 0) {
          page = pageCheck - 1;
        }
        setPageCheck(page);
        fetchRefundList(page, "");
      }
    },
    onChangeRowsPerPage(numberOfRows) {
      setNumberOfRows(numberOfRows);
      fetchRefundList(page, numberOfRows);
    }
  };
  const columns = [
    {
      name: "orderId",
      label: t("refund.orderId"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  fontSize: 14
                }}>
                {value}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "title",
      label: t("refund.productName"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  maxWidth: 140,
                  fontSize: 14
                }}>
                {value.charAt(0).toUpperCase() + value.slice(1)}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "productPrice",
      label: t("refund.productPrice"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  fontSize: 14
                }}>
                {value}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "reason",
      label: t("refund.reason"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  fontSize: 14
                }}>
                {value ? value : "-"}
              </Typography>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "refundStatus",
      label: t("refund.refundStatus"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <Tooltip title={value}>
              <Typography
                style={{
                  fontSize: 14
                }}>
                {value ? value : "-"}
              </Typography>
            </Tooltip>
          );
        }
      }
    },

    {
      name: "createdAt",
      label: t("products.createdAt"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          var localTime = moment.utc(value).toDate();
          return <div style={{ alignItems: "center" }}>{getMomentFormat(localTime)}</div>;
        }
      }
    },
    {
      name: "id",
      label: t("category.action"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (dataIndex) => {
          return (
            <>
              <TableActionButton
                style={{ marginLeft: 10 }}
                onClick={(event) => handleClick(event, dataIndex)}
              />
              {optionMenu()}
            </>
          );
        }
      }
    }
  ];

  return (
    <>
      <RefundStatus
        open={resolveDialog}
        text={t("refund.resolveConfirmation")}
        handleClose={setResolveDialog}
        handleAgree={handleResolveService}
        setRefundStatusData={setRefundStatusData}
        refundStatusData={refundStatusData}
      />

      <Grid container>
        <Grid
          container
          sx={{
            border: " 1px solid rgba(224, 224, 224, 1)",
            p: 2,
            backgroundColor: "white"
          }}>
          <Typography variant="body1" sx={{ fontSize: 18 }} color="secondary.contrastText">
            {t("refund.title")}
          </Typography>
        </Grid>

        {isLoading ? (
          <Grid
            container
            sx={{
              border: "1px solid rgba(224, 224, 224, 1)",
              mt: 2,
              height: 300,
              backgroundColor: "white"
            }}>
            <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}></Grid>
          </Grid>
        ) : (
          <>
            {refundListData && refundListData.length > 0 ? (
              <Grid container>
                <Grid xs={12}>
                  <MUIDataTable
                    // title={"Events List"}
                    data={refundListData}
                    columns={columns}
                    options={options}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid
                  container
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    mt: 2,
                    height: 300,
                    backgroundColor: "white"
                  }}>
                  <Grid xs={12} style={{ display: "flex", justifyContent: "center" }}>
                    <Typography sx={{ fontSize: 24, fontWeight: 500, mt: "7.5rem" }}>
                      {t("category.noRecordFound")}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}

        {isLoading && <Loader open={isLoading} text={t("adrena.pleaseWait")} />}
      </Grid>
    </>
  );
}
