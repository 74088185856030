import { Button, CircularProgress, Typography } from "@mui/material";
import React from "react";

export default function AdminButton(props) {
  const { fullWidth, title, onClick, variant, sx, startIcon, isLoading, disable } = props;
  return (
    <Button
      disabled={disable}
      fullWidth={fullWidth}
      variant={variant ? variant : "contained"}
      startIcon={startIcon}
      color="primary"
      sx={sx}
      style={{ minHeight: 30, borderRadius: 2 }}
      onClick={onClick}>
      {isLoading ? (
        <CircularProgress size={24} style={{ color: "white" }} />
      ) : (
        <Typography variant="body1" component="span">
          {title}
        </Typography>
      )}
    </Button>
  );
}
