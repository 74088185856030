/** @format */

import React, { memo } from "react";

import PropTypes from "prop-types";
import AdminButton from "components/adminButton";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Slide,
  TextField,
  Grid
} from "@mui/material";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeclineProduct = (props) => {
  const { handleClose, handleAgree, open, setReason, reason } = props;
  const { t } = useTranslation();
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontFamily: "setimo", fontWeight: 700, fontSize: 20 }}>
            {t("productDialog.declineProduct")}
          </Typography>
          <Icon
            icon="akar-icons:cross"
            onClick={() => handleClose(false)}
            color="primary"
            width="20px"
            height="20px"
          />
        </DialogTitle>
        <DialogContent
          style={{
            backgroundColor: "white",
            paddingTop: 0
          }}>
          <Typography
            sx={{
              mb: -1.5,
              color: "black",
              fontWeight: 500,
              fontSize: 13,
              display: "block",
              mt: 2
            }}>
            {t("productDialog.reason")}
          </Typography>
          <TextField
            fullWidth
            multiline
            style={{ marginTop: "1rem", color: "blue" }}
            maxRows={8}
            minRows={5}
            sx={{ input: { color: "blue" } }}
            onChange={(val) => setReason(val.target.value)}
            placeholder={t("productDialog.enterDeclineReason")}
          />
        </DialogContent>
        <Grid container sx={{ px: 2.8, pb: 2 }} gap={2}>
          <Grid xs={5.7}>
            <AdminButton
              fullWidth
              onClick={() => handleClose(false)}
              color="primary"
              variant="outlined"
              title="Cancel"
            />
          </Grid>
          <Grid xs={5.7}>
            <AdminButton
              disable={reason.length < 1 ? true : false}
              fullWidth
              title="Decline"
              onClick={() => {
                handleClose(false);
                handleAgree();
              }}
              color="primary"
              sx={{ height: "2.5rem" }}
            />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

DeclineProduct.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.any,
  title: PropTypes.string,
  text: PropTypes.string,
  handleAgree: PropTypes.any
};

export default memo(DeclineProduct);
