import React from "react";
import { Card, Typography } from "@mui/material";
import AnimatedNumber from "react-animated-number";
import { useTranslation } from "react-i18next";

export default function CategoryBox(props) {
  const { data, isMobile } = props;
  const { i18n } = useTranslation();
  return (
    <Card
      sx={{
        minWidth: 100,
        maxWidth: isMobile ? 350 : 200,
        height: 150,
        position: "relative",
        textAlign: "left"
      }}>
      <img
        src={data?.imageAccessUrl}
        alt=""
        style={{
          filter: "brightness(0.3)"
          //   opacity: 0.5
        }}
        width={"100%"}
        height="100%"
      />
      <Typography
        sx={{
          position: "absolute",
          top: 10,
          left: 10,
          color: "white",
          fontSize: "25px",
          width: 180,
          textAlign: "left"
        }}>
        {i18n?.language === "en"
          ? data?.titleEn
          : i18n?.language === "pt"
          ? data?.titlePt
          : data?.titleEs}
      </Typography>
      <AnimatedNumber
        component="text"
        initialValue={0}
        value={data?.productCount}
        stepPrecision={0}
        style={{
          transition: "0.8s ease-out",
          fontSize: 38,
          transitionProperty: "background-color, color, opacity",
          position: "absolute",
          bottom: 0,
          right: 10,
          color: "white"
        }}
        duration={3000}
        formatValue={(n) => Intl.NumberFormat("en-US").format(n)}
      />
    </Card>
  );
}
