import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "./components/scroll-to-top/scrolltotop";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@mui/material";
import { useEffect } from "react";

function App() {
  const CloseButton = ({ closeToast }) => (
    <Button size="small" onClick={closeToast} style={{ color: "white" }}>
      Ok
    </Button>
  );
  useEffect(() => {
    try {
      window.Iugu.setAccountID("82F8E4F2ABE840B1BE51B5B209914BC9");
      window.Iugu.setTestMode(true);
      window.Iugu.setup();
    } catch (error) {
      console.log("Error", error);
    }
  }, []);
  return (
    <>
      <HelmetProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Router />
        </BrowserRouter>
      </HelmetProvider>
      <ToastContainer
        theme="colored"
        position={toast.POSITION.TOP_RIGHT}
        autoClose={3000}
        hideProgressBar={true}
        pauseOnHover
        draggable
        icon={false}
        closeButton={CloseButton}
        limit={1}
      />
    </>
  );
}

export default App;
