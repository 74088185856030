import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import { Link } from "react-router-dom";
import { appColors } from "theme/colors";

export default function SideItem(props) {
  const { active = false, disabled, icon, title, index, path, open } = props;
  return (
    <List
      sx={{
        ...(active && {
          backgroundColor: appColors.primaryColor,
          color: appColors.whiteColor
        }),
        ...(disabled && {
          backgroundColor: appColors.whiteColor
        }),
        pt: 0,
        pb: 0
      }}>
      <ListItem key={index} disablePadding sx={{ display: "block" }}>
        <Link to={path} style={{ textDecoration: "none", color: appColors.whiteColor }}>
          <ListItemButton
            sx={{
              minHeight: 40,
              justifyContent: open ? "initial" : "center",
              px: 2.5
            }}>
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                color: appColors.whiteColor,
                ...(active && {
                  color: appColors.whiteColor
                })
              }}>
              {icon}
            </ListItemIcon>
            <ListItemText
              primary={title}
              primaryTypographyProps={{ style: { fontSize: 15 } }}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </Link>
      </ListItem>
    </List>
  );
}
