import { Icon } from "@iconify/react";
import {
  Autocomplete,
  Box,
  Checkbox,
  Grid,
  InputAdornment,
  InputLabel,
  List,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import AdminButton from "components/adminButton";
import AdrenaInput from "components/adrenaInput";
import { label } from "components/style";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getSubCategoryById } from "store";
import { addressPlaced } from "store";
import { getCategoryList } from "store";
import { addressSearch } from "store";
import { clearAddressSearch } from "store";
import AdBrandConfig, { documentArray } from "./config";
import { useNavigate, useParams } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import { imagesList } from "store";
import ImageBox from "./imageBox";
import PlanBox from "./planBox";
import { getUserPlansList } from "store";
import { toast } from "react-toastify";
import { getUserList } from "store";
import AdPaymentDialog from "../../../components/alertDialog/paymentDialog/index";
import { addProduct } from "store";
import Loader from "components/loader/loader";
import { getPaymentCalculation } from "store";
import { clearImageListData } from "store";
import { removeImageDataFromList } from "store";
import PhoneInput from "react-phone-input-2";
import { getproductDetail } from "store";
import { getImagelistfromProductDetails } from "store";
export default function AddAds() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  var [planPrice, setPlanPrice] = useState(0);
  const inputImages = useRef();
  const dispatch = useDispatch();
  const [categoryArray, setCategoryArray] = useState([
    {
      categoryId: "",
      subCategoryId: ""
    }
  ]);
  const [subCategoryArray, setSubCategoryArray] = useState([]);
  const [showPayentDialog, setShowPaymentDialog] = useState();
  const [brandInput, setBrandInput] = useState([
    {
      brand: "",
      specification: ""
    }
  ]);
  var theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const { id, userId } = useParams();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [adsData, setAdsData] = useState({});
  const [adsDataError, setAdsDataError] = useState({});
  const { addressListData, adressData, categoryListData, subCateogryIdData, userListData } =
    useSelector((state) => state.userSlice);
  const { imageListData, imageNameList, userPlansListData, productDetails } = useSelector(
    (state) => state.productSlice
  );
  useEffect(() => {
    if (id) {
      setIsLoading(true);
      dispatch(getproductDetail(id, userId, i18n?.language, setIsLoading));
    }
  }, [id]);
  useEffect(() => {
    if (productDetails) {
      setAdsData(JSON.parse(JSON.stringify(productDetails)));

      if (productDetails?.categoryArray?.length > 0) {
        setCategoryArray(JSON.parse(JSON.stringify(productDetails?.categoryArray)));
      }
      if (productDetails?.extraInformation?.length > 0) {
        setBrandInput(JSON.parse(JSON.stringify(productDetails?.extraInformation)));
      }

      if (productDetails?.imageFullUrl) {
        productDetails?.imageFullUrl?.map((path) => {
          return dispatch(getImagelistfromProductDetails({ data: path }));
        });
      }
    }
  }, [productDetails]);
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCategoryList();
  }, []);

  //  adress
  useEffect(() => {
    if (adressData) {
      setAdsData((adsData) => ({
        ...adsData,
        address: adressData?.formatted_address,
        latitude: adressData?.geometry.location?.lat,
        longitude: adressData?.geometry.location?.lng
      }));
      adressData?.address_components?.map((item) => {
        {
          item.types.map((data) => {
            if (data === "administrative_area_level_1") {
              setAdsData((adsData) => ({
                ...adsData,
                region: item.long_name
              }));
            } else if (data === "postal_code") {
              setAdsData((adsData) => ({
                ...adsData,
                postalCode: item.long_name
              }));
            } else if (data === "country") {
              setAdsData((adsData) => ({
                ...adsData,
                country: item.long_name
              }));
            } else if (data === "administrative_area_level_3") {
              setAdsData((adsData) => ({
                ...adsData,
                city: item.long_name
              }));
            }
          });
        }
      });
    }
  }, [adressData]);
  const handleSearch = (event, value) => {
    setAdsDataError((adsDataError) => ({
      ...adsDataError,
      addressError: ""
    }));
    if (value) {
      let data = {
        pattern: value,
        language: i18n?.language
      };
      dispatch(addressSearch(data));
    } else {
      dispatch(clearAddressSearch({ data: [] }));
    }
  };
  const handlePlaceId = (value, option) => {
    setAdsData((adsData) => ({
      ...adsData,
      address: option?.description
    }));
    let data = {
      placeId: option?.place_id,
      language: i18n.language
    };
    dispatch(addressPlaced(data));
  };
  //  category
  useEffect(() => {
    if (categoryArray?.length >= 1) {
      setAdsData((adsData) => ({
        ...adsData,
        categoryArray: categoryArray
      }));
    }
  }, [categoryArray]);
  const fetchCategoryList = () => {
    var data = {
      page: 1,
      limit: 1000
    };
    dispatch(getCategoryList(data));
  };
  // brand
  useEffect(() => {
    if (brandInput?.length >= 1) {
      setAdsData((adsData) => ({
        ...adsData,
        extraInformation: brandInput
      }));
    }
  }, [brandInput]);
  //  sub category
  useEffect(() => {
    if (subCategoryArray?.length >= 1) {
      let request = {
        categoryIds: subCategoryArray,
        language: i18n?.language
      };
      dispatch(getSubCategoryById(request));
    }
  }, [subCategoryArray]);
  const handleSubCategory = (index, value) => {
    let temp = [...subCategoryArray];
    if (temp[index] !== undefined) {
      temp[index] = value;
    } else {
      temp.push(value);
    }
    setSubCategoryArray(temp);
  };
  //  images
  useEffect(() => {
    if (imageNameList?.length >= 1) {
      setAdsData((adsData) => ({
        ...adsData,
        images: imageNameList,
        coverPhoto: imageNameList[0]
      }));
    }
  }, [imageNameList]);
  const resizeFile = (file, width, height) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        width,
        height,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        width,
        height
      );
    });

  function onChange(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      let width = 0;
      let height = 0;
      const img = document.createElement("img");
      const objectURL = URL.createObjectURL(file);
      img.onload = async () => {
        width = img.width;
        height = img.height;
        const compressedImage = await resizeFile(file, width, height);
        const formData = new FormData();
        formData.append("file", compressedImage);
        dispatch(imagesList(formData, "Product", i18n.language));
      };
      img.src = objectURL;
    }
  }
  // plans
  useEffect(() => {
    fetchPlanList();
  }, []);
  const fetchPlanList = () => {
    var request = {
      page: 1,
      perPage: 10,
      language: i18n?.language
    };
    dispatch(getUserPlansList(request));
  };
  //  user
  const handleUserSearch = (event, value) => {
    setAdsDataError((adsDataError) => ({
      ...adsDataError,
      userError: ""
    }));
    var data = {
      page: 1,
      limit: 20,
      search: value
    };
    dispatch(getUserList(data, true));
  };
  const handleUserId = (value, option) => {
    setAdsData((adsData) => ({
      ...adsData,
      loginUserId: option?.id,
      isEmailVerified: option?.isEmailVerified,
      isPhoneVerified: option?.isPhoneVerified,
      isWhatsAppVerified: option?.isWhatsAppVerified,
      productWhatsappNo: option?.isWhatsAppVerified ? option?.productWhatsappNo : "",
      productEmail: option?.isEmailVerified ? option?.email : "",
      productPhoneNo: option?.isPhoneVerified ? option?.phone : "",
      productCountryCode:
        option?.isWhatsAppVerified || option?.isPhoneVerified ? option?.countryCode : "",
      dialCode: option?.dialCode
    }));
  };
  // payment calculation
  const handlePaymentCalculation = (adDetail, couponCode) => {
    setAdsData({ ...adsData, productId: adDetail?.id });
    let planIndex = userPlansListData?.findIndex((item) => item.id === adDetail?.userPlan?.planId);
    if (planIndex >= 0) {
      if (userPlansListData[planIndex]?.priceRange?.length >= 1) {
        userPlansListData[planIndex]?.priceRange?.map((range) => {
          if (adDetail?.price >= range?.startRange && adDetail?.price <= range?.endRange) {
            planPrice = range?.amount;
            setPlanPrice(range?.amount);
          } else if (adDetail?.price > range?.startRange && range?.endRange === 0) {
            planPrice = range?.amount;
            setPlanPrice(range?.amount);
          }
        });
      }
    }
    let data = {
      userPlanId:
        adDetail?.upcomingUserPlanData != null
          ? adDetail?.upcomingUserPlanData?._id
          : adDetail?.userPlan?._id,
      planPrice: adDetail?.userPlan?.subscriptionType
        ? adDetail?.userPlan?.subscriptionType === "monthly"
          ? userPlansListData[planIndex]?.amountMonthly
          : userPlansListData[planIndex]?.amountYearly
        : planPrice,
      userServiceId: "",
      productId: adDetail?.id,
      servicePrice: 0,
      couponCode: couponCode ? couponCode : "",
      language: i18n?.language
    };
    if (adDetail?.userPlan?._id) {
      dispatch(getPaymentCalculation(data, setIsLoading, setShowPaymentDialog));
    }
    // if (planPrice > 0) {
    //   dispatch(getPaymentCalculation(data, setIsLoading, setShowPaymentDialog));

    // } else {
    //   let data = {
    //     productId: productDetails?.id,
    //     language: i18n?.language,
    //     subscriptionType: productDetails?.userPlan?.subscriptionType,
    //     productStatus: "approve"
    //   };
    //   const newObj = { ...productDetails, ...data };
    //   dispatch(addProduct(newObj, "", navigate, setIsLoading));
    // }
  };
  // handle Draft
  const handleDraft = () => {
    if (!adsData?.loginUserId && !productDetails?.userId) {
      setAdsDataError((adsDataError) => ({
        ...adsDataError,
        userError: t("notification.userError")
      }));
      return;
    }
    if (!adsData?.title) {
      setAdsDataError((adsDataError) => ({
        ...adsDataError,
        titleError: t("adrenaAds.titleError")
      }));
      return;
    }
    if (!adsData?.price) {
      setAdsDataError((adsDataError) => ({
        ...adsDataError,
        priceError: t("adrenaAds.priceError")
      }));
      return;
    }
    if (adsData?.price < 100) {
      setAdsDataError((adsDataError) => ({
        ...adsDataError,
        priceError: t("adrenaAds.priceValidError")
      }));
      return;
    }
    if (!adsData?.address) {
      setAdsDataError((adsDataError) => ({
        ...adsDataError,
        addressError: t("adrenaAds.addressError")
      }));
      return;
    }
    if (!adsData?.description) {
      setAdsDataError((adsDataError) => ({
        ...adsDataError,
        descriptionError: t("adrenaAds.descriptionError")
      }));
      return;
    }
    if (!adsData?.categoryArray[0]?.categoryId) {
      setAdsDataError((adsDataError) => ({
        ...adsDataError,
        categoryError: t("adrenaAds.categoryError")
      }));
      return;
    }
    if (!adsData?.categoryArray[0]?.subCategoryId) {
      setAdsDataError((adsDataError) => ({
        ...adsDataError,
        subCategoryError: t("adrenaAds.subCategoryError")
      }));
      return;
    }
    if (!adsData?.extraInformation[0]?.brand) {
      setAdsDataError((adsDataError) => ({
        ...adsDataError,
        brandError: t("adrenaAds.brandError")
      }));
      return;
    }

    if (!adsData?.conservationState) {
      setAdsDataError((adsDataError) => ({
        ...adsDataError,
        conservationStateError: t("adrenaAds.conservationStateError")
      }));
      return;
    }
    if (imageNameList?.length < 1) {
      toast.warning(t("addProduct.coverImage"));
      return;
    }
    let obj = {
      language: i18n?.language,
      productStatus: "draft",
      loginUserId: adsData?.loginUserId ? adsData?.loginUserId : productDetails?.userId
    };
    const request = { ...adsData, ...obj };
    dispatch(addProduct(request));
  };
  //  handle publish
  const handlePublish = (isUpdate) => {
    if (!adsData?.loginUserId && !productDetails?.userId) {
      setAdsDataError((adsDataError) => ({
        ...adsDataError,
        userError: t("notification.userError")
      }));
      return;
    }
    if (!adsData?.title) {
      setAdsDataError((adsDataError) => ({
        ...adsDataError,
        titleError: t("adrenaAds.titleError")
      }));
      return;
    }
    if (!adsData?.price) {
      setAdsDataError((adsDataError) => ({
        ...adsDataError,
        priceError: t("adrenaAds.priceError")
      }));
      return;
    }
    if (!adsData?.address) {
      setAdsDataError((adsDataError) => ({
        ...adsDataError,
        addressError: t("adrenaAds.addressError")
      }));
      return;
    }
    if (!adsData?.description) {
      setAdsDataError((adsDataError) => ({
        ...adsDataError,
        descriptionError: t("adrenaAds.descriptionError")
      }));
      return;
    }
    if (!adsData?.categoryArray[0]?.categoryId) {
      setAdsDataError((adsDataError) => ({
        ...adsDataError,
        categoryError: t("adrenaAds.categoryError")
      }));
      return;
    }
    if (!adsData?.categoryArray[0]?.subCategoryId) {
      setAdsDataError((adsDataError) => ({
        ...adsDataError,
        subCategoryError: t("adrenaAds.subCategoryError")
      }));
      return;
    }
    if (!adsData?.extraInformation[0]?.brand) {
      setAdsDataError((adsDataError) => ({
        ...adsDataError,
        brandError: t("adrenaAds.brandError")
      }));
      return;
    }
    if (!adsData?.conservationState) {
      setAdsDataError((adsDataError) => ({
        ...adsDataError,
        conservationStateError: t("adrenaAds.conservationStateError")
      }));
      return;
    }
    if (imageNameList?.length < 1) {
      toast.warning(t("addProduct.coverImage"));
      return;
    }
    if (!adsData?.planId) {
      toast.warning(t("adrenaAds.planError"));
      return;
    }
    setIsLoading(true);
    let obj = {
      productId: productDetails?.id,
      language: i18n?.language,
      productStatus: isUpdate ? productDetails?.productStatus : "draft",
      loginUserId: adsData?.loginUserId ? adsData?.loginUserId : productDetails?.userId
    };
    const request = { ...adsData, ...obj };
    if (isUpdate) {
      setIsLoading(true);
      dispatch(addProduct(request, "", navigate, setIsLoading));
    } else {
      dispatch(addProduct(request, handlePaymentCalculation));
    }
  };
  return (
    <>
      <AdPaymentDialog
        setShowDialog={setShowPaymentDialog}
        showDialog={showPayentDialog}
        handlePaymentCalculation={handlePaymentCalculation}
        adsData={adsData}
        planPrice={planPrice}
      />
      <Grid container sx={{ mb: 5 }}>
        <Grid>
          <Typography sx={{ fontSize: 18, fontWeight: 700, color: "#212b36" }}>
            {t("adrenaAds.addAds")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          container
          sx={{
            border: " 1px solid rgba(224, 224, 224, 1)",
            background: "white",
            p: 2,
            pb: 10,
            borderRadius: 1
          }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography style={label} sx={{ pb: 2 }}>
                {t("adrenaAds.user")}
              </Typography>
              {id ? (
                <AdrenaInput value={adsData?.email ? adsData?.email : adsData?.phone} disabled />
              ) : (
                <Autocomplete
                  sx={{
                    backgroundColor: "transparent",
                    width: isMobile ? "100%" : "100%",
                    height: 45,
                    paddingTop: 0.1
                  }}
                  freeSolo
                  disableClearable
                  options={userListData?.length >= 1 ? userListData : []}
                  value={adsData?.loginUserId && adsData?.loginUserId}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      setAdsData((adsData) => ({
                        ...adsData,
                        loginUserId: ""
                      }));
                    }
                  }}
                  getOptionLabel={(option) => {
                    if (option?.phone) {
                      return option?.phone;
                    } else {
                      return option?.email;
                    }
                  }}
                  onChange={handleUserId}
                  onInputChange={handleUserSearch}
                  renderOption={(props, option) => {
                    const { email, phone } = option;
                    return (
                      <List
                        {...props}
                        style={{
                          backgroundColor: "black",
                          marginTop: "-0.5rem",
                          marginBottom: "-0.5rem"
                        }}>
                        <ListItemText
                          sx={{
                            fontSize: "28px",
                            color: "white",
                            fontWeight: 600,
                            lineHeight: "22px",
                            fontFamily: "Setimo"
                          }}>
                          {email ? email : phone}
                        </ListItemText>
                      </List>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        style: { color: "black" }
                      }}
                      sx={{
                        input: {
                          color: "black",
                          fontFamily: "setimo",
                          fontSize: 16,
                          height: 10
                        },
                        "& .MuiAutocomplete-inputRoot": {
                          borderRadius: "10px"
                        },
                        "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
                          color: "black",
                          visibility: "visible"
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        type: "search"
                      }}
                    />
                  )}
                />
              )}
              {adsDataError?.userError && (
                <Typography sx={{ color: "red", fontSize: 15, ml: 1, mt: 0.5 }}>
                  {adsDataError?.userError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography style={label}>{t("adrenaAds.title")}</Typography>
              <AdrenaInput
                value={adsData?.title}
                maxLength={36}
                onChange={(val) => {
                  setAdsData((adsData) => ({
                    ...adsData,
                    title: val.target.value
                  }));
                  setAdsDataError((adsDataError) => ({
                    ...adsDataError,
                    titleError: ""
                  }));
                }}
                formError={adsDataError?.titleError}
              />
              <InputLabel sx={{ display: "flex", justifyContent: "end", color: "black" }}>
                {adsData?.title ? adsData?.title?.length : 0}/36
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography style={label}>{t("adrenaAds.price")}</Typography>
              <AdrenaInput
                type={"number"}
                startIcon={<Icon icon="mdi:brazilian-real" height="20px" width="20px" />}
                value={adsData?.price}
                onChange={(val) => {
                  setAdsData((adsData) => ({
                    ...adsData,
                    price: val.target.value
                  }));
                  setAdsDataError((adsDataError) => ({
                    ...adsDataError,
                    priceError: ""
                  }));
                }}
                formError={adsDataError?.priceError}
              />
              <InputLabel sx={{ pl: 1, display: "flex", justifyContent: "end", color: "black" }}>
                {t("adrenaAds.minimumprice")}
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography
                sx={{
                  mb: -0.9,
                  color: "#212b36",
                  fontWeight: 500,
                  fontSize: 15
                }}>
                {t("adrenaAds.document")}
              </Typography>
              <Select
                fullWidth
                onChange={(val) => {
                  setAdsData((adsData) => ({
                    ...adsData,
                    documentRequired: val.target.value
                  }));
                }}
                value={adsData?.documentRequired ? adsData?.documentRequired : ""}
                inputProps={{
                  MenuProps: {
                    MenuListProps: {
                      sx: {
                        backgroundColor: "white",
                        color: "black",
                        fontFamily: "setimo",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "22px",
                        "& .MuiMenuItem-root.Mui-selected": {
                          backgroundColor: "#d66565",
                          color: "white"
                        },
                        "& .MuiMenuItem-root:hover": {
                          backgroundColor: "#d66565",
                          color: "white"
                        }
                      }
                    }
                  }
                }}
                style={{
                  marginTop: "0.9rem",
                  color: "black",
                  marginBottom: "0.5rem",
                  height: "2.7rem"
                }}>
                {documentArray?.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item?.value}>
                      {t(`${item?.title}`)}
                    </MenuItem>
                  );
                })}
              </Select>
              {/* {adsDataError?.accountTypeError && (
                <Typography sx={{ color: "red", fontSize: 15, ml: 1, mt: 0.5 }}>
                  {adsDataError?.accountTypeError}
                </Typography>
              )} */}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography style={label}>{t("user.Address")}</Typography>
              <Autocomplete
                sx={{
                  mt: 2.2,
                  height: 44,
                  paddingTop: 0.1
                }}
                freeSolo
                // disableClearable
                value={adsData?.address || ""}
                options={addressListData}
                getOptionLabel={(option) => option.description || adsData?.address || ""}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    setAdsData((adsData) => ({
                      ...adsData,
                      address: ""
                    }));
                  }
                }}
                onChange={handlePlaceId}
                onInputChange={handleSearch}
                renderOption={(props, option) => {
                  const { description } = option;
                  return (
                    <List
                      {...props}
                      style={{
                        backgroundColor: "white",
                        marginTop: "-0.5rem",
                        marginBottom: "-0.5rem"
                      }}>
                      <ListItemText
                        sx={{
                          fontSize: "16px",
                          color: "black",
                          fontWeight: 400,
                          lineHeight: "22px",
                          fontFamily: "Setimo"
                        }}>
                        {description}
                      </ListItemText>
                    </List>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{
                      style: { color: "black" }
                    }}
                    sx={{
                      input: {
                        color: "black",
                        fontFamily: "setimo",
                        fontSize: 16,
                        height: 10
                      },

                      "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
                        color: "black",
                        visibility: "visible"
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,

                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon icon="ion:location-sharp" height="20px" width="20px" />
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />
              {adsDataError?.addressError && (
                <Typography sx={{ color: "red", fontSize: 15, ml: 1 }}>
                  {adsDataError?.addressError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography style={label}>{t("adrenaAds.description")}</Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                InputLabelProps={{
                  style: { color: "black", marginTop: -3 }
                }}
                value={adsData?.description}
                onChange={(val) => {
                  setAdsData((adsData) => ({
                    ...adsData,
                    description: val.target.value
                  }));
                  setAdsDataError((adsDataError) => ({
                    ...adsDataError,
                    descriptionError: ""
                  }));
                }}
                variant="outlined"
                minRows={1}
                maxRows={3}
                multiline={true}
                inputProps={{ maxLength: 500 }}
                sx={{ input: { color: "black" } }}
                InputProps={{
                  style: { minHeight: "1rem", color: "black" }
                }}
              />
              <InputLabel sx={{ display: "flex", justifyContent: "end", color: "black" }}>
                {adsData?.description ? adsData?.description?.length : 0}/500
              </InputLabel>
              {adsDataError?.descriptionError && (
                <Typography sx={{ color: "red", fontSize: 15, ml: 1, mt: -3 }}>
                  {adsDataError?.descriptionError}
                </Typography>
              )}
            </Grid>
            <Box
              sx={{
                border: "1px solid #e7e7e7",
                width: "100%",
                p: 2,
                ml: 1,
                mt: 1
              }}>
              <Grid container spacing={2}>
                {categoryArray?.map((item, index) => {
                  return (
                    <>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            mb: -1,
                            color: "#212b36",
                            fontWeight: 500,
                            fontSize: 15,
                            display: "block"
                          }}>
                          {t("adrenaAds.category")}
                        </Typography>
                        <Select
                          fullWidth
                          onChange={(val) => {
                            handleSubCategory(index, val?.target.value);
                            let temp = [...categoryArray];
                            temp[index].categoryId = val?.target.value;
                            setCategoryArray(temp);
                            setAdsDataError((adsDataError) => ({
                              ...adsDataError,
                              categoryError: ""
                            }));
                          }}
                          value={item?.categoryId}
                          inputProps={{
                            MenuProps: {
                              MenuListProps: {
                                sx: {
                                  backgroundColor: "white",
                                  color: "black",
                                  fontFamily: "setimo",
                                  fontWeight: 400,
                                  fontSize: "16px",
                                  lineHeight: "22px",
                                  "& .MuiMenuItem-root.Mui-selected": {
                                    backgroundColor: "#d66565",
                                    color: "white"
                                  },
                                  "& .MuiMenuItem-root:hover": {
                                    backgroundColor: "#d66565",
                                    color: "white"
                                  }
                                }
                              }
                            }
                          }}
                          style={{
                            marginTop: "0.9rem",
                            color: "black",
                            marginBottom: "0.5rem",
                            height: "2.7rem"
                          }}>
                          {categoryListData?.map((category, index) => {
                            return (
                              <MenuItem key={index} value={category?.id}>
                                {i18n?.language == "en" ? category?.titleEn : category?.titlePt}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {adsDataError?.categoryError && (
                          <Typography sx={{ color: "red", fontSize: 15, ml: 1 }}>
                            {adsDataError?.categoryError}
                          </Typography>
                        )}
                      </Grid>

                      <Grid item xs={6}>
                        {index > 0 ? (
                          <Stack
                            direction="row"
                            sx={{ display: "flex", justifyContent: "space-between", mb: -0.8 }}>
                            <Typography
                              sx={{
                                color: "#212b36",
                                fontWeight: 500,
                                fontSize: 15,
                                display: "block"
                              }}>
                              {t("adrenaAds.subCategory")}
                            </Typography>
                            <Icon
                              icon="akar-icons:cross"
                              onClick={() => {
                                let temp = [...categoryArray];
                                temp.splice(index, 1);
                                setCategoryArray(temp);
                              }}
                              color="primary"
                              width="20px"
                              height="20px"
                              style={{ cursor: "pointer" }}
                            />
                          </Stack>
                        ) : (
                          <Typography
                            sx={{
                              mb: -1,
                              color: "#212b36",
                              fontWeight: 500,
                              fontSize: 15,
                              display: "block"
                            }}>
                            {t("adrenaAds.subCategory")}
                          </Typography>
                        )}

                        <Select
                          fullWidth
                          onChange={(val) => {
                            let temp = [...categoryArray];
                            temp[index].subCategoryId = val?.target.value;
                            setCategoryArray(temp);
                            setAdsDataError((adsDataError) => ({
                              ...adsDataError,
                              subCategoryError: ""
                            }));
                          }}
                          value={item?.subCategoryId}
                          inputProps={{
                            MenuProps: {
                              MenuListProps: {
                                sx: {
                                  backgroundColor: "white",
                                  color: "black",
                                  fontFamily: "setimo",
                                  fontWeight: 400,
                                  fontSize: "16px",
                                  lineHeight: "22px",
                                  "& .MuiMenuItem-root.Mui-selected": {
                                    backgroundColor: "#d66565",
                                    color: "white"
                                  },
                                  "& .MuiMenuItem-root:hover": {
                                    backgroundColor: "#d66565",
                                    color: "white"
                                  }
                                }
                              }
                            }
                          }}
                          style={{
                            marginTop: "0.9rem",
                            color: "black",
                            marginBottom: "0.5rem",
                            height: "2.7rem"
                          }}>
                          {subCateogryIdData?.map((subCategory, subIndex) => {
                            if (index === subIndex) {
                              return subCategory?.subCatArray?.map((item) => {
                                return (
                                  <MenuItem key={item._id} value={item?._id}>
                                    {item?.title}
                                  </MenuItem>
                                );
                              });
                            }
                          })}
                        </Select>
                        {adsDataError?.subCategoryError && (
                          <Typography sx={{ color: "red", fontSize: 15, ml: 1 }}>
                            {adsDataError?.subCategoryError}
                          </Typography>
                        )}
                      </Grid>
                    </>
                  );
                })}
              </Grid>
              {categoryArray?.length < 3 && (
                <InputLabel
                  onClick={() => {
                    let data = {
                      categoryId: categoryListData[0]?.id,
                      subCategoryId: ""
                    };
                    let temp = [...categoryArray];
                    temp.push(data);
                    setCategoryArray(temp);
                    let subTemp = [...subCategoryArray];
                    subTemp[categoryArray?.length] = categoryListData[0]?.id;
                    setSubCategoryArray(subTemp);
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "right",
                    fontSize: 16,
                    fontWeight: 600,
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "black"
                  }}>
                  {t("category.addCategory")}
                </InputLabel>
              )}
            </Box>
            <Box
              sx={{
                border: "1px solid #e7e7e7",
                width: "100%",
                p: 2,
                ml: 1,
                mt: 1
              }}>
              <Grid container spacing={2}>
                {brandInput?.map((item, index) => {
                  return (
                    <>
                      <Grid item xs={6}>
                        <Typography style={label}>{t("adrenaAds.brand")}</Typography>
                        <AdrenaInput
                          value={item?.brand}
                          maxLength={30}
                          onChange={(val) => {
                            let temp = [...brandInput];
                            temp[index].brand = val.target.value;
                            setBrandInput(temp);
                            setAdsDataError((adsDataError) => ({
                              ...adsDataError,
                              brandError: ""
                            }));
                          }}
                          // formError={adsDataError?.emailError}
                        />
                        <InputLabel sx={{ display: "flex", justifyContent: "end", color: "black" }}>
                          {item?.brand ? item?.brand?.length : 0}/30
                        </InputLabel>
                        {adsDataError?.brandError && (
                          <Typography sx={{ color: "red", fontSize: 15, ml: 1, mt: -2.8 }}>
                            {adsDataError?.brandError}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {index > 0 ? (
                          <Stack
                            direction="row"
                            sx={{ display: "flex", justifyContent: "space-between", mb: -0.8 }}>
                            <Typography style={label}>{t("adrenaAds.specification")}</Typography>
                            <Icon
                              icon="akar-icons:cross"
                              onClick={() => {
                                let temp = [...categoryArray];
                                temp.splice(index, 1);
                                setCategoryArray(temp);
                              }}
                              color="primary"
                              width="20px"
                              height="20px"
                              style={{ cursor: "pointer" }}
                            />
                          </Stack>
                        ) : (
                          <Typography style={label}>{t("adrenaAds.specification")}</Typography>
                        )}
                        <AdrenaInput
                          value={item?.specification}
                          maxLength={50}
                          onChange={(val) => {
                            let temp = [...brandInput];
                            temp[index].specification = val.target.value;
                            setBrandInput(temp);
                          }}
                          // formError={adsDataError?.emailError}
                        />
                        <InputLabel sx={{ display: "flex", justifyContent: "end", color: "black" }}>
                          {item?.specification ? item?.specification?.length : 0}/50
                        </InputLabel>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
              {brandInput?.length < 3 && (
                <InputLabel
                  onClick={() => {
                    let obj = {
                      brand: "",
                      specification: ""
                    };
                    let temp = [...brandInput];
                    temp.push(obj);
                    setBrandInput(temp);
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "right",
                    fontSize: 16,
                    fontWeight: 600,
                    mt: 2,
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "black"
                  }}>
                  {t("adrenaAds.addBrand")}
                </InputLabel>
              )}
            </Box>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography
                sx={{
                  mb: -1,
                  color: "#212b36",
                  fontWeight: 500,
                  fontSize: 15,
                  display: "block"
                }}>
                {t("products.conservationState")}
              </Typography>
              <Select
                value={adsData?.conservationState ?? ""}
                fullWidth
                onChange={(val) => {
                  setAdsData((adsData) => ({
                    ...adsData,
                    conservationState: val.target.value
                  }));
                  setAdsDataError((adsDataError) => ({
                    ...adsDataError,
                    conservationStateError: ""
                  }));
                }}
                inputProps={{
                  MenuProps: {
                    MenuListProps: {
                      sx: {
                        backgroundColor: "white",
                        color: "black",
                        fontFamily: "setimo",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "22px",
                        "& .MuiMenuItem-root.Mui-selected": {
                          backgroundColor: "#d66565",
                          color: "white"
                        },
                        "& .MuiMenuItem-root:hover": {
                          backgroundColor: "#d66565",
                          color: "white"
                        }
                      }
                    }
                  }
                }}
                style={{
                  marginTop: "0.9rem",
                  color: "black",
                  marginBottom: "0.5rem",
                  height: "2.7rem"
                }}>
                {AdBrandConfig?.map((item, index) => {
                  return (
                    <MenuItem value={item?.id} sx={{ display: "block" }} key={index}>
                      <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
                        {item?.heading}
                      </Typography>
                      <Typography>{t(`${item?.subHeading}`)}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
              {adsDataError?.conservationStateError && (
                <Typography sx={{ color: "red", fontSize: 15, ml: 1 }}>
                  {adsDataError?.conservationStateError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography style={label}>{t("adrenaAds.model")}</Typography>
              <AdrenaInput
                value={adsData?.model}
                onChange={(val) => {
                  setAdsData((adsData) => ({
                    ...adsData,
                    model: val.target.value
                  }));
                }}
                // formError={adsDataError?.emailError}
              />
            </Grid>
            {/* CONTACT */}

            <Box
              sx={{
                border: "1px solid #e7e7e7",
                width: "100%",
                p: 2,
                ml: 1,
                mt: 3
              }}>
              <Typography sx={{ color: "black", fontWeight: 700, fontSize: 18, display: "block" }}>
                {t("addproduct.contact")}
              </Typography>
              <Stack direction={"row"} mt={2} gap={2}>
                <Checkbox
                  checked={adsData?.isEmailVerified ? true : false}
                  disabled={adsData?.productEmail ? false : true}
                  onChange={(event) => {
                    setAdsData((adsData) => ({
                      ...adsData,
                      isEmailVerified: event.target.checked
                    }));
                  }}
                  icon={
                    <Icon
                      icon="carbon:checkbox"
                      color={adsData?.productEmail ? "red" : "grey"}
                      height="23px"
                      width="23px"
                    />
                  }
                  checkedIcon={
                    <Icon icon="ri:checkbox-fill" color="red" height="23px" width="23px" />
                  }
                />
                <Box sx={{ width: isMobile ? "100%" : "18%" }}>
                  <Typography color={"black"} sx={{ mb: -2 }}>
                    {t("login.email")}
                  </Typography>
                  {/* {adsData?.productEmail ? (
                    <Typography mt={2}>{adsData?.productEmail}</Typography>
                  ) : ( */}
                  <AdrenaInput
                    fullWidth
                    value={adsData?.productEmail || ""}
                    onChange={(val) => {
                      setAdsData((adsData) => ({
                        ...adsData,
                        productEmail: val.target.value
                      }));
                    }}
                    // formError={adsDataError?.emailError}
                  />
                  {/* )} */}
                </Box>
              </Stack>
              <Stack direction={"row"} mt={2} gap={2}>
                <Checkbox
                  checked={adsData?.isWhatsAppVerified ? true : false}
                  disabled={adsData?.productWhatsappNo ? false : true}
                  onChange={(event) => {
                    setAdsData((adsData) => ({
                      ...adsData,
                      isWhatsAppVerified: event.target.checked
                    }));
                  }}
                  icon={
                    <Icon
                      icon="carbon:checkbox"
                      color={adsData?.productWhatsappNo ? "red" : "grey"}
                      height="23px"
                      width="23px"
                    />
                  }
                  checkedIcon={
                    <Icon icon="ri:checkbox-fill" color="red" height="23px" width="23px" />
                  }
                />
                <Box>
                  <Typography color={"black"} mb={-0.3}>
                    {t("addproduct.whatsapp")}
                  </Typography>
                  {/* {adsData?.productWhatsappNo ? (
                    <Typography mt={0.5}>{adsData?.productWhatsappNo}</Typography>
                  ) : ( */}
                  <PhoneInput
                    country={"br"}
                    placeholder={"(00) 0000-0000"}
                    onChange={(value, inputdata) => {
                      setAdsData((userData) => ({
                        ...userData,
                        productWhatsappNo: value.slice(inputdata?.dialCode?.length),
                        productCountryCode: inputdata?.countryCode?.toUpperCase(),
                        dialCode: inputdata?.dialCode
                      }));
                    }}
                    value={`${adsData?.dialCode}${adsData?.productWhatsappNo}`}
                    inputStyle={{
                      width: "100%",
                      border: "1px solid #E7E9EA",
                      height: "42px",
                      fontSize: "19px",
                      color: "black"
                    }}
                  />
                  {/* )} */}
                </Box>
              </Stack>
              <Stack direction={"row"} mt={2} gap={2}>
                <Checkbox
                  checked={adsData?.isPhoneVerified ? true : false}
                  inputProps={{ "aria-label": "controlled" }}
                  disabled={adsData?.productPhoneNo ? false : true}
                  onChange={(event) => {
                    setAdsData((adsData) => ({
                      ...adsData,
                      isPhoneVerified: event.target.checked
                    }));
                  }}
                  icon={
                    <Icon
                      icon="carbon:checkbox"
                      color={adsData?.productPhoneNo ? "red" : "grey"}
                      height="23px"
                      width="23px"
                    />
                  }
                  checkedIcon={
                    <Icon icon="ri:checkbox-fill" color="red" height="23px" width="23px" />
                  }
                />
                <Box>
                  <Typography color={"black"} mb={-0.3}>
                    {t("users.phone")}
                  </Typography>
                  {/* {adsData?.productPhoneNo ? (
                    <Typography mt={0.5}>{adsData?.productPhoneNo}</Typography>
                  ) : ( */}
                  <PhoneInput
                    country={"br"}
                    placeholder={"(00) 0000-0000"}
                    onChange={(value, inputdata) => {
                      setAdsData((userData) => ({
                        ...userData,
                        productPhoneNo: value.slice(inputdata?.dialCode?.length),
                        productCountryCode: inputdata?.countryCode?.toUpperCase(),
                        dialCode: inputdata?.dialCode
                      }));
                    }}
                    value={`${adsData?.dialCode}${adsData?.productPhoneNo}`}
                    inputStyle={{
                      width: "100%",
                      border: "1px solid #E7E9EA",
                      height: "42px",
                      fontSize: "19px",
                      color: "black"
                    }}
                  />
                  {/* )} */}
                </Box>
              </Stack>
            </Box>
            {/* photos */}
            <Box
              sx={{
                border: "1px solid #e7e7e7",
                width: "100%",
                p: 2,
                ml: 1,
                mt: 1
              }}>
              <Grid item xs={12}>
                <Stack direction="row" sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{ color: "black", fontWeight: 700, fontSize: 18, display: "block" }}>
                    {`${t("adrenaAds.photos")} (${
                      imageNameList?.length >= 1 ? imageNameList?.length : 0
                    }/ 9)`}
                  </Typography>
                  <AdminButton
                    disable={imageNameList?.length >= 9 ? true : false}
                    onClick={() => {
                      inputImages.current && inputImages.current.click();
                    }}
                    title={t("adrenaAds.addPhotos")}
                  />
                  <input
                    ref={inputImages}
                    style={{ display: "none", cursor: "pointer" }}
                    type="file"
                    accept="image/*"
                    onChange={onChange}
                    multiple={true}
                    maxLength={9}
                  />
                </Stack>
              </Grid>
              <Grid container sx={{ mt: 4 }}>
                {imageListData?.map((data, index) => {
                  return (
                    <Grid xs={12} sm={12} md={2.2} lg={2.2} key={index}>
                      <ImageBox
                        image={data}
                        index={index}
                        handleDelete={() => dispatch(removeImageDataFromList(index))}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
            {/* update product  buttons and plans  */}
            {id && productDetails?.userPlan?.paymentStatus === "pending" && (
              <>
                <Grid item xs={12}>
                  <Typography
                    sx={{ color: "black", fontWeight: 700, fontSize: 18, display: "block" }}>
                    {t("adDocument.selectPlan")}
                  </Typography>
                  <Grid container spacing={2}>
                    {userPlansListData?.length >= 1 &&
                      userPlansListData?.map((plan, index) => {
                        if (plan?.subType != "store") {
                          return (
                            <Grid key={index} item xs={12} sm={12} md={6} lg={6}>
                              <PlanBox
                                plan={plan}
                                adFormData={adsData}
                                setAdFormData={setAdsData}
                              />
                            </Grid>
                          );
                        }
                      })}
                  </Grid>
                </Grid>
                <Grid container sx={{ mt: 4 }} gap={2}>
                  <Grid xs={5} sm={5} md={2} lg={2}>
                    <AdminButton
                      fullWidth
                      sx={{ bgcolor: "black", "&:hover": { bgcolor: "#757575" } }}
                      title={t("button.saveInDraft")}
                      onClick={() => {
                        dispatch(clearImageListData());
                        navigate("/adds");
                      }}
                    />
                  </Grid>
                  <Grid xs={6} sm={6} md={2} lg={2}>
                    <AdminButton
                      fullWidth
                      onClick={() => handlePublish()}
                      title={t("adrenaAds.publishAd")}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {id && productDetails?.userPlan?.paymentStatus != "pending" && (
              <Grid container sx={{ mt: 4, p: 2 }} gap={2}>
                <Grid xs={4} sm={4} md={2} lg={2}>
                  <AdminButton
                    fullWidth
                    sx={{ bgcolor: "black", "&:hover": { bgcolor: "#757575" } }}
                    title={t("users.cancel")}
                    onClick={() => {
                      dispatch(clearImageListData());
                      navigate("/adrenaAds");
                    }}
                  />
                </Grid>

                <Grid xs={6} sm={6} md={2} lg={2}>
                  <AdminButton
                    fullWidth
                    onClick={() => handlePublish("update")}
                    title={t("plans.btnUpdate")}
                  />
                </Grid>
              </Grid>
            )}
            {/*  add product buttons and plans */}
            {!id && !productDetails?.id && (
              <Grid container sx={{ mt: 4, p: 2 }} gap={2}>
                <Grid xs={4} sm={4} md={2} lg={2}>
                  <AdminButton
                    fullWidth
                    sx={{ bgcolor: "black", "&:hover": { bgcolor: "#757575" } }}
                    title={t("users.cancel")}
                    onClick={() => {
                      navigate("/adrenaAds");
                    }}
                  />
                </Grid>

                <Grid xs={6} sm={6} md={2} lg={2}>
                  <AdminButton fullWidth onClick={handleDraft} title={t("adDocument.selectPlan")} />
                </Grid>
              </Grid>
            )}
            {!id && productDetails?.id && (
              <Grid item xs={12}>
                <Typography
                  sx={{ color: "black", fontWeight: 700, fontSize: 18, display: "block" }}>
                  {t("adDocument.selectPlan")}
                </Typography>
                <Grid container spacing={2}>
                  {userPlansListData?.length >= 1 &&
                    userPlansListData?.map((plan, index) => {
                      if (plan?.subType != "store") {
                        return (
                          <Grid key={index} item xs={12} sm={12} md={6} lg={6}>
                            <PlanBox plan={plan} adFormData={adsData} setAdFormData={setAdsData} />
                          </Grid>
                        );
                      }
                    })}
                </Grid>
              </Grid>
            )}
          </Grid>
          {!id && productDetails?.id && (
            <Grid container sx={{ mt: 4 }} gap={2}>
              <Grid xs={5} sm={5} md={2} lg={2}>
                <AdminButton
                  fullWidth
                  sx={{ bgcolor: "black", "&:hover": { bgcolor: "#757575" } }}
                  title={t("button.saveInDraft")}
                  onClick={() => {
                    dispatch(clearImageListData());
                    navigate("/adds");
                  }}
                />
              </Grid>
              <Grid xs={6} sm={6} md={2} lg={2}>
                <AdminButton
                  fullWidth
                  onClick={() => handlePublish()}
                  title={t("adrenaAds.publishAd")}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        {isLoading && <Loader open={isLoading} text={"Please Wait"} />}
      </Grid>
    </>
  );
}
