import { TextField, Typography } from "@mui/material";
import React from "react";
import "./index.css";
import InputAdornment from "@mui/material/InputAdornment";
import { appColors } from "theme/colors";

export default function AdminInput(props) {
  const {
    title,
    type,
    formError,
    value,
    onChange,
    endIcon,
    PasswordShowClick,
    inputRef,
    borderRadius
  } = props;
  return (
    <>
      <TextField
        margin="normal"
        required
        fullWidth
        type={type}
        InputLabelProps={{
          style: { color: "white" }
        }}
        onChange={onChange}
        value={value}
        id={type}
        label={title}
        name={type}
        inputRef={inputRef}
        autoComplete={type}
        sx={{
          input: {
            color: "white",
            "&:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 100px black inset",
              WebkitTextFillColor: "#fff"
            }
          }
        }}
        InputProps={{
          style: {
            borderRadius: borderRadius
          },
          endAdornment: (
            <InputAdornment onClick={PasswordShowClick} position="end">
              {endIcon}
            </InputAdornment>
          )
        }}
      />
      <Typography sx={{ color: appColors.primaryColor, fontSize: 15, ml: 1 }}>
        {formError ? formError : ""}
      </Typography>
    </>
  );
}
